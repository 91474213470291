//@flow
import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Gram } from 'Components/common';
import styles from './styles';
import { updateReducerCalcValue } from '../actions/MacroCalculatorActions';
import Slider from 'react-native-slider';
import { i18n } from 'Theme';
import { Ionicons } from '@expo/vector-icons';

type Props = {
	onBack: Function,
	theme: Object
};

export const PageCarb = (props: Props) => {
	const { carb } = useSelector((state) => state.calculator);
	const { onBack, theme } = props;
	const dispatch = useDispatch();

	return (
		<View style={styles.modalContainer}>
			<View style={styles.modalHeader}>
				<TouchableOpacity
					style={[styles.closeContainer, { backgroundColor: theme.blue }]}
					onPress={onBack}>
					<Ionicons
						style={styles.closeIcon}
						name={'md-close'}
						color={theme.darkFont}
						size={20}
					/>
				</TouchableOpacity>
			</View>
			<View style={styles.modalSubContainer}>
				<Text style={styles.pageTitle}>{i18n.t('netcarb')}</Text>
				<Text style={styles.pageDescription}>
					{i18n.t('netcarbDescription')}
				</Text>
				<Text style={styles.carbNumberText}>
					{carb}
					<Gram />
				</Text>
				<Slider
					style={styles.slider}
					value={carb}
					onValueChange={(value) =>
						dispatch(updateReducerCalcValue('carb', value))
					}
					minimumValue={0}
					maximumValue={50}
					step={1}
					minimumTrackTintColor={theme.blue}
					thumbTintColor={theme.blue}
				/>
			</View>
		</View>
	);
};
