//@flow
import React from 'react';
import { ScrollView, View, Image, Linking } from 'react-native';
import { Paragraph, Heading, Divider, BlockQuote, Text } from '../common';
import styles from './Styles/Styles';
import { i18n, Images } from 'Theme';

export const Basics = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<View>
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('basicsh1')}
				</Heading>
				<Paragraph>{i18n.t('basicsp1')}</Paragraph>
				<Divider />
				<Image
					source={Images.ketoChart}
					resizeMode="contain"
					style={styles.imageStyle}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('basicsh2')}
				</Heading>
				<Paragraph>{i18n.t('basicsp2')}</Paragraph>
				<Divider />
				<Image
					source={Images.dietGrid}
					resizeMode="contain"
					style={styles.imageStyle}
				/>
				<Divider />
				<BlockQuote
					ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('basicsbq')}
				</BlockQuote>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('basicsh3')}
				</Heading>
				<Paragraph>{i18n.t('basicsp3')}</Paragraph>
				<Divider />
				<BlockQuote
					ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}
					style={{ paddingTop: 0, alignItems: 'center' }}>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'https://www.health.harvard.edu/blog/ketogenic-diet-is-the-ultimate-low-carb-diet-good-for-you-2017072712089'
							)
						}>
						{'\n'}Source
					</Text>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('https://www.dietdoctor.com/low-carb/science')
						}>
						{'\n'}Source 2
					</Text>
				</BlockQuote>
			</View>
		</ScrollView>
	);
};
