//@flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Animated, Platform } from 'react-native';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavOptionsHeader, NavOptionsHeaderNoDraw } from '../';
import { i18n } from 'Theme';
import {
	HeaderIconMultiButtons,
	Text,
	HeaderMenuDropdown
} from 'Components/common';
import { MaterialCommunityIcons } from '@expo/vector-icons';

//Normal Screens
import { LearnScreen } from 'Components/learn';
import RecipeRouter from './RecipeRouter';
import FoodListRouter from './FoodListRouter';
import { OtherDietsScreen } from 'Components/otherapps/OtherDietsScreen';
import { MacroCalculatorScreen } from 'Components/calculator/MacroCalculatorScreen';

import { ShoppingListScreen } from 'Components/shoppinglist/ShoppingListScreen';
import { LoginScreen } from 'Components/tracker/login/LoginScreen';

//Tracker Imports
import { LogScreen } from 'Components/tracker/log/LogScreen';
import ProgressStack from './ProgressRouter';
import { SettingsScreen } from 'Components/tracker/settings/SettingsScreen';
import { PreferencesScreen } from 'Components/tracker/settings/PreferencesScreen';
import { FitnessTrackersScreen } from 'Components/tracker/fitnesstrackers/FitnessTrackersScreen';
import {
	AddFoodScreenRouter,
	MyEditStack,
	LogEntryStack
} from './AddFoodScreenRouter';
import SocialScreenRouter from './SocialScreenRouter';
import ManageRouter from './ManageRouter';
import { BodyMeasurementsScreen } from 'Components/tracker/bodymeasurements/BodyMeasurementsScreen';
import { VitalsScreen } from 'Components/tracker/vitals/VitalsScreen';
import PurchaseScreen from 'Components/purchase/PurchaseScreen';
const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const PurchaseStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="Purchase"
				component={PurchaseScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('premium')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const LearnStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="Learn"
				component={LearnScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('learn')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const OtherDietsStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="OtherDiets"
				component={OtherDietsScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('moreapps')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const CalculatorStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="Calculator"
				component={MacroCalculatorScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('macrocalculator')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

//Vitals
const VitalsStack = () => {
	const title = (
		<Text
			style={{ color: '#FFF', alignItems: 'center', justifyContent: 'center' }}>
			<MaterialCommunityIcons
				name={'stethoscope'}
				color={'#FFF'}
				size={12}
				style={{ alignSelf: 'center', marginRight: 5 }}
			/>{' '}
			{i18n.t('vitals')}
		</Text>
	);
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="Vitals"
				component={VitalsScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw navigation={navigation} title={title} />
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

//Bodymeasurements
const BodyMeasurementsStack = () => {
	const title = (
		<Text
			style={{ color: '#FFF', alignItems: 'center', justifyContent: 'center' }}>
			<MaterialCommunityIcons
				name={'scale-bathroom'}
				color={'#FFF'}
				size={12}
				style={{ alignSelf: 'center', marginRight: 5 }}
			/>{' '}
			{i18n.t('bodyMeasurements')}
		</Text>
	);
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="BodyMeasurements"
				component={BodyMeasurementsScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw navigation={navigation} title={title} />
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

//Login Screen
const LoginStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="Login"
				component={LoginScreen}
				headerMode={'none'}
				options={{ headerShown: false }}
			/>
		</Stack.Navigator>
	);
};

//Log Screen Home Page
const LogStack = () => {
	return (
		<Stack.Navigator initialRouteName="LogScreen">
			<Stack.Screen
				name="LogScreen"
				component={LogScreen}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;

						const progress = Animated.add(
							scene.progress.current,
							scene.progress.next || 0
						);

						const opacity = progress.interpolate({
							inputRange: [0, 1, 2],
							outputRange: [0, 1, 0]
						});
						return (
							<Animated.View style={{ opacity }}>
								<NavOptionsHeader
									navigation={navigation}
									title={i18n.t('log')}
									right={
										<HeaderIconMultiButtons btnArray={params.headerBtns} />
									}
								/>
							</Animated.View>
						);
					}
				}}
			/>
			<Stack.Screen
				name="AddFood"
				component={AddFoodScreenRouter}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="MealEdit"
				component={MyEditStack}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="LogEntry"
				component={LogEntryStack}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="BodyMeasurements"
				component={BodyMeasurementsStack}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="Vitals"
				component={VitalsStack}
				options={{ headerShown: false }}
			/>
		</Stack.Navigator>
	);
};

//Login vs Log home page Screen selector. If logged in deleted login stack, if logged out delete log stack
/*const AuthLogStacked = () => {
	const auth = useSelector((state) => state.firebase.auth);
	const profile = useSelector((state) => state.firebase.profile);
	const purchase = useSelector((state) => state.purchase.purchase);
	return !purchase
		? PurchaseStack()
		: isLoaded(auth) && !isEmpty(auth) && isLoaded(profile) && !isEmpty(profile)
		? LogStack()
		: LoginStack();
};*/

const AuthLogStacked = () => {
	const auth = useSelector((state) => state.firebase.auth);
	const profile = useSelector((state) => state.firebase.profile);
	return isLoaded(auth) &&
		!isEmpty(auth) &&
		isLoaded(profile) &&
		!isEmpty(profile)
		? LogStack()
		: LoginStack();
};

const SettingsStack = () => {
	return (
		<Stack.Navigator initialRouteName="SettingsScreen">
			<Stack.Screen
				name="SettingsScreen"
				component={SettingsScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('settings')}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="PreferencesScreen"
				component={PreferencesScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('preferences')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const FitnessTrackersStack = () => {
	return (
		<Stack.Navigator initialRouteName="FitnessTrackersScreen">
			<Stack.Screen
				name="FitnessTrackersScreen"
				component={FitnessTrackersScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('fitnessTracker')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const ShoppingListStack = () => {
	return (
		<Stack.Navigator initialRouteName="ShoppingListScreen">
			<Stack.Screen
				name="ShoppingListScreen"
				component={ShoppingListScreen}
				options={{
					header: ({ navigation, scene }) => {
						const { params = {} } = scene.route;
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('shoppingList')}
								right={<HeaderIconMultiButtons btnArray={params.headerBtns} />}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

let loggedIn = [];
if (Platform.OS !== 'web') {
	loggedIn = [
		<Drawer.Screen
			name="LogScreen"
			key="LogScreen"
			options={{ title: i18n.t('log'), drawerIcon: 'ios-clipboard' }}
			component={AuthLogStacked}
		/>,
		<Drawer.Screen
			name="Progress"
			key="Progress"
			options={{ title: i18n.t('progress'), drawerIcon: 'ios-stats' }}
			component={ProgressStack}
		/>,
		<Drawer.Screen
			name="Manage"
			key="Manage"
			options={{ title: i18n.t('manage'), drawerIcon: 'ios-restaurant' }}
			component={ManageRouter}
		/>,
		<Drawer.Screen
			name="Social"
			key="Social"
			options={{ title: i18n.t('social'), drawerIcon: 'ios-person-add' }}
			component={SocialScreenRouter}
		/>,
		<Drawer.Screen
			name="FitnessTrackers"
			key="FitnessTrackers"
			options={{ title: i18n.t('fitnessTracker'), drawerIcon: 'ios-swap' }}
			component={FitnessTrackersStack}
		/>,
		<Drawer.Screen
			name="Settings"
			key="Settings"
			options={{ title: i18n.t('settings'), drawerIcon: 'ios-settings' }}
			component={SettingsStack}
		/>,
		<Drawer.Screen
			name="Purchase"
			key="Purchase"
			options={{ title: i18n.t('premium'), drawerIcon: 'ios-star' }}
			component={PurchaseStack}
		/>,
		<Drawer.Screen
			name="Calculator"
			key="Calculator"
			options={{
				title: i18n.t('macrocalculator'),
				drawerIcon: 'ios-calculator'
			}}
			component={CalculatorStack}
		/>,
		<Drawer.Screen
			name="Recipes"
			key="Recipes"
			options={{ title: i18n.t('recipes'), drawerIcon: 'ios-search' }}
			component={RecipeRouter}
		/>,
		<Drawer.Screen
			name="FoodList"
			key="FoodList"
			options={{ title: i18n.t('netcarbFinder'), drawerIcon: 'ios-list' }}
			component={FoodListRouter}
		/>,
		<Drawer.Screen
			name="Learn"
			key="Learn"
			options={{ title: i18n.t('learn'), drawerIcon: 'ios-school' }}
			component={LearnStack}
		/>,
		<Drawer.Screen
			name="ShoppingList"
			key="ShoppingList"
			options={{ title: i18n.t('shoppingList'), drawerIcon: 'ios-list-box' }}
			component={ShoppingListStack}
		/>,
		<Drawer.Screen
			name="OtherDiets"
			key="OtherDiets"
			options={{ title: i18n.t('moreapps'), drawerIcon: 'ios-apps' }}
			component={OtherDietsStack}
		/>
	];
} else {
	loggedIn = [
		<Drawer.Screen
			name="LogScreen"
			key="LogScreen"
			options={{ title: i18n.t('log'), drawerIcon: 'ios-clipboard' }}
			component={AuthLogStacked}
		/>,
		<Drawer.Screen
			name="Progress"
			key="Progress"
			options={{ title: i18n.t('progress'), drawerIcon: 'ios-stats' }}
			component={ProgressStack}
		/>,
		<Drawer.Screen
			name="Manage"
			key="Manage"
			options={{ title: i18n.t('manage'), drawerIcon: 'ios-restaurant' }}
			component={ManageRouter}
		/>,
		<Drawer.Screen
			name="Social"
			key="Social"
			options={{ title: i18n.t('social'), drawerIcon: 'ios-person-add' }}
			component={SocialScreenRouter}
		/>,
		<Drawer.Screen
			name="FitnessTrackers"
			key="FitnessTrackers"
			options={{ title: i18n.t('fitnessTracker'), drawerIcon: 'ios-swap' }}
			component={FitnessTrackersStack}
		/>,
		<Drawer.Screen
			name="Settings"
			key="Settings"
			options={{ title: i18n.t('settings'), drawerIcon: 'ios-settings' }}
			component={SettingsStack}
		/>,
		<Drawer.Screen
			name="Calculator"
			key="Calculator"
			options={{
				title: i18n.t('macrocalculator'),
				drawerIcon: 'ios-calculator'
			}}
			component={CalculatorStack}
		/>,
		<Drawer.Screen
			name="Recipes"
			key="Recipes"
			options={{ title: i18n.t('recipes'), drawerIcon: 'ios-search' }}
			component={RecipeRouter}
		/>,
		<Drawer.Screen
			name="FoodList"
			key="FoodList"
			options={{ title: i18n.t('netcarbFinder'), drawerIcon: 'ios-list' }}
			component={FoodListRouter}
		/>,
		<Drawer.Screen
			name="Learn"
			key="Learn"
			options={{ title: i18n.t('learn'), drawerIcon: 'ios-school' }}
			component={LearnStack}
		/>,
		<Drawer.Screen
			name="ShoppingList"
			key="ShoppingList"
			options={{ title: i18n.t('shoppingList'), drawerIcon: 'ios-list-box' }}
			component={ShoppingListStack}
		/>,
		<Drawer.Screen
			name="OtherDiets"
			key="OtherDiets"
			options={{ title: i18n.t('moreapps'), drawerIcon: 'ios-apps' }}
			component={OtherDietsStack}
		/>
	];
}

const notLoggedIn = [
	<Drawer.Screen
		name="Login"
		key="Login"
		headerMode={'none'}
		options={{
			headerShown: false,
			title: i18n.t('login'),
			drawerIcon: 'ios-log-in'
		}}
		component={AuthLogStacked}
	/>
];

export const FinalAuthRoutes = () => {
	const auth = useSelector((state) => state.firebase.auth);
	const profile = useSelector((state) => state.firebase.profile);
	let purchaseFiltered = [...loggedIn];
	if (profile.premium) {
		purchaseFiltered = purchaseFiltered.filter(function (obj) {
			return obj.key !== 'Purchase';
		});
	}
	return isLoaded(auth) &&
		!isEmpty(auth) &&
		isLoaded(profile) &&
		!isEmpty(profile)
		? purchaseFiltered
		: notLoggedIn;
};

export const returnDefaultRoute = () => {
	let string = 'Login';
	const auth = useSelector((state) => state.firebase.auth);
	const profile = useSelector((state) => state.firebase.profile);
	string =
		isLoaded(auth) && !isEmpty(auth) && isLoaded(profile) && !isEmpty(profile)
			? 'LogScreen'
			: 'Login';
	return string;
};

//For Purchase Mode
/*export const returnDefaultRoute = () => {
	let string = 'Calculator';
	const auth = useSelector((state) => state.firebase.auth);
	const profile = useSelector((state) => state.firebase.profile);
	const purchase = useSelector((state) => state.purchase.purchase);
	string = !purchase
		? 'Calculator'
		: isLoaded(auth) && !isEmpty(auth) && isLoaded(profile) && !isEmpty(profile)
		? 'Login'
		: 'LogScreen';
	return string;
};

export const FinalAuthRoutes = () => {
	const auth = useSelector((state) => state.firebase.auth);
	const profile = useSelector((state) => state.firebase.profile);
	const purchase = useSelector((state) => state.purchase.purchase);
	return !purchase
		? noPurchased
		: isLoaded(auth) && !isEmpty(auth) && isLoaded(profile) && !isEmpty(profile)
		? loggedIn
		: notLoggedIn;
};*/
