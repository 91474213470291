//@flow
import React from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { updateUnitsFormat } from 'Components/tracker/settings/actions';
import { i18n } from 'Theme';
import { Text, OptionSelector } from 'Components/common';
import styles from './styles';
import { Nav } from './';

export const PagePreferredCarb = (props) => {
	const dispatch = useDispatch();
	const { next, prev, page, theme } = props;
	const carbUnits = useSelector(
		(state) => state.firebase.profile.settings.units.carbUnits
	);

	const carbOptions = [
		{ label: i18n.t('net'), value: 'net' },
		{ label: i18n.t('total'), value: 'total' }
	];

	return (
		<View style={styles.pageContainer}>
			<Nav onForward={() => next()} onBack={prev} page={page} theme={theme} />
			<Text style={styles.pageTitle}>{i18n.t('preferredCarb')}</Text>
			<Text style={styles.pageSubtitle}>{i18n.t('preferredCarbDesc')}</Text>
			<View style={styles.inputsContainer}>
				<OptionSelector
					name={i18n.t('preferredCarb')}
					onChange={(value) => dispatch(updateUnitsFormat('carbUnits', value))}
					currentValue={carbUnits}
					data={carbOptions}
					containerStyle={{
						justifyContent: 'center',
						borderBottomWidth: 0
					}}
					textStyle={{ fontSize: 16 }}
				/>
			</View>
		</View>
	);
};
