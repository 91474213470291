import React from 'react';
import {
	Linking,
	Platform,
	FlatList,
	TouchableOpacity,
	Image,
	StyleSheet
} from 'react-native';
import { Text, Row } from 'Components/common';
import { Images } from 'Theme';
import { useSelector } from 'react-redux';

const seventeenUrl = Platform.select({
	ios:
		'https://itunes.apple.com/us/app/17-day-diet-guide/id1045420811?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.seventeendiet'
});
const alkalineUrl = Platform.select({
	ios:
		'https://itunes.apple.com/us/app/alkaline-diet-guide/id1028877995?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.alkaline'
});
const dashUrl = Platform.select({
	ios: 'https://itunes.apple.com/us/app/dash-diet-guide/id1032110739?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.dashdiet'
});
const fertilityUrl = Platform.select({
	ios:
		'https://itunes.apple.com/us/app/fertility-diet-guide/id1086511025?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.fertilitydiet'
});
const glutenUrl = Platform.select({
	ios:
		'https://itunes.apple.com/us/app/gluten-free-diet-guide/id1035050804?mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.glutendiet'
});
const hcgUrl = Platform.select({
	ios: 'https://itunes.apple.com/us/app/hcg-diet-guide/id1031402985?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.hcgdiet'
});
const hmrUrl = Platform.select({
	ios: 'https://itunes.apple.com/us/app/hmr-diet/id1043341792?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.hmrdiet'
});
const macrobioticUrl = Platform.select({
	ios:
		'https://itunes.apple.com/us/app/macrobiotic-diet-guide/id1037716833?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.macrobioticdiet'
});
const mediterraneanUrl = Platform.select({
	ios:
		'https://itunes.apple.com/us/app/mediterranean-diet-guide-foods/id1089223593?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.medidiet'
});
const paleoUrl = Platform.select({
	ios:
		'https://itunes.apple.com/us/app/paleo-diet-guide/id1082855602?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.paleodiet'
});
const rawUrl = Platform.select({
	ios:
		'https://itunes.apple.com/us/app/raw-food-diet-guide/id1084906092?ls=1&mt=8',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.rawdiet'
});
const myfodmapsUrl = Platform.select({
	ios:
		'https://apps.apple.com/us/app/myfodmaps-ibs-diet-guide/id1510994461?ls=1',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.fodmap&hl=en'
});

const ketoUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1459449742',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.keto'
});

const glycemicUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1522964184',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.glycemicrecipes'
});
const peanutUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1523189839',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.peanutfreerecipes'
});
const pescatarianUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1523191465',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.pescatarianrecipes'
});
const kosherUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1523375039',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.kosherrecipes'
});
const kidneyUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1523371555',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.kidneyrecipes'
});
const immuneUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1523376070',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.immunesupportrecipe'
});
const cocktailUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1526946757',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.cocktailrecipes'
});
const redmeatUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1527126495',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.redmeatfreerecipes'
});
const dairyUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1527138207',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.dairyfreerecipes'
});
const caribbeanUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1527138394',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.caribbeanrecipes'
});
const chineseUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1527139190',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.chineserecipes'
});
const easterneuropeUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1527139302',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.easteuroperecipes'
});
const frenchUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1527139344',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.frenchrecipes'
});
const indianUrl = Platform.select({
	ios: 'https://apps.apple.com/us/app/id1527139538',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.indianrecipes'
});

const diets = [
	/*{
		image: Images.keto,
		title: 'MyKeto: Keto Tracker',
		url: ketoUrl,
		id: 0
	},*/
	{
		image: Images.myfodmaps,
		title: 'MyFodmaps: IBS Diet',
		url: myfodmapsUrl,
		id: 1
	},
	{
		image: Images.dash,
		title: 'Dash Diet',
		url: dashUrl,
		id: 2
	},
	{
		image: Images.mediterranean,
		title: 'Mediterranean Diet',
		url: mediterraneanUrl,
		id: 3
	},
	{
		image: Images.seventeen,
		title: '17 Day Diet',
		url: seventeenUrl,
		id: 4
	},
	{
		image: Images.hcg,
		title: 'HCG Diet',
		url: hcgUrl,
		id: 5
	},
	{
		image: Images.alkaline,
		title: 'Alkaline Diet',
		url: alkalineUrl,
		id: 6
	},
	{
		image: Images.hmr,
		title: 'HMR Diet',
		url: hmrUrl,
		id: 7
	},
	{
		image: Images.glutenFree,
		title: 'Gluten Free Diet',
		url: glutenUrl,
		id: 8
	},
	{
		image: Images.fertility,
		title: 'Fertility Diet',
		url: fertilityUrl,
		id: 9
	},
	{
		image: Images.rawFood,
		title: 'Raw Food Diet',
		url: rawUrl,
		id: 10
	},
	{
		image: Images.paleo,
		title: 'Paleo Diet',
		url: paleoUrl,
		id: 11
	},
	{
		image: Images.macrobiotic,
		title: 'Macrobiotic Diet',
		url: macrobioticUrl,
		id: 12
	},
	{
		image: Images.glycemic,
		title: 'Low Glycemic Recipes',
		url: glycemicUrl,
		id: 13
	},
	{
		image: Images.peanut,
		title: 'Peanut-Free Recipes',
		url: peanutUrl,
		id: 14
	},
	{
		image: Images.pescatarian,
		title: 'Pescatarian Diet Recipes',
		url: pescatarianUrl,
		id: 15
	},
	{
		image: Images.kidney,
		title: 'Kidney Friendly Recipes',
		url: kidneyUrl,
		id: 16
	},
	{
		image: Images.kosher,
		title: 'Kosher Recipes',
		url: kosherUrl,
		id: 17
	},
	{
		image: Images.immune,
		title: 'Immune System Support Recipes',
		url: immuneUrl,
		id: 18
	},
	{
		image: Images.cocktail,
		title: 'Cocktail Recipes',
		url: cocktailUrl,
		id: 19
	},
	{
		image: Images.redmeat,
		title: 'No Red Meat Diet - Pollotarian Recipes',
		url: redmeatUrl,
		id: 20
	},
	{
		image: Images.dairy,
		title: 'Dairy-Free Recipes',
		url: dairyUrl,
		id: 21
	},
	{
		image: Images.caribbean,
		title: 'Caribbean Cusine Recipes',
		url: caribbeanUrl,
		id: 22
	},
	{
		image: Images.chinese,
		title: 'Chinese Cuisine Recipes',
		url: chineseUrl,
		id: 23
	},
	{
		image: Images.easterneurope,
		title: 'Eastern European Cuisine Recipes',
		url: easterneuropeUrl,
		id: 24
	},
	{
		image: Images.french,
		title: 'French Cuisine Recipes',
		url: frenchUrl,
		id: 25
	},
	{
		image: Images.indian,
		title: 'Indian Cuisine Recipes',
		url: indianUrl,
		id: 26
	}
];

export const OtherDietsScreen = () => {
	const { theme } = useSelector((state) => state.startup);

	const renderRow = ({ item }) => {
		return (
			<TouchableOpacity id={item.id} onPress={() => Linking.openURL(item.url)}>
				<Row style={{ borderBottomColor: theme.border }}>
					<Image style={styles.otherDietImage} source={item.image} />
					<Text style={{ flex: 1, paddingLeft: 10 }}>{item.title}</Text>
					<Image
						style={{ width: 75, height: 25 }}
						source={Platform.OS === 'ios' ? Images.ios : Images.gplay}
					/>
				</Row>
			</TouchableOpacity>
		);
	};

	return (
		<FlatList
			style={{ backgroundColor: theme.baseBG }}
			data={diets}
			renderItem={renderRow}
			keyExtractor={(item) => item.title}
		/>
	);
};

const styles = StyleSheet.create({
	container: {},
	linkText: {
		color: 'blue',
		paddingLeft: 10
	},
	imageSize: {
		height: 200,
		width: 200
	},
	otherDietImage: {
		height: 75,
		width: 75,
		borderRadius: 5
	},
	otherDietIcon: {
		justifyContent: 'flex-end',
		flex: 0.1
	}
});
