const DATA = {
	food0: 'Queijo azul',
	food1: 'Queijo cheddar',
	food2: 'Queijo tipo cottage',
	food3: 'Creme de queijo',
	food4: 'Queijo feta',
	food5: 'Queijo Gouda',
	food6: 'Queijo mussarela',
	food7: 'Provolone Cheese',
	food8: 'Queijo ricota',
	food9: 'Queijo suíço',
	food10: 'Creme de coco',
	food11: 'Leite de coco (sem açúcar)',
	food12: 'Ovo',
	food13: 'Creme de leite',
	food14: 'Sorvete (sem açúcar)',
	food15: 'Leite',
	food16: 'Leite de soja (sem açúcar)',
	food17: 'Chantilly (sem açúcar)',
	food18: 'Iogurte / Kefir, Simples',
	food19: 'Queijo azul',
	food20: 'César cremoso',
	food21: 'Rancho',
	food22: 'Óleo de amêndoa',
	food23: 'Manteiga',
	food24: 'Manteiga de cacau',
	food25: 'Óleo de côco',
	food26: 'Óleo de peixe (fígado de bacalhau)',
	food27: 'Óleo de linhaça',
	food28: 'Óleo de semente de uva',
	food29: 'Óleo de sementes de cânhamo',
	food30: 'Maionese',
	food31: 'Azeite',
	food32: 'Óleo de Cártamo',
	food33: 'Óleo de gergelim',
	food34: 'Óleo de girassol',
	food35: 'Óleo vegetal',
	food36: 'Linguado',
	food37: 'Arenque',
	food38: 'Salmão',
	food39: 'Sardinhas',
	food40: 'Único',
	food41: 'Truta',
	food42: 'Atum',
	food43: 'Atum (enlatado)',
	food44: 'Farinha de amêndoa',
	food45: 'Cacau em pó (sem açúcar)',
	food46: 'Farinha de Semente de Linho',
	food47: 'Proteína em pó',
	food48: 'Psyllium Husk',
	food49: 'Farinha de trigo',
	food50: 'Pernas de galinha',
	food51: 'Asas de frango',
	food52: 'Pato',
	food53: 'Ganso',
	food54: 'Codorna',
	food55: 'Peito de peru',
	food56: 'Turquia, solo',
	food57: 'Bacon de peru',
	food58: 'Cevada',
	food59: 'Alimentos processados ​​em caixa',
	food60: 'Pão',
	food61: 'Migalhas de pão',
	food62: 'Bolos',
	food63: 'Sopas e ensopados enlatados',
	food64: 'Cereais frios',
	food65: 'Biscoitos',
	food66: 'Salgadinhos de milho',
	food67: 'Pão de milho',
	food68: 'Fubá',
	food69: 'Biscoitos',
	food70: 'Grits',
	food71: 'Hash browns',
	food72: 'Cereais quentes',
	food73: 'Muffins, simples',
	food74: 'Panquecas',
	food75: 'Massa',
	food76: 'Tortas',
	food77: 'Polenta',
	food78: 'Pipoca',
	food79: 'Batata frita',
	food80: 'Salgadinhos',
	food81: 'Arroz',
	food82: 'Centeio',
	food83: 'Soletrado',
	food84: 'Wrappers Tamale',
	food85: 'Tater tots',
	food86: 'Teff',
	food87: 'Tortas',
	food88: 'Tricale',
	food89: 'Waffles',
	food90: 'Bife, costela',
	food91: 'Carne, salsicha',
	food92: 'Bife',
	food93: 'Cordeiro, costeletas',
	food94: 'Pepperoni, porco / vaca',
	food95: 'Porco, bacon',
	food96: 'Costeletas de porco',
	food97: 'Porco, presunto',
	food98: 'Salsicha de porco',
	food99: 'Vitela',
	food100: 'Veado, bife',
	food101: 'Feijões',
	food102: 'Castanha de caju',
	food103: 'Coco (carne, sem açúcar)',
	food104: 'Avelãs',
	food105: 'Lentilhas',
	food106: 'Macadâmias',
	food107: 'Peanuts',
	food108: 'Manteiga de amendoim',
	food109: 'Nozes',
	food110: 'Pistachios',
	food111: 'Nozes',
	food112: 'Sementes de linhaça',
	food113: 'Sementes de Cânhamo',
	food114: 'Sementes de abóbora',
	food115: 'Sementes de Cártamo',
	food116: 'Sementes de Sesamo',
	food117: 'Sementes de Girassol',
	food118: 'Carne de carangueijo',
	food119: 'Lagosta',
	food120: 'Mexilhões',
	food121: 'Ostras',
	food122: 'Camarão',
	food123: 'Lula',
	food124: 'Caldo claro ou caldo',
	food125: 'Café',
	food126: 'Crystal Light',
	food127: 'Refrigerantes diet (Coca Zero, Coca Diet)',
	food128: 'Água tônica diet',
	food129: 'Água com gás com sabor (sem açúcar)',
	food130: 'Chá de ervas (sem açúcar)',
	food131: 'Suco de limão e lima (limitado)',
	food132: 'Água com gás',
	food133: 'Refrigerante',
	food134: 'Água com gás',
	food135: 'Bebidas energéticas sem açúcar (Red Bull, Monster, etc.)',
	food136: 'Água com gás (Perrier)',
	food137: 'Chá (sem açúcar)',
	food138: 'Água',
	food139: 'Cerveja',
	food140: 'Conhaque',
	food141: 'Cognac (Hennessy, etc.)',
	food142: 'Gin (Tanqueray, Beefeater, etc)',
	food143: 'Light Beers (ou seja, Coors, Bud Light)',
	food144: 'Rum (Capitão Morgan, etc)',
	food145: 'Scotch',
	food146: 'Tequila',
	food147: 'Vodka (Absolut, Grey Goose, etc.)',
	food148: 'Vinho (seco)',
	food149: 'Vinho (sobremesa)',
	food150: "Whisky (Jack Daniel's, etc.)",
	food151: 'Açúcar mascavo',
	food152: 'Doce',
	food153: 'Cana de açúcar',
	food154: 'Xarope de milho',
	food155: 'Dextrose',
	food156: 'Eritritol',
	food157: 'Frutose',
	food158: 'Glicose',
	food159: 'Mel',
	food160: 'Lactose',
	food161: 'Maltose',
	food162: 'Xarope de bordo',
	food163: 'Açúcar em pó',
	food164: 'Sorgo',
	food165: 'Stevia',
	food166: 'Sacarose',
	food167: 'Maçãs',
	food168: 'Damasco',
	food169: 'Abacate',
	food170: 'Banana',
	food171: 'Amora silvestre',
	food172: 'Amoras',
	food173: 'Cantalupo',
	food174: 'Cerejas',
	food175: 'Cranberries',
	food176: 'Datas',
	food177: 'Fruta do dragão',
	food178: 'Durians',
	food179: 'Figs',
	food180: 'Uvas',
	food181: 'Toranja',
	food182: 'Melada',
	food183: 'Suco',
	food184: 'Kiwi',
	food185: 'Limões',
	food186: 'Limes',
	food187: 'Manga',
	food188: 'Nectarinas',
	food189: 'Laranjas',
	food190: 'Mamão',
	food191: 'Maracujá',
	food192: 'Pêssego',
	food193: 'Pêras',
	food194: 'Abacaxis',
	food195: 'Romã',
	food196: 'Ameixas',
	food197: 'Prunes',
	food198: 'Passas de uva',
	food199: 'Framboesas',
	food200: 'Morangos',
	food201: 'Tangerinas',
	food202: 'Melancia',
	food203: 'Brotos de alfafa',
	food204: 'Qualquer vegetal com folhas verdes',
	food205: 'Alcachofras',
	food206: 'Espargos',
	food207: 'Brotos de bambu',
	food208: 'Broto de feijão',
	food209: 'Verduras de beterraba',
	food210: 'Bok Choy',
	food211: 'Brócolis',
	food212: 'Couves de Bruxelas',
	food213: 'Repolho',
	food214: 'Couve-flor',
	food215: 'Salsão',
	food216: 'Raiz de aipo',
	food217: 'Acelga',
	food218: 'Cebolinha',
	food219: 'Collard Greens',
	food220: 'Milho',
	food221: 'Pepinos',
	food222: 'Dandelion Greens',
	food223: 'Picles de endro',
	food224: 'Alho',
	food225: 'Couve',
	food226: 'Alho-poró',
	food227:
		'Alfaces e verduras para salada - rúcula, bok choy, alface Boston, chicória, endívia, escarola, erva-doce, macha, radicchio, romaine, azeda',
	food228: 'Feijão-Lima',
	food229: 'Cogumelos',
	food230: 'Quiabo',
	food231: 'Azeitonas',
	food232: 'Cebolas',
	food233: 'Ervilhas',
	food234: 'Batatas',
	food235: 'Rabanetes',
	food236: 'Chucrute',
	food237: 'Cebolinha',
	food238: 'Chalotas',
	food239: 'Espinafre',
	food240: 'Batatas doces',
	food241: 'Acelga',
	food242: 'Nabos',
	food243: "Castanhas d'água"
};

export default DATA;
