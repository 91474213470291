import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
	container: {},
	linkText: {
		color: 'blue',
		paddingLeft: 10
	},
	imageStyle: {
		alignSelf: 'center',
		width: width,
		height: width / 2,
		paddingHorizontal: 10
	},
	productImg: {
		alignSelf: 'center',
		width: 200,
		height: 200,
		paddingHorizontal: 10
	},
	tinyText: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 8
	}
});
