const DATA = {
	food0: 'Queso azul',
	food1: 'Queso cheddar',
	food2: 'Queso cottage',
	food3: 'Queso crema',
	food4: 'Queso feta',
	food5: 'Queso gouda',
	food6: 'Queso mozzarella',
	food7: 'Queso provolone',
	food8: 'Queso ricotta',
	food9: 'Queso suizo',
	food10: 'Crema de coco',
	food11: 'Leche de coco (sin azúcar)',
	food12: 'Huevo',
	food13: 'Crema espesa',
	food14: 'Helado (sin azúcar)',
	food15: 'Leche',
	food16: 'Leche de soja (sin azúcar)',
	food17: 'Crema batida (sin azúcar)',
	food18: 'Yogur / Kéfir, simple',
	food19: 'Queso azul',
	food20: 'César cremoso',
	food21: 'Rancho',
	food22: 'Aceite de almendras',
	food23: 'Mantequilla',
	food24: 'Mantequilla de cocoa',
	food25: 'Aceite de coco',
	food26: 'Aceite de pescado (hígado de bacalao)',
	food27: 'Aceite de semilla de lino',
	food28: 'Aceite de semilla de uva',
	food29: 'Aceite de semilla de cáñamo',
	food30: 'Mayonesa',
	food31: 'Aceite de oliva',
	food32: 'Aceite de cártamo',
	food33: 'Aceite de sésamo',
	food34: 'Aceite de girasol',
	food35: 'Aceite vegetal',
	food36: 'Platija',
	food37: 'Arenque',
	food38: 'Salmón',
	food39: 'Sardinas',
	food40: 'Único',
	food41: 'Trucha',
	food42: 'Atún',
	food43: 'Atún (enlatado)',
	food44: 'Harina de almendra',
	food45: 'Cacao en polvo (sin azúcar)',
	food46: 'Harina de linaza',
	food47: 'Proteína en polvo',
	food48: 'Cáscara de psyllium',
	food49: 'Harina de trigo',
	food50: 'Piernas de pollo',
	food51: 'Alitas de pollo',
	food52: 'Pato',
	food53: 'Ganso',
	food54: 'Codorniz',
	food55: 'Pechuga de pavo',
	food56: 'Turquía, tierra',
	food57: 'Tocino de pavo',
	food58: 'Cebada',
	food59: 'Alimentos procesados ​​en caja',
	food60: 'Un pan',
	food61: 'Migas de pan',
	food62: 'Tortas',
	food63: 'Sopas y guisos enlatados',
	food64: 'Cereales fríos',
	food65: 'Galletas',
	food66: 'Frituras de maíz',
	food67: 'Pan de maíz',
	food68: 'Harina de maíz',
	food69: 'Galletas',
	food70: 'Sémola',
	food71: 'Hash browns',
	food72: 'Cereales calientes',
	food73: 'Muffins, sencillos',
	food74: 'Panqueques',
	food75: 'Pasta',
	food76: 'Tartas',
	food77: 'Polenta',
	food78: 'Palomitas de maiz',
	food79: 'Patatas fritas',
	food80: 'Pretzels',
	food81: 'Arroz',
	food82: 'Centeno',
	food83: 'Espelta',
	food84: 'Envoltorios de tamales',
	food85: 'Tater tots',
	food86: 'Teff',
	food87: 'Tortillas',
	food88: 'Tricale',
	food89: 'Waffles',
	food90: 'Res, costillas',
	food91: 'Carne de res, salchicha',
	food92: 'Filete de ternera',
	food93: 'Chuletas de cordero',
	food94: 'Pepperoni, cerdo / ternera',
	food95: 'Cerdo, tocino',
	food96: 'Chuletas de cerdo',
	food97: 'Jamón de cerdo',
	food98: 'Salsa de puerco',
	food99: 'Ternera',
	food100: 'Carne de venado, bistec',
	food101: 'Frijoles',
	food102: 'Anacardos',
	food103: 'Coco (carne, sin azúcar)',
	food104: 'Avellanas',
	food105: 'Lentejas',
	food106: 'Macadamias',
	food107: 'Miseria',
	food108: 'Mantequilla de maní',
	food109: 'Nueces pecanas',
	food110: 'Pistachos',
	food111: 'Nueces',
	food112: 'Semillas de lino',
	food113: 'Semillas de cáñamo',
	food114: 'Semillas de calabaza',
	food115: 'Semillas de cártamo',
	food116: 'Semillas de sésamo',
	food117: 'Semillas de girasol',
	food118: 'Carne de cangrejo',
	food119: 'Langosta',
	food120: 'Mejillones',
	food121: 'Ostras',
	food122: 'Camarón',
	food123: 'Calamar',
	food124: 'Caldo claro o consomé',
	food125: 'Café',
	food126: 'Luz cristalina',
	food127: 'Refrescos dietéticos (Coke Zero, Diet Coke)',
	food128: 'Agua tónica dietética',
	food129: 'Agua carbonatada aromatizada (sin azúcar)',
	food130: 'Té de hierbas (sin azúcar)',
	food131: 'Zumo de limón y lima (limitado)',
	food132: 'Agua mineral',
	food133: 'Soda',
	food134: 'Agua con gas',
	food135: 'Bebidas energéticas sin azúcar (Red Bull, Monster, etc.)',
	food136: 'Agua con gas (Perrier)',
	food137: 'Té (sin azúcar)',
	food138: 'Agua',
	food139: 'Cerveza',
	food140: 'Brandy',
	food141: 'Coñac (Hennessy, etc.)',
	food142: 'Ginebra (Tanqueray, Beefeater, etc)',
	food143: 'Cervezas ligeras (es decir, Coors, Bud Light)',
	food144: 'Ron (Capitán Morgan, etc)',
	food145: 'Escocés',
	food146: 'Tequila',
	food147: 'Vodka (Absolut, Grey Goose, etc.)',
	food148: 'Vino (seco)',
	food149: 'Vino (postre)',
	food150: "Whisky (Jack Daniel's, etc.)",
	food151: 'Azúcar morena',
	food152: 'Dulce',
	food153: 'Caña de azucar',
	food154: 'Jarabe de maíz',
	food155: 'Dextrosa',
	food156: 'Eritritol',
	food157: 'Fructosa',
	food158: 'Glucosa',
	food159: 'Miel',
	food160: 'Lactosa',
	food161: 'Maltosa',
	food162: 'Miel de maple',
	food163: 'Azúcar en polvo',
	food164: 'Sorgo',
	food165: 'Stevia',
	food166: 'Sacarosa',
	food167: 'Manzanas',
	food168: 'Albaricoque',
	food169: 'Aguacate',
	food170: 'Plátano',
	food171: 'Moras',
	food172: 'Arándanos',
	food173: 'Cantalupo',
	food174: 'Cerezas',
	food175: 'Arándanos',
	food176: 'Fechas',
	food177: 'Dragon de fruta',
	food178: 'Durianos',
	food179: 'Higos',
	food180: 'Uvas',
	food181: 'Pomelo',
	food182: 'Gotas de miel',
	food183: 'Jugo',
	food184: 'Kiwi',
	food185: 'Limones',
	food186: 'Limas',
	food187: 'Mango',
	food188: 'Nectarinas',
	food189: 'Naranjas',
	food190: 'Papaya',
	food191: 'Maracuyá',
	food192: 'Melocotón',
	food193: 'Peras',
	food194: 'Piñas',
	food195: 'Granada',
	food196: 'Ciruelas',
	food197: 'Ciruelas',
	food198: 'Pasas',
	food199: 'Frambuesas',
	food200: 'Fresas',
	food201: 'Mandarinas',
	food202: 'Sandía',
	food203: 'Brotes de alfalfa',
	food204: 'Cualquier verdura de hoja verde',
	food205: 'Alcachofas',
	food206: 'Espárragos',
	food207: 'Brotes de bambú',
	food208: 'Brotes de soja',
	food209: 'Hojas de remolacha',
	food210: 'Bok Choy',
	food211: 'Brócoli',
	food212: 'Coles de Bruselas',
	food213: 'Repollo',
	food214: 'Coliflor',
	food215: 'Apio',
	food216: 'Raíz de apio',
	food217: 'Acelga',
	food218: 'Cebollín',
	food219: 'Coles verdes',
	food220: 'Maíz',
	food221: 'Pepinos',
	food222: 'Dientes de leon',
	food223: 'Pepinillos',
	food224: 'Ajo',
	food225: 'Col rizada',
	food226: 'Puerros',
	food227: 'Lechugas y verduras para ensalada: rúcula, bok choy, lechuga de Boston, achicoria, escarola, escarola, hinojo, maché, radicchio, lechuga romana, acedera',
	food228: 'Habas',
	food229: 'Hongos',
	food230: 'Okra',
	food231: 'Olivos',
	food232: 'Cebollas',
	food233: 'Chícharos',
	food234: 'Patatas',
	food235: 'Rábanos',
	food236: 'Chucrut',
	food237: 'Cebolletas',
	food238: 'Chalotes',
	food239: 'Espinacas',
	food240: 'Patatas dulces',
	food241: 'Acelga',
	food242: 'Nabos',
	food243: 'Castañas de agua'
};

export default DATA;
