//@flow
import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { EdamamImage } from './components/EdamamImage';
import { Text, ClickableHeader, Loader } from 'Components/common';
import { Header } from 'Components/common';
import { Colors, i18n } from 'Theme';
import { receiveEdamamSingleRecipe } from './actions';
import { formatMacros, formatNutrients, formatIngredients } from './selectors';
import { ViewTabBar } from './ViewTabBar';
export const RecipeSingleScreen = (props) => {
	const { recipe } = props.route.params;
	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.edamamRecipeApi.isFetching);
	const { theme } = useSelector((state) => state.startup);
	const macros = useSelector((state) => formatMacros(state));
	const nutrients = useSelector((state) => formatNutrients(state));
	const ingredients = useSelector((state) => formatIngredients(state));
	const { carbUnits } = useSelector((state) => state.firebase.profile.settings.units);

	useEffect(() => {
		dispatch(receiveEdamamSingleRecipe([recipe]));
	}, []);

	if (isLoading) {
		return (
			<Loader
				full={false}
				loaderBG={theme.baseBG}
				loaderColor={theme.darkFont}
			/>
		);
	}

	return (
		<ScrollView
			style={{ flex: 1, backgroundColor: theme.baseBG }}
			keyboardShouldPersistTaps="handled">
			<Header title={recipe.label} imageURL={recipe.image} bgColor={theme.base}>
				<Text
					style={[styles.metaText, { color: Colors.blue }]}
					onPress={() => Linking.openURL(recipe.url)}>
					{recipe.source}
				</Text>
				<Text style={styles.metaText}>
					{i18n.t('makes')}: {recipe.yield} {i18n.t('servings')}
				</Text>
			</Header>
			<ClickableHeader
				leftText={i18n.t('directions')}
				noMargin
				isBlue
				onPress={() => Linking.openURL(recipe.url)}
				rightIconName={'arrow-right'}
				style={{ backgroundColor: theme.base }}
				iconColor={theme.darkFont}
			/>
			<ViewTabBar
				ingredients={ingredients}
				nutrients={nutrients}
				macros={macros}
				theme={theme}
				carbUnits={carbUnits}
			/>
			<EdamamImage />
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	metaText: {
		fontSize: 12,
		lineHeight: 18
	}
});
