	//@flow
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { updateDate, backOneDay, forwardOneDay } from '../actions';
import { FontAwesome } from '@expo/vector-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Elevations from 'react-native-elevation';
import moment from 'moment';

type Props = {
	theme: Object
};

export const DateHeader = (props: Props) => {
	const { theme } = props;
	const { date } = useSelector((state) => state.log);
	const dispatch = useDispatch();

	return (
		<View
			style={[
				styles.dateContainer,
				{ backgroundColor: theme.grey, borderBottomColor: theme.border }
			]}>
			<TouchableOpacity
				style={styles.dateArrows}
				onPress={() => dispatch(backOneDay(date))}>
				<FontAwesome name="minus-circle" size={20} color={theme.darkFont} />
			</TouchableOpacity>
			<DatePicker
				onChange={(x) => dispatch(updateDate(moment(x).format('YYYY-MM-DD')))}
				selected={moment(date, 'YYYY-MM-DD').toDate()}
			/>
			<TouchableOpacity
				style={styles.dateArrows}
				onPress={() => dispatch(forwardOneDay(date))}>
				<FontAwesome name="plus-circle" size={20} color={theme.darkFont} />
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	dateContainer: {
		zIndex: 9999,
		flexDirection: 'row',
		height: 50,
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomWidth: 1,
		...Elevations[1]

	},
	dateArrows: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	}
});
