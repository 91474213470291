//@flow
import { Platform } from 'react-native';
import Toast from 'react-native-root-toast';
import { WebCompatAlert } from 'Components/common';
import { i18n } from 'Theme';
import { convertToMetricForFirebase } from 'Utilities';
import { CHANGE_BM_REVIEW_NAME } from './types';
import { ahk_updateWeight } from 'Components/tracker/fitnesstrackers/utilities/AppleHealthKit';
import { fitbit_saveWeight } from 'Components/tracker/fitnesstrackers/utilities/Fitbit';
import moment from 'moment';
//Saves or Updates a BodyMeasurement Key/Value for a specific Date. Converts to metric value if not already before saving to Firebase
export const saveBodyMeasurement = (
	date: string,
	measurementName: string,
	value: string,
	unitsType: string
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { bodymeasurementsUnits } = state.firebase.profile.settings.units;
	const { ahkWasAuth } = state.fitnessTracker.appleHealthKit;
	const { auth: fitbitAuth } = state.fitnessTracker.fitbit;
	const ref = firebase.database().ref(`userdata/${uid}/bodymeasurements/`);
	let updates = {};
	const formattedMetricValue = convertToMetricForFirebase(
		unitsType,
		bodymeasurementsUnits,
		value
	);
	updates[`${date}/${measurementName}`] = +formattedMetricValue;

	if (measurementName === 'Weight') {
		if (Platform.OS === 'ios' && ahkWasAuth) {
			ahk_updateWeight(date, formattedMetricValue);
		}

		if (fitbitAuth.isAuth) {
			dispatch(
				fitbit_saveWeight(
					fitbitAuth.access_token,
					date,
					+value,
					bodymeasurementsUnits === 'metric'
				)
			);
		}
	}
	ref.update(updates).then(() => {
		Toast.show(i18n.t('updated'), {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
};

/*-------------------------------Review BodyMeasurement Tab----------------------------------------------*/
export const changeReviewName = (newName: string, index: number) => {
	const payLd = nameFind(newName);

	return {
		type: CHANGE_BM_REVIEW_NAME,
		value: payLd,
		label: newName,
		index: index
	};
};

//Converts Local Language String to English for Firebase Node Saving
const nameFind = (newName) => {
	switch (newName) {
		case i18n.t('Weight'):
			return 'Weight';
		case i18n.t('BMI'):
			return 'BMI';
		case i18n.t('BodyFat'):
			return 'Body Fat';
		case i18n.t('LeanBodyMass'):
			return 'Lean Body Mass';
		case i18n.t('WaistSize'):
			return 'Waist Size';
		case i18n.t('HipSize'):
			return 'Hip Size';
		case i18n.t('ChestSize'):
			return 'Chest Size';
		case i18n.t('NeckSize'):
			return 'Neck Size';
		case i18n.t('ArmSize'):
			return 'Arm Size';
		case i18n.t('ForearmSize'):
			return 'Forearm Size';
		case i18n.t('CalfSize'):
			return 'Calf Size';
		case i18n.t('ThighSize'):
			return 'Thigh Size';
		default:
			return 'Weight';
	}
};

//Delete Body Measurement Key/Value for a specific Date
export const deleteBodyMeasurement = (date: string, itemName: string) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/bodymeasurements/`);
	const formattedName = nameFind(itemName);
	let updates = {};
	updates[`${date}/${formattedName}`] = {};

	WebCompatAlert(
		i18n.t('delete'),
		i18n.t('confirmDelete'),
		i18n.t('delete'),
		() =>
			ref.update(updates).then(() => {
				Toast.show(i18n.t('updated'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			})
	);
};

/*export const createDemoBMData = () => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};

	///Adding Demo Data
	var startDate = new Date('2020-10-01'); //YYYY-MM-DD
	var endDate = new Date('2021-03-23'); //YYYY-MM-DD

	var getDateArray = (start, end) => {
		var arr = {};
		var dt = new Date(start);
		while (dt <= end) {
			arr[moment(dt).format('YYYY-MM-DD')] = {
				'Chest Size': Math.random() * (120 - 100) + 100,
				'Hip Size': Math.random() * (90 - 80) + 20,
				'Waist Size': Math.random() * (90 - 80) + 20,
				Weight: Math.random() * (90 - 70) + 70
			};
			dt.setDate(dt.getDate() + 1);
		}
		return arr;
	};

	var dateArr = getDateArray(startDate, endDate);
	updates['bodymeasurements'] = dateArr;
	ref.update(updates).then(() => console.log('DONOE'));
	console.log(JSON.stringify({ dateArr }));
};*/
