//@flow
import React, { useState } from 'react';
import {
	View,
	Image,
	KeyboardAvoidingView,
	Keyboard,
	TouchableWithoutFeedback,
	Platform,
	ScrollView
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { Nav } from './';
import { OptionTextInput, Text, Button, Gram } from 'Components/common';
import styles from './styles';
import {
	calculateProteinOptions,
	updateReducerCalcValue,
	calculateCustomResults
} from '../actions/MacroCalculatorActions';
import { validateAll } from 'Utilities';
import { Images, i18n } from 'Theme';

type Props = {
	onBack: Function,
	theme: Object
};

export const PageProtein = (props: Props) => {
	const [page, changePage] = useState(0);
	const { bodyFat, protein, baseProteins, calories, carb } = useSelector(
		(state) => state.calculator
	);
	const { weight } = useSelector((state) => state.firebase.profile.profilePage);
	const { bodymeasurementsUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);

	const [bfState, changeBF] = useState(bodyFat);
	const [proteinState, changeProtein] = useState(protein);

	const { onBack, theme } = props;
	const dispatch = useDispatch();

	const toProteinPage = () => {
		if (!validateAll([+bfState])) return;
		dispatch(updateReducerCalcValue('bodyFat', bfState));
		dispatch(
			calculateProteinOptions(
				weight,
				bfState,
				bodymeasurementsUnits === 'metric'
			)
		);
		changePage(page + 1);
	};

	const backToBodyFat = () => {
		if (!validateAll([+proteinState])) {
			changePage(page - 1);
		} else {
			dispatch(updateReducerCalcValue('protein', proteinState));
			changePage(page - 1);
		}
	};

	const submitModal = () => {
		if (!validateAll([+proteinState])) return;
		dispatch(updateReducerCalcValue('protein', proteinState));
		dispatch(calculateCustomResults(calories, proteinState, carb));
		onBack();
	};

	const closeModal = () => {
		if (!validateAll([+proteinState, +bfState])) {
			onBack();
		} else {
			dispatch(calculateCustomResults(calories, proteinState, carb));
			onBack();
		}
	};

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS === 'ios' ? 'padding' : null}
			style={styles.modalContainer}>
			<Nav
				onForward={page === 0 ? toProteinPage : submitModal}
				page={page}
				onBack={page === 1 ? backToBodyFat : closeModal}
				canClose={true}
				dotNumber={2}
				theme={theme}
			/>
			<TouchableWithoutFeedback
				onPress={Platform.OS === 'web' ? null : Keyboard.dismiss}>
				<ScrollView style={{ flex: 1 }}>
					{page === 0 && (
						<View style={styles.modalSubContainer}>
							<View style={styles.inputsContainer}>
								<OptionTextInput
									name={'bodyFat'}
									onChange={(value) => changeBF(value.replace(',', '.'))}
									currentValue={bfState}
									placeholder={i18n.t('bodyFat')}>
									<Gram customText={'%'} />
								</OptionTextInput>
							</View>
							<Text style={styles.pageDescription}>
								{i18n.t('bodyFatDetails')}
							</Text>
							<Image source={Images.bodyFatEstimate} style={styles.bodyFat} />
						</View>
					)}
					{page === 1 && (
						<View style={styles.modalSubContainer}>
							<Text style={styles.pageTitle}>{i18n.t('protein')}</Text>
							<Text style={styles.pageDescription}>
								{i18n.t('proteinDetail')}
							</Text>
							<Text style={styles.pageDescription}>
								{`${i18n.t('idealRange')}:\n ${
									baseProteins.maintain
								}g  (${i18n.t('maintainLower')})  -  ${
									baseProteins.gain
								}g  (${i18n.t('build')})`}
							</Text>
							<View style={styles.inputsContainer}>
								<OptionTextInput
									name={'protein'}
									onChange={(value) => changeProtein(value.replace(',', '.'))}
									currentValue={proteinState}
									placeholder={i18n.t('dailyProtein')}>
									<Gram />
								</OptionTextInput>
								<View
									style={[
										styles.deficitPresetsContainer,
										{ marginBottom: 20 }
									]}>
									<Button
										style={[
											styles.deficitPreset,
											{ backgroundColor: theme.blue }
										]}
										textStyle={styles.deficitPresetText}
										onPress={() => changeProtein(baseProteins.maintain)}>
										{i18n.t('maintainMuscle')}
									</Button>
									<Button
										style={[
											styles.deficitPreset,
											{ backgroundColor: theme.blue }
										]}
										textStyle={styles.deficitPresetText}
										onPress={() => changeProtein(baseProteins.gain)}>
										{i18n.t('buildMuscle')}
									</Button>
								</View>
							</View>
						</View>
					)}
				</ScrollView>
			</TouchableWithoutFeedback>
		</KeyboardAvoidingView>
	);
};
