//@flow
import React from 'react';
import {
	View,
	ScrollView,
	Platform,
	ActivityIndicator,
	Image,
	Alert
} from 'react-native';
import { connect } from 'react-redux';
import {
	savePurchaseData,
	promoCodeSuccess,
	submitPromoCode,
	restorePreviousAccount
} from './actions/PurchaseActions';
import { Button, Heading, Prompt, WhiteButton } from 'Components/common';
import styles from './styles/Styles';
import * as RNIap from 'react-native-iap';
import { Feature, Divider } from './components';
import { Images, i18n } from 'Theme';
import Lightbox from 'react-native-lightbox';

const products =
	Platform.OS === 'ios'
		? ['com.prestigeworldwide.myketotracker.dailytracker']
		: ['com.prestigeworldwide.keto.dailytracker'];

const mapStateToProps = (state) => {
	const { purchase } = state.purchase;
	const { theme } = state.startup;
	return { purchase, theme };
};

class PurchaseScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			title: i18n.t('purchaseTracking'),
			description: i18n.t('gettingProductDetails'),
			price: '',
			promoModal: false,
			promoCode: '',
			restoreModal: false,
			restoreEmail: ''
		};
	}

	async componentDidMount() {
		await RNIap.initConnection();
		try {
			const details = await RNIap.getProducts(products);
			this.setState({
				title: details[0].title,
				description: details[0].description,
				price: details[0].localizedPrice,
				loading: false
			});
		} catch (error) {
			return Alert.alert(
				i18n.t('error'),
				error.message ? error.message : i18n.t('errorProducts')
			);
		}
	}

	componentWillUnmount() {
		RNIap.endConnection();
	}

	onPurchase = async () => {
		const trackerSku =
			Platform.OS === 'ios'
				? 'com.prestigeworldwide.myketotracker.dailytracker'
				: 'com.prestigeworldwide.keto.dailytracker';
		try {
			RNIap.requestPurchase(trackerSku, false);
		} catch (error) {
			return Alert.alert(
				i18n.t('error'),
				error.message ? error.message : i18n.t('error')
			);
		}
	};

	onRestore = async () => {
		const trackerSku =
			Platform.OS === 'ios'
				? 'com.prestigeworldwide.myketotracker.dailytracker'
				: 'com.prestigeworldwide.keto.dailytracker';
		try {
			const purchases = await RNIap.getPurchaseHistory();

			if (purchases.length === 0) {
				Alert.alert(i18n.t('noPurchaseFound'), i18n.t('noPurchaseFoundDesc'));
			}

			purchases.forEach((purchase) => {
				if (purchase.productId === trackerSku) {
					this.props.savePurchaseData(purchase);
				}
			});
		} catch (error) {
			Alert.alert(
				i18n.t('error'),
				error.message ? error.message : i18n.t('error')
			);
		}
	};

	submitPromoCode = (promoSubmitted) => {
		this.props.submitPromoCode(promoSubmitted).then(() => {
			this.setState({ promoModal: false });
		});
	};

	submitRestoreCode = (emailSubmitted) => {
		this.props.restorePreviousAccount(emailSubmitted).then(() => {
			this.setState({ restoreModal: false });
		});
	};

	render() {
		const { theme } = this.props;
		return (
			<View style={{ flex: 1, backgroundColor: theme.baseBG }}>
				<ScrollView>
					{this.state.loading ? (
						<ActivityIndicator size="large" style={styles.activityIndicator} />
					) : (
						<View>
							<View
								style={[styles.btnContainer, { backgroundColor: theme.base }]}>
								<Button
									onPress={() => this.onPurchase()}
									style={styles.buttonStyle}>
									Get Premium - {this.state.price}
								</Button>
							</View>
							<View style={styles.featureContainer}>
								<Feature
									icon="check"
									title="Premium diet tracking with no monthly subscription!"
									body={`Gain access to premium features for a one time only payment of ${this.state.price}. Paying the same price monthly for little to no extra value just doesn't make sense.`}
								/>
								<Divider />
								<Feature
									icon="check"
									title="Extra Measurements and Vitals"
									body="Track all body measurements such as BMI, Lean Body Mass, Waist, Hip, Chest Size and more. Additionally track Heart Rate and Blood Pressure in the Vitals section."
								/>
								<View style={styles.imageRow}>
									<Lightbox
										renderContent={() => (
											<View style={styles.modalImgCtn}>
												<Image
													style={styles.modalImg}
													source={Images.purchase1a}
												/>
											</View>
										)}>
										<View style={styles.thumbCtn}>
											<Image
												style={styles.thumbImg}
												source={Images.purchase1a}
											/>
										</View>
									</Lightbox>
									<Lightbox
										renderContent={() => (
											<View style={styles.modalImgCtn}>
												<Image
													style={styles.modalImg}
													source={Images.purchase1b}
												/>
											</View>
										)}>
										<View style={styles.thumbCtn}>
											<Image
												style={styles.thumbImg}
												source={Images.purchase1b}
											/>
										</View>
									</Lightbox>
								</View>
								<Divider />
								<Feature
									icon="check"
									title="Track Nutrients"
									body="Follow more than just your macros. Keep track of: Cholesterol, Sodium, Calcium, Magnesium, Potassium, Iron, Zinc, Phosphorus, Folate, Thiamin (B1), Riboflavin (B2), Niacin (B3), Vitamin B6, Vitamin B12, Vitamin A,C,D,E,K, "
								/>
								<View style={styles.imageRow}>
									<Lightbox
										renderContent={() => (
											<View style={styles.modalImgCtn}>
												<Image
													style={styles.modalImg}
													source={Images.purchase2a}
												/>
											</View>
										)}>
										<View style={styles.thumbCtn}>
											<Image
												style={styles.thumbImg}
												source={Images.purchase2a}
											/>
										</View>
									</Lightbox>
									<Lightbox
										renderContent={() => (
											<View style={styles.modalImgCtn}>
												<Image
													style={styles.modalImg}
													source={Images.purchase2b}
												/>
											</View>
										)}>
										<View style={styles.thumbCtn}>
											<Image
												style={styles.thumbImg}
												source={Images.purchase2b}
											/>
										</View>
									</Lightbox>
								</View>
								<Divider />
								<Feature
									icon="check"
									title="CSV Data Export"
									body="Export all of your Diet Log, Body Measurements, Vitals and Other Tracking items to CSV"
								/>
								<View style={styles.imageRow}>
									<Lightbox
										renderContent={() => (
											<View style={styles.modalImgCtn}>
												<Image
													style={styles.modalImg}
													source={Images.purchase3a}
												/>
											</View>
										)}>
										<View style={styles.thumbCtn}>
											<Image
												style={styles.thumbImg}
												source={Images.purchase3a}
											/>
										</View>
									</Lightbox>
									<Lightbox
										renderContent={() => (
											<View style={styles.modalImgCtn}>
												<Image
													style={styles.modalImg}
													source={Images.purchase3b}
												/>
											</View>
										)}>
										<View style={styles.thumbCtn}>
											<Image
												style={styles.thumbImg}
												source={Images.purchase3b}
											/>
										</View>
									</Lightbox>
									<Lightbox
										renderContent={() => (
											<View style={styles.modalImgCtn}>
												<Image
													style={styles.modalImg}
													source={Images.purchase3c}
												/>
											</View>
										)}>
										<View style={styles.thumbCtn}>
											<Image
												style={styles.thumbImg}
												source={Images.purchase3c}
											/>
										</View>
									</Lightbox>
								</View>
								<Divider />
								<Feature
									icon="check"
									title="Web Portal Access"
									body="Don't want to track on your phone? Use the website portal to track just as you would on your mobile device"
								/>
								<View style={styles.imageRow}>
									<Lightbox
										renderContent={() => (
											<View style={styles.modalImgCtn}>
												<Image
													style={styles.modalImg}
													source={Images.purchase4}
												/>
											</View>
										)}>
										<View style={styles.thumbCtn}>
											<Image
												style={styles.thumbImg}
												source={Images.purchase4}
											/>
										</View>
									</Lightbox>
								</View>
								<Divider />
								<Feature
									icon="check"
									title="Ad-Free"
									body="Remove all ads from the entire app"
								/>
								<Divider />
								<Feature
									icon="check"
									title="Premium Customer Service"
									body="Get support quickly over non premium users"
								/>
							</View>
							<View
								style={[styles.btnContainer, { backgroundColor: theme.base }]}>
								<Button
									onPress={() => this.onPurchase()}
									style={styles.buttonStyle}>
									Get Premium - {this.state.price}
								</Button>
							</View>
							<View
								style={[
									styles.restoreContainer,
									{ backgroundColor: theme.base }
								]}>
								<Button
									onPress={() => this.onRestore()}
									style={styles.restoreBtn}>
									Restore Previous Purchase
								</Button>
								{Platform.OS === 'android' && (
									<WhiteButton
										onPress={() => this.setState({ promoModal: true })}
										style={styles.promoBtn}>
										Promo Code
									</WhiteButton>
								)}
							</View>
						</View>
					)}
				</ScrollView>
				<Prompt
					title={'Promo Code'}
					placeholder="Promo Code"
					defaultValue=""
					visible={this.state.promoModal}
					onCancel={() => this.setState({ promoModal: false })}
					onSubmit={(value) => this.submitPromoCode(value)}
					onClosed={() => this.setState({ promoModal: false })}
					submitText="Submit"
					textInputProps={{ autoCapitalize: 'none' }}
					theme={this.props.theme}
				/>
				<Prompt
					title={'Restore via Email'}
					placeholder="Enter Email Of Account"
					defaultValue=""
					visible={this.state.restoreModal}
					onCancel={() => this.setState({ restoreModal: false })}
					onSubmit={(value) => this.submitRestoreCode(value)}
					onClosed={() => this.setState({ restoreModal: false })}
					submitText="Submit"
					textInputProps={{ autoCapitalize: 'none' }}
					theme={this.props.theme}
				/>
			</View>
		);
	}
}

export default connect(mapStateToProps, {
	savePurchaseData,
	promoCodeSuccess,
	submitPromoCode,
	restorePreviousAccount
})(PurchaseScreen);
