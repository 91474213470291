const DATA = {
	food0: 'Blue Cheese',
	food1: 'Cheddar Cheese',
	food2: 'Cottage Cheese',
	food3: 'Cream Cheese',
	food4: 'Feta Cheese',
	food5: 'Gouda Cheese',
	food6: 'Mozzarella Cheese',
	food7: 'Provolone Cheese',
	food8: 'Ricotta Cheese',
	food9: 'Swiss Cheese',
	food10: 'Coconut Cream',
	food11: 'Coconut Milk (unsweetened)',
	food12: 'Egg',
	food13: 'Heavy Cream',
	food14: 'Ice Cream (unsweetened)',
	food15: 'Milk',
	food16: 'Soy Milk (unsweetened)',
	food17: 'Whipped Cream (unsweetened)',
	food18: 'Yogurt / Kefir, Plain',
	food19: 'Blue Cheese',
	food20: 'Creamy Caesar',
	food21: 'Ranch',
	food22: 'Almond Oil',
	food23: 'Butter',
	food24: 'Cocoa Butter',
	food25: 'Coconut Oil',
	food26: 'Fish Oil (cod liver)',
	food27: 'Flax Seed Oil',
	food28: 'Grape Seed Oil',
	food29: 'Hemp Seed Oil',
	food30: 'Mayonnaise',
	food31: 'Olive Oil',
	food32: 'Safflower Oil',
	food33: 'Sesame Oil',
	food34: 'Sunflower Oil',
	food35: 'Vegetable Oil',
	food36: 'Flounder',
	food37: 'Herring',
	food38: 'Salmon',
	food39: 'Sardines',
	food40: 'Sole',
	food41: 'Trout',
	food42: 'Tuna',
	food43: 'Tuna (canned)',
	food44: 'Almond Flour',
	food45: 'Cocoa Powder (unsweetened)',
	food46: 'Flax Seed Meal',
	food47: 'Protein Powder',
	food48: 'Psyllium Husk',
	food49: 'Wheat Flour',
	food50: 'Chicken Legs',
	food51: 'Chicken Wings',
	food52: 'Duck',
	food53: 'Goose',
	food54: 'Quail',
	food55: 'Turkey, breast',
	food56: 'Turkey, ground',
	food57: 'Turkey, bacon',
	food58: 'Barley',
	food59: 'Boxed processed foods',
	food60: 'Bread',
	food61: 'Bread crumbs',
	food62: 'Cakes',
	food63: 'Canned soups & stews',
	food64: 'Cold cereals',
	food65: 'Cookies',
	food66: 'Corn chips',
	food67: 'Cornbread',
	food68: 'Cornmeal',
	food69: 'Crackers',
	food70: 'Grits',
	food71: 'Hash browns',
	food72: 'Hot cereals',
	food73: 'Muffins, plain',
	food74: 'Pancakes',
	food75: 'Pasta',
	food76: 'Pies',
	food77: 'Polenta',
	food78: 'Popcorn',
	food79: 'Potato chips',
	food80: 'Pretzels',
	food81: 'Rice',
	food82: 'Rye',
	food83: 'Spelt',
	food84: 'Tamale wrappers',
	food85: 'Tater tots',
	food86: 'Teff',
	food87: 'Tortillas',
	food88: 'Tricale',
	food89: 'Waffles',
	food90: 'Beef, ribs',
	food91: 'Beef, sausage',
	food92: 'Beef, steak',
	food93: 'Lamb, chops',
	food94: 'Pepperoni, pork/beef',
	food95: 'Pork, bacon',
	food96: 'Pork, chops',
	food97: 'Pork, ham',
	food98: 'Pork, sausage',
	food99: 'Veal',
	food100: 'Venison, steak',
	food101: 'Beans',
	food102: 'Cashews',
	food103: 'Coconut (meat, unsweetened)',
	food104: 'Hazelnuts',
	food105: 'Lentils',
	food106: 'Macadamias',
	food107: 'Peanuts',
	food108: 'Peanut Butter',
	food109: 'Pecans',
	food110: 'Pistachios',
	food111: 'Walnuts',
	food112: 'Flax Seeds',
	food113: 'Hemp Seeds',
	food114: 'Pumpkin Seeds',
	food115: 'Safflower Seeds',
	food116: 'Sesame Seeds',
	food117: 'Sunflower Seeds',
	food118: 'Crab meat',
	food119: 'Lobster',
	food120: 'Mussels',
	food121: 'Oysters',
	food122: 'Shrimp',
	food123: 'Squid',
	food124: 'Clear broth or bouillon',
	food125: 'Coffee',
	food126: 'Crystal Light',
	food127: 'Diet sodas (Coke Zero, Diet Coke)',
	food128: 'Diet tonic water',
	food129: 'Flavored seltzer water (unsweetened)',
	food130: 'Herbal tea (unsweetened)',
	food131: 'Lemon and lime juice (Limited)',
	food132: 'Seltzer water',
	food133: 'Soda',
	food134: 'Soda water',
	food135: 'Sugar-free energy drinks (Red Bull, Monster, etc.)',
	food136: 'Sparkling water (Perrier)',
	food137: 'Tea (unsweetened)',
	food138: 'Water',
	food139: 'Beer',
	food140: 'Brandy',
	food141: 'Cognac (Hennessy, etc.)',
	food142: 'Gin (Tanqueray, Beefeater, etc)',
	food143: 'Light Beers (ie Coors, Bud Light)',
	food144: 'Rum (Captain Morgan, etc)',
	food145: 'Scotch',
	food146: 'Tequila',
	food147: 'Vodka (Absolut, Grey Goose, etc.)',
	food148: 'Wine (Dry)',
	food149: 'Wine (Dessert)',
	food150: "Whiskey (Jack Daniel's, etc.)",
	food151: 'Brown sugar',
	food152: 'Candy',
	food153: 'Cane sugar',
	food154: 'Corn syrup',
	food155: 'Dextrose',
	food156: 'Erythritol',
	food157: 'Fructose',
	food158: 'Glucose',
	food159: 'Honey',
	food160: 'Lactose',
	food161: 'Maltose',
	food162: 'Maple syrup',
	food163: 'Powdered sugar',
	food164: 'Sorghum',
	food165: 'Stevia',
	food166: 'Sucrose',
	food167: 'Apples',
	food168: 'Apricot',
	food169: 'Avocado',
	food170: 'Banana',
	food171: 'Blackberries',
	food172: 'Blueberries',
	food173: 'Cantaloupe',
	food174: 'Cherries',
	food175: 'Cranberries',
	food176: 'Dates',
	food177: 'Dragon fruit',
	food178: 'Durians',
	food179: 'Figs',
	food180: 'Grapes',
	food181: 'Grapefruit',
	food182: 'Honeydew',
	food183: 'Juice',
	food184: 'Kiwi',
	food185: 'Lemons',
	food186: 'Limes',
	food187: 'Mango',
	food188: 'Nectarines',
	food189: 'Oranges',
	food190: 'Papaya',
	food191: 'Passion fruit',
	food192: 'Peach',
	food193: 'Pears',
	food194: 'Pineapples',
	food195: 'Pomegranate',
	food196: 'Plums',
	food197: 'Prunes',
	food198: 'Raisins',
	food199: 'Raspberries',
	food200: 'Strawberries',
	food201: 'Tangerines',
	food202: 'Watermelon',
	food203: 'Alfalfa Sprouts',
	food204: 'Any leafy green vegetable',
	food205: 'Artichokes',
	food206: 'Asparagus',
	food207: 'Bamboo Shoots',
	food208: 'Bean Sprouts',
	food209: 'Beet Greens',
	food210: 'Bok Choy',
	food211: 'Broccoli',
	food212: 'Brussels Sprouts',
	food213: 'Cabbage',
	food214: 'Cauliflower',
	food215: 'Celery',
	food216: 'Celery Root',
	food217: 'Chard',
	food218: 'Chives',
	food219: 'Collard Greens',
	food220: 'Corn',
	food221: 'Cucumbers',
	food222: 'Dandelion Greens',
	food223: 'Dill Pickles',
	food224: 'Garlic',
	food225: 'Kale',
	food226: 'Leeks',
	food227:
		'Lettuces and salad greens: Arugula, Bok Choy, Boston lettuce, Chicory, Endive, Escarole,Fennel, Mache, Radicchio, Romaine, Sorrel',
	food228: 'Lima beans',
	food229: 'Mushrooms',
	food230: 'Okra',
	food231: 'Olives',
	food232: 'Onions',
	food233: 'Peas',
	food234: 'Potatoes',
	food235: 'Radishes',
	food236: 'Sauerkraut',
	food237: 'Scallions',
	food238: 'Shallots',
	food239: 'Spinach',
	food240: 'Sweet potatoes',
	food241: 'Swiss Chard',
	food242: 'Turnips',
	food243: 'Water Chestnuts'
};

export default DATA;
