//@flow
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducer from './reducers';
import { getFirebase } from 'react-redux-firebase';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

export const reduxFirebaseConfig = {
	userProfile: 'userdata', // root that user profiles are written to
	updateProfileOnLogin: false, // prevents resetting user's data back to these defaults on re-login
	enableRedirectHandling: false, // enable/disable updating of profile on login
	logErrors: false,
	profileFactory: (userData, profileData) => {
		const { user } = userData;
		return {
			email: user.email || user.providerData[0].email,
			id: user.uid || user.providerData[0].uid,
			settings: {
				goals: { cal: 2000, carb: 5, fat: 75, protein: 20 },
				carbgrams: 25,
				fatgrams: 167,
				proteingrams: 100,
				units: {
					bodymeasurementsUnits: 'imperial', //default imperial units, metric
					glucoseUnits: 'mmol/L', // default mmol/L, mg/dL
					ketonesUnits: 'mmol/L', // default mmol/L, mg/dL
					exerciseUnits: 'kCal', //default kCal, kJ
					waterUnits: 'oz', // default  oz, ml
					carbUnits: 'net' //defaults to net, total
				}
			},
			profilePage: {
				height: '175.3', // in centimeters
				weight: '79.8323', // in kg
				gender: 'female', //false === female, true === male
				age: 30, // years
				goalWeight: '77.11', //in kg
				exercise: 1.55
			},
			onBoard: {
				shown: false,
				subscribedEmail: false,
				checkedInterests: [{ name: 'WeightLoss', status: 'inactive' }]
			},
			vitalsConverted: true,
			bodymeasurementsConverted: true,
			othertrackingConverted: true,
			premium: false

			/*customfoodsv3: {},
		savedmealsv3: {},
		trackerdate: {},
		recentbarcodes: {},
		recentlyused: {},
		recipes: {},
		bodymeasurements: {},
		vitals: {},
		othertracking: {}
		*/
		};
	}
};

const args = __DEV__
	? [
			reduxImmutableStateInvariant({
				ignore: ['firebase']
			}),
			thunk.withExtraArgument(getFirebase),
			logger
	  ]
	: [thunk.withExtraArgument(getFirebase)];

export default () => {
	let store = createStore(reducer, compose(applyMiddleware(...args)));
	let persistor = persistStore(store);
	return { store, persistor };
};
