//@flow
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
	NavOptionsHeader,
	NavOptionsHeaderNoDraw,
	NavOptionsBottomTab
} from '../NavOptionsHeader';
import { Ionicons } from '@expo/vector-icons';
import { i18n } from 'Theme';
import { FsSearchScreen } from 'Components/fatsecret/FsSearchScreen';
import { FsSingleScreen } from 'Components/fatsecret/FsSingleScreen';
import { BarcodeScreen } from 'Components/fatsecret/BarcodeScreen';
import { OfflineScreen } from 'Components/foodlist/OfflineScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

//Net Carb Stack
const FsTabStack = () => {
	return (
		<Stack.Navigator initialRouteName="NetCarbSearch">
			<Stack.Screen
				name="NetCarbSearch"
				component={FsSearchScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('netcarbFinder')}
							/>
						);
					}
				}}
			/>
			<Stack.Screen
				name="NetCarbSearchSingle"
				component={FsSingleScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeaderNoDraw
								navigation={navigation}
								title={i18n.t('netcarbFinder')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const BarcodeStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="NetCarbBarcode"
				component={BarcodeScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('netcarbFinder')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};
const OfflineStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="Offline"
				component={OfflineScreen}
				options={{
					header: ({ navigation, scene }) => {
						return (
							<NavOptionsHeader
								navigation={navigation}
								title={i18n.t('netcarbFinder')}
							/>
						);
					}
				}}
			/>
		</Stack.Navigator>
	);
};

const FoodListRouter = () => {
	return (
		<Tab.Navigator
			initialRouteName="NetCarbSearch"
			tabBar={(props) => <NavOptionsBottomTab {...props} />}
			backBehavior={'history'}>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('search'),
					tabBarIcon: ({ focused, color, size }) => (
						<Ionicons name={'ios-search'} size={size} color={color} />
					)
				}}
				name="NetCarbSearchTab"
				component={FsTabStack}
			/>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('barcode'),
					tabBarIcon: ({ focused, color, size }) => (
						<Ionicons name={'ios-barcode'} size={size} color={color} />
					)
				}}
				name="NetCarbBarcode"
				component={BarcodeStack}
			/>
			<Tab.Screen
				options={{
					tabBarLabel: i18n.t('offline'),
					tabBarIcon: ({ focused, color, size }) => (
						<Ionicons name={'ios-list'} size={size} color={color} />
					)
				}}
				name="Offline"
				component={OfflineStack}
			/>
		</Tab.Navigator>
	);
};

export default FoodListRouter;
