//@flow
import React from 'react';
import { ScrollView } from 'react-native';
import { Paragraph, Heading, Divider } from '../common';
import styles from './Styles/Styles';
import { i18n } from 'Theme';

export const GetStarted = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={styles.container}>
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t('getstartedh0')}</Heading>
			<Paragraph>{i18n.t('getstartedp0')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t('getstartedh1')}</Heading>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t('getstartedh2')}</Heading>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t('getstartedh3')}</Heading>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t('getstartedh4')}</Heading>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t('getstartedh5')}</Heading>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>{i18n.t('getstartedh6')}</Heading>
			<Divider />
		</ScrollView>
	);
};
