//@flow
import React, { useState } from 'react';
import {
	View,
	ScrollView,
	StyleSheet,
	FlatList,
	Picker,
	Platform
} from 'react-native';
import { DropdownButton, Divider, Text } from '../common';
import { i18n } from 'Theme';
import ModalSelector from 'react-native-modal-selector';
import DATA from './data/foods';
import { useSelector } from 'react-redux';

//Food Filters
const FOOD_CATEGORIES = [
	{ key: 0, label: i18n.t('all') },
	{ key: 1, label: i18n.t('alcohol') },
	{ key: 2, label: i18n.t('beverages') },
	{ key: 3, label: i18n.t('cheeses') },
	{ key: 4, label: i18n.t('dairy') },
	{ key: 5, label: i18n.t('dressings') },
	{ key: 6, label: i18n.t('fatsoils') },
	{ key: 7, label: i18n.t('fish') },
	{ key: 8, label: i18n.t('flours') },
	{ key: 9, label: i18n.t('fowl') },
	{ key: 10, label: i18n.t('fruits') },
	{ key: 11, label: i18n.t('grainsstarch') },
	{ key: 12, label: i18n.t('meats') },
	{ key: 13, label: i18n.t('nutslegumes') },
	{ key: 14, label: i18n.t('seeds') },
	{ key: 15, label: i18n.t('shellfish') },
	{ key: 16, label: i18n.t('sugarssweetener') },
	{ key: 17, label: i18n.t('vegetables') }
];

export const OfflineScreen = () => {
	const [filter, setFilter] = useState(FOOD_CATEGORIES[0]);
	const [data, setData] = useState(
		DATA.sort((a, b) => (a.title > b.title ? 1 : -1))
	);
	const { theme } = useSelector((state) => state.startup);

	const onChange = (option) => {
		setFilter(FOOD_CATEGORIES[option.key]);
		FOOD_CATEGORIES[option.key].label === i18n.t('all')
			? setData(DATA.sort((a, b) => (a.title > b.title ? 1 : -1)))
			: setData(
					DATA.filter((obj) => {
						return obj.type === option.label;
					})
			  );
	};

	//Render Each Food and NetCarb Number
	const renderItem = ({ item }) => {
		//Determine Color for Net CarbCircle
		let circleColor = {};
		if (item.netcarbs < 4) {
			circleColor = { backgroundColor: '#98bb56' };
		} else if (item.netcarbs >= 4 && item.netcarbs < 10) {
			circleColor = { backgroundColor: '#fbc02d' };
		} else if (item.netcarbs >= 10) {
			circleColor = { backgroundColor: '#e51c23' };
		}
		return (
			<View style={[styles.row, { borderBottomColor: theme.border }]}>
				<View style={styles.leftRow}>
					<Text style={styles.titleStyle}>{item.title}</Text>
					<Text style={styles.servingSize}>{item.servingsize}</Text>
				</View>
				<View style={[styles.colorCircle, circleColor]}>
					<Text style={[styles.netCarbStyle, { color: theme.white }]}>
						{item.netcarbs}
					</Text>
				</View>
			</View>
		);
	};

	return Platform.OS === 'web' ? (
		<ScrollView style={{ backgroundColor: theme.baseBG }}>
			<Divider />
			<Picker
				selectedValue={filter.key}
				style={styles.webPicker}
				onValueChange={(itemValue, itemIndex) => {
					setFilter(FOOD_CATEGORIES[itemIndex]);
					onChange(FOOD_CATEGORIES[itemIndex]);
				}}>
				{FOOD_CATEGORIES.map((item) => (
					<Picker.Item label={item.label} value={item.key} key={item.key} />
				))}
			</Picker>
			<FlatList
				data={data}
				keyExtractor={(item, index) => item + index}
				renderItem={renderItem}
			/>
		</ScrollView>
	) : (
		<View style={[styles.container, { backgroundColor: theme.baseBG }]}>
			<ModalSelector
				data={FOOD_CATEGORIES}
				initValue={filter.label}
				onChange={(option) => onChange(option)}
				style={{ backgroundColor: theme.base }}>
				<DropdownButton title={filter.label} />
			</ModalSelector>
			<FlatList
				style={{ marginTop: 0 }}
				data={data}
				keyExtractor={(item, index) => item + index}
				renderItem={renderItem}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginBottom: 40
	},
	row: {
		flex: 1,
		flexDirection: 'row',
		borderBottomWidth: 1,
		paddingVertical: 7,
		paddingHorizontal: 10,
		justifyContent: 'center'
	},
	leftRow: {
		flex: 2,
		justifyContent: 'center'
	},
	titleStyle: {
		fontSize: 14,
		lineHeight: 20
	},
	servingSize: {
		fontSize: 8,
		lineHeight: 16,
		paddingLeft: 1
	},
	netCarbStyle: {
		fontSize: 14
	},
	colorCircle: {
		borderRadius: 25,
		height: 50,
		width: 50,
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center'
	}
});
