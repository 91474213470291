//@flow
import React from 'react';
import { ScrollView, Linking, View, Image } from 'react-native';
import { Paragraph, Heading, BlockQuote, Divider, Text } from '../common';
import styles from './Styles/Styles';
import { i18n, Images } from 'Theme';

export const Myths = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={styles.container}>
			<View>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('mythsh0')}
				</Heading>
				<Paragraph>{i18n.t('mythsp0')}</Paragraph>
				<Divider />
				<Image
					resizeMode="contain"
					style={styles.imageStyle}
					source={Images.satFat}
				/>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('mythsh1')}
				</Heading>
				<Paragraph>{i18n.t('mythsp1')}</Paragraph>
				<Divider />
				<BlockQuote
					ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('mythsbq1')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://www.hopkinsmedicine.org/news/media/releases/low_carb_higher_fat_diets_add_no_arterial_health_risks_to_obese_people_seeking_to_lose_weight'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('mythsh2')}
				</Heading>
				<Paragraph>{i18n.t('mythsp2')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('mythsh3')}
				</Heading>
				<Paragraph>{i18n.t('mythsp3')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('mythsh4')}
				</Heading>
				<Paragraph>{i18n.t('mythsp4')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('mythsh5')}
				</Heading>
				<Paragraph>{i18n.t('mythsp5')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('mythsh6')}
				</Heading>
				<Paragraph>{i18n.t('mythsp6')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('mythsh7')}
				</Heading>
				<Paragraph>{i18n.t('mythsp7')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('mythsh8')}
				</Heading>
				<Paragraph>{i18n.t('mythsp8')}</Paragraph>
				<Divider />
				<BlockQuote
					ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}
					style={{ paddingTop: 0, alignItems: 'center' }}>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'https://www.health.harvard.edu/blog/ketogenic-diet-is-the-ultimate-low-carb-diet-good-for-you-2017072712089'
							)
						}>
						{'\n'}Source
					</Text>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('https://www.dietdoctor.com/low-carb/science')
						}>
						{'\n'}Source 2
					</Text>
				</BlockQuote>
			</View>
		</ScrollView>
	);
};
