//@flow
import React from 'react';
import {
	ScrollView,
	Linking,
	TouchableOpacity,
	View,
	Image
} from 'react-native';
import { Paragraph, Heading, Text, Divider } from 'Components/common';
import styles from './Styles/Styles';

export const Products = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={styles.container}>
			<View>
				<Heading style={{ backgroundColor: theme.base }}>Recommended Products</Heading>
				<Paragraph>
					These are some items that will help make the keto diet easier to
					maintain and a whole lot more enjoyable. By no means are they
					required, and you can get by just fine without them.
				</Paragraph>
				<Heading style={{ backgroundColor: theme.base }}>Ketone Strips (Ketostix)</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B00SODYZQK/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00SODYZQK&linkCode=as2&tag=pwwapps-20&linkId=7bdcd1a821c04a3978f9a46623bb240a'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00SODYZQK&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					Ever worry if that cookie you ate or that beer you chugged knocked you
					out of Ketosis? Well worry no more! Just put a little bit of your pee
					on these strips of paper, and it will check for the presence of
					ketones in your urine. Along with their convenience, they are also
					very cheap.
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Kitchen and Food Products</Heading>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Erythritol Natural Sweetener</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B000Z978SS/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B000Z978SS&linkCode=as2&tag=pwwapps-20&linkId=a44767be58b6ac12686b6fa8bfb8d53c'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B000Z978SS&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					In general, if something tastes sweet, there is probably some form of
					sugar in it. Since you won't be consuming very many carbs, you may be
					thinking that you will have to give up sweets, but that is not the
					case at all. Erythritol is one of the most popular zero carb
					sweeteners among keto dieters. It is a "sugar-alcohol", which for some
					may cause gas, bloating, digestive discomfort, and may kick you out of
					ketosis. However, erythritol is much more tolerated and is very
					difficult for intestinal bacteria to digest, thus comes with little to
					no side-effects. You have to use a little more of it than regular
					sugar to get the same sweetness, but compared to other zero-calorie
					sweeteners, it taste very similar to the real thing and has no
					aftertaste.
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Stevia Natural Sweetener</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B00430EJR8/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00430EJR8&linkCode=as2&tag=pwwapps-20&linkId=5f51f0e73c4fe6eff1568fc18e44d283'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00430EJR8&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					Stevia is another natural no-carb sweetener that is popular among keto
					dieters. The extract is from the Stevia rebaudiana leaf and is up to
					150x sweeter than table sugar, so a little goes a long way with this.
					If you try out Stevia and find that you don't like it, then give
					Truvia a try (its a combo of both Erythritol and Stevia).
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Almond Flour</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B00HS01I1A/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00HS01I1A&linkCode=as2&tag=pwwapps-20&linkId=39468c8ab215388b34323e385ef306bd'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00HS01I1A&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					Almond flour is one of the best low carb baking flours. It has a high
					fat and high protein content, since its basically just finely ground
					almonds. The one draw-back of almond flour is that is has a very high
					concentration of Omega-6 fatty acids (pro-inflammatory). If you are
					not eating a ton of seafood, it may be best to use this flour in
					limited quantities, or even try out flaxseed flour (flax meal) which
					is high in Omega-3s.
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Coconut Flour</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B00IDCFXG8/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00IDCFXG8&linkCode=as2&tag=pwwapps-20&linkId=32f87e11bb07614ed58b107086e7e5da'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00IDCFXG8&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					Coconut flour is another popular alternative for baking. It has a
					slightly sweet flavor, and since it is mostly fiber (ground up coconut
					meat), it sucks up a lot more moisture than almond flour. Therefore,
					you are going to need a lot more liquids and eggs in whatever you are
					baking, or else you will get a really dry result. Compared to almond
					flour, it has a much lower concentration of Omega-6 fatty acids. You
					may have some trouble at first trying to substitute this flour in
					place of others, so try to stick to recipes that call specifically for
					it until you get used to its consistency.
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>MCT Oil</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B00QL083S4/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00QL083S4&linkCode=as2&tag=pwwapps-20&linkId=278931506bd7578891517dd6394b6be7'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00QL083S4&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					One of the reasons Coconut Oil is so popular and beneficial to our
					health is because it contains large amounts of medium chain
					triglycerides (MCTs). One advantage of MCTs is that they have a
					loophole in our digestion. They provide quick energy by going straight
					to the liver, where they are turned into ketones for the blood stream.
					MCTs show evidence of boosting your metabolism, increasing fat burning
					(oxidation), and maintaining a healthy weight. Try adding it to your
					coffee to make the famous "Bulletproof Coffee".
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Quest Bars</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B00FY0TCZK/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00FY0TCZK&linkCode=as2&tag=pwwapps-20&linkId=73ac6de5b825a544bf583e70737b1797'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00FY0TCZK&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					Quest bars are really one of the only true keto-friendly protein bars.
					With about 6g net carbs, they are high in milk protein (~20g) and
					moderate in fats (~9g). These are great for taking on the go for a
					snack, and when you don't have time to cook or find a keto friendly
					restaurant. Be sure not to go overboard with these, too much protein
					in your diet can convert to glucose and break ketosis. Costco
					(Kirkland) also has their own version of these bars as well.
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Spiralizer</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B00GRIR87M/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00GRIR87M&linkCode=as2&tag=pwwapps-20&linkId=e04c90884617cba93972c16821929d93'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00GRIR87M&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					Miss your favorite pasta and noodle dishes since going Keto? A
					Spiralizer basically converts any vegetable into low carb, delicious,
					uniform noodles. You can use it to create not only noodles, but also
					place the spiralized vegetables in salads and casseroles. Zucchini is
					the most popular vegetable to use when making pasta substitute
					noodles.
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Crockpot</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B003HF6PUO/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B003HF6PUO&linkCode=as2&tag=pwwapps-20&linkId=57ab444e3eb5d336b0f1b9f11614497c'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B003HF6PUO&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					Crockpots are by far one of the most convenient ways to make delicious
					meals (especially in bulk). You throw in a few ingredients, set the
					switch to low or high for 4-8 hours, then come back and have an
					awesome smelling meal ready. Making cooking easy will actually help
					you to stick to the diet instead of cheating because you don't want to
					cook something extravagant.
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Supplements</Heading>
				<Heading style={{ backgroundColor: theme.base }}>Fish Oil (Omega-3 Oil)</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B00CAZAU62/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00CAZAU62&linkCode=as2&tag=pwwapps-20&linkId=a7e593237f311f7cb3d95f53cf556fb2'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00CAZAU62&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					Fish Oil is important because it contains anti-inflammatory Omega-3
					oils. The scientific research hints that an ideal intake of
					Omega-3:Omega-6 should be somewhere at 1:1 - 1:5. However, the typical
					western diet is more like 1:26, with a large excess of inflammatory
					Omega-6 fats. Since most of your diet will be coming from fats, you
					need to make sure that you take this ratio into account. Try to eat
					more seafood, or grass fed meats (especially for the really fatty
					cuts).
				</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>Multi-Vitamin</Heading>
				<Divider />
				<TouchableOpacity
					onPress={() =>
						Linking.openURL(
							'https://www.amazon.com/gp/product/B002RL8FCU/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B002RL8FCU&linkCode=as2&tag=pwwapps-20&linkId=b83abcccafa1f58e3933fe40b33dbd69'
						)
					}>
					<View
						style={{
							flex: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image
							source={{
								uri:
									'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B002RL8FCU&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=pwwapps-20'
							}}
							style={styles.productImg}
						/>
						<Text>See More</Text>
					</View>
				</TouchableOpacity>
				<Divider />
				<Paragraph>
					Carbohydrates provide us with a lot of beneficial vitamins, minerals,
					and antioxidants. If you are eating all your vegetables on keto, you
					should be more than fine. However, it's always good to have a safety
					net, especially when just starting off and all you find yourself
					eating is meat and cheese.
				</Paragraph>
				<Divider />
			</View>
		</ScrollView>
	);
};
