const DATA = {
	food0: 'Blauer Käse',
	food1: 'Cheddar-Käse',
	food2: 'Hüttenkäse',
	food3: 'Frischkäse',
	food4: 'Feta Käse',
	food5: 'Gouda Käse',
	food6: 'Mozzarella Käse',
	food7: 'Provolone Käse',
	food8: 'Ricotta-Käse',
	food9: 'Schweizer Käse',
	food10: 'Kokosnuss Creme',
	food11: 'Kokosmilch (ungesüßt)',
	food12: 'Ei',
	food13: 'Schlagsahne',
	food14: 'Eis (ungesüßt)',
	food15: 'Milch',
	food16: 'Sojamilch (ungesüßt)',
	food17: 'Schlagsahne (ungesüßt)',
	food18: 'Joghurt / Kefir, Plain',
	food19: 'Blauer Käse',
	food20: 'Cremiger Cäsar',
	food21: 'Ranch',
	food22: 'Mandelöl',
	food23: 'Butter',
	food24: 'Kakaobutter',
	food25: 'Kokosnussöl',
	food26: 'Fischöl (Lebertran)',
	food27: 'Leinsamenöl',
	food28: 'Traubenkernöl',
	food29: 'Hanföl',
	food30: 'Mayonnaise',
	food31: 'Olivenöl',
	food32: 'Distelöl',
	food33: 'Sesamöl',
	food34: 'Sonnenblumenöl',
	food35: 'Pflanzenöl',
	food36: 'Flunder',
	food37: 'Hering',
	food38: 'Lachs',
	food39: 'Sardinen',
	food40: 'Sohle, einzig, alleinig',
	food41: 'Forelle',
	food42: 'Thunfisch',
	food43: 'Thunfisch (in Dosen)',
	food44: 'Mandelmehl',
	food45: 'Kakaopulver (ungesüßt)',
	food46: 'Leinsamen Mahlzeit',
	food47: 'Protein Pulver',
	food48: 'Psylliumschale',
	food49: 'Weizenmehl',
	food50: 'Hühnerbeine',
	food51: 'Hühnerflügel',
	food52: 'Ente',
	food53: 'Gans',
	food54: 'Wachtel',
	food55: 'Truthahnbrust',
	food56: 'Türkei, Boden',
	food57: 'Truthahnspeck',
	food58: 'Gerste',
	food59: 'Boxed verarbeitete Lebensmittel',
	food60: 'Brot',
	food61: 'Semmelbrösel',
	food62: 'Kuchen',
	food63: 'Suppen & Eintöpfe in Dosen',
	food64: 'Kaltes Getreide',
	food65: 'Kekse',
	food66: 'Maischips',
	food67: 'Körnerbrot',
	food68: 'Mahlzeit mit Getreide',
	food69: 'Cracker',
	food70: 'Grits',
	food71: 'Rösti',
	food72: 'Heißes Getreide',
	food73: 'Muffins, schlicht',
	food74: 'Pfannkuchen',
	food75: 'Pasta',
	food76: 'Kuchen',
	food77: 'Polenta',
	food78: 'Popcorn',
	food79: 'Kartoffelchips',
	food80: 'Brezeln',
	food81: 'Reis',
	food82: 'Roggen',
	food83: 'Dinkel',
	food84: 'Tamale Wrapper',
	food85: 'Tater Tots',
	food86: 'Teff',
	food87: 'Tortillas',
	food88: 'Tricale',
	food89: 'Waffeln',
	food90: 'Rippchen',
	food91: 'Rindfleischwurst',
	food92: 'Rindfleisch, Steak',
	food93: 'Lamm, Koteletts',
	food94: 'Peperoni, Schweinefleisch / Rindfleisch',
	food95: 'Schweinefleisch, Speck',
	food96: 'Schweinefleisch, Koteletts',
	food97: 'Schweinefleisch, Schinken',
	food98: 'Schweinswurst',
	food99: 'Kalbfleisch',
	food100: 'Wildbret, Steak',
	food101: 'Bohnen',
	food102: 'Cashewkerne',
	food103: 'Kokosnuss (Fleisch, ungesüßt)',
	food104: 'Haselnüsse',
	food105: 'Linsen',
	food106: 'Macadamias',
	food107: 'Erdnüsse',
	food108: 'Erdnussbutter',
	food109: 'Pecannüsse',
	food110: 'Pistazien',
	food111: 'Walnüsse',
	food112: 'Leinsamen',
	food113: 'Hanfsamen',
	food114: 'Kürbissamen',
	food115: 'Saflorsamen',
	food116: 'Sesamsamen',
	food117: 'Sonnenblumenkerne',
	food118: 'Krabbenfleisch',
	food119: 'Hummer',
	food120: 'Muscheln',
	food121: 'Austern',
	food122: 'Garnele',
	food123: 'Tintenfisch',
	food124: 'Klare Brühe oder Bouillon',
	food125: 'Kaffee',
	food126: 'Kristall Licht',
	food127: 'Diät-Limonaden (Coke Zero, Diet Coke)',
	food128: 'Diät Tonic Wasser',
	food129: 'Aromatisiertes Selterswasser (ungesüßt)',
	food130: 'Kräutertee (ungesüßt)',
	food131: 'Zitrone und Limettensaft (Limited)',
	food132: 'Seltzer Wasser',
	food133: 'Sprudel',
	food134: 'Mineralwasser',
	food135: 'Zuckerfreie Energiegetränke (Red Bull, Monster usw.)',
	food136: 'Sprudelwasser (Perrier)',
	food137: 'Tee (ungesüßt)',
	food138: 'Wasser',
	food139: 'Bier',
	food140: 'Brandy',
	food141: 'Cognac (Hennessy usw.)',
	food142: 'Gin (Tanqueray, Beefeater usw.)',
	food143: 'Leichte Biere (dh Coors, Bud Light)',
	food144: 'Rum (Captain Morgan usw.)',
	food145: 'Scotch',
	food146: 'Tequila',
	food147: 'Wodka (Absolut, Graugans usw.)',
	food148: 'Wein (trocken)',
	food149: 'Wein (Dessert)',
	food150: 'Whisky (Jack Daniels usw.)',
	food151: 'Brauner Zucker',
	food152: 'Süßigkeiten',
	food153: 'Rohrzucker',
	food154: 'Maissirup',
	food155: 'Traubenzucker',
	food156: 'Erythrit',
	food157: 'Fruktose',
	food158: 'Glucose',
	food159: 'Honig',
	food160: 'Laktose',
	food161: 'Maltose',
	food162: 'Ahornsirup',
	food163: 'Puderzucker',
	food164: 'Sorghum',
	food165: 'Stevia',
	food166: 'Saccharose',
	food167: 'Äpfel',
	food168: 'Aprikose',
	food169: 'Avocado',
	food170: 'Banane',
	food171: 'Brombeeren',
	food172: 'Blaubeeren',
	food173: 'Cantaloup-Melone',
	food174: 'Kirschen',
	food175: 'Preiselbeeren',
	food176: 'Termine',
	food177: 'Drachenfrucht',
	food178: 'Durians',
	food179: 'Feigen',
	food180: 'Trauben',
	food181: 'Grapefruit',
	food182: 'Honigtau',
	food183: 'Saft',
	food184: 'Kiwi',
	food185: 'Zitronen',
	food186: 'Zitronen',
	food187: 'Mango',
	food188: 'Nektarinen',
	food189: 'Orangen',
	food190: 'Papaya',
	food191: 'Passionsfrucht',
	food192: 'Pfirsich',
	food193: 'Birnen',
	food194: 'Ananas',
	food195: 'Granatapfel',
	food196: 'Pflaumen',
	food197: 'Pflaumen',
	food198: 'Rosinen',
	food199: 'Himbeeren',
	food200: 'Erdbeeren',
	food201: 'Mandarinen',
	food202: 'Wassermelone',
	food203: 'Alfalfa Sprossen',
	food204: 'Jedes grüne Blattgemüse',
	food205: 'Artischocken',
	food206: 'Spargel',
	food207: 'Bambussprossen',
	food208: 'Bohnensprossen',
	food209: 'Rübengrün',
	food210: 'Bok Choy',
	food211: 'Brokkoli',
	food212: 'Der Rosenkohl',
	food213: 'Kohl',
	food214: 'Blumenkohl',
	food215: 'Sellerie',
	food216: 'Sellerie',
	food217: 'Mangold',
	food218: 'Schnittlauch',
	food219: 'Collard Greens',
	food220: 'Mais',
	food221: 'Gurken',
	food222: 'Löwenzahngrün',
	food223: 'Dillgurken',
	food224: 'Knoblauch',
	food225: 'Grünkohl',
	food226: 'Lauch',
	food227:
		'Salat und Salat - Rucola, Bok Choy, Boston Salat, Chicorée, Endivie, Escarole, Fenchel, Mache, Radicchio, Romaine, Sauerampfer',
	food228: 'Limabohnen',
	food229: 'Pilze',
	food230: 'Okra',
	food231: 'Oliven',
	food232: 'Zwiebeln',
	food233: 'Erbsen',
	food234: 'Kartoffeln',
	food235: 'Radieschen',
	food236: 'Sauerkraut',
	food237: 'Frühlingszwiebeln',
	food238: 'Schalotten',
	food239: 'Spinat',
	food240: 'Süßkartoffeln',
	food241: 'Mangold',
	food242: 'Rüben',
	food243: 'Wasserkastanien'
};

export default DATA;
