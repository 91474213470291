//@flow
import Toast from 'react-native-root-toast';
import { WebCompatAlert } from 'Components/common';
import { toggleLoading } from 'Redux/actions';
import { i18n } from 'Theme';

//Searches database for a user with the email equal to the user input
export const sendFriendRequest = (userInput, cb) => (
	dispatch,
	getState,
	getFirebase
) => {
	const state = getState();
	const firebase = getFirebase();
	const { uid, email } = state.firebase.auth;
	const { friends = {} } = state.firebase;
	const formattedEmail = userInput.trim().toLowerCase();
	let error = false;
	if (email === formattedEmail) {
		error = true;
		return WebCompatAlert(i18n.t('invalidEmail'), i18n.t('cantFriendYourself'));
	}

	friends.sent &&
		Object.entries(friends.sent).forEach(([key, value]) => {
			if (value.email === formattedEmail) {
				error = true;
				return WebCompatAlert(
					i18n.t('alreadyInvited'),
					i18n.t('alreadyPendingInvite')
				);
			}
		});

	friends.accepted &&
		Object.entries(friends.accepted).forEach(([key, value]) => {
			if (value.email === formattedEmail) {
				error = true;
				return WebCompatAlert(
					i18n.t('alreadyFriended'),
					i18n.t('alreadyYourFriend')
				);
			}
		});

	friends.pending &&
		Object.entries(friends.pending).forEach(([key, value]) => {
			if (value.email === formattedEmail) {
				error = true;
				return WebCompatAlert(
					i18n.t('pendingInvite'),
					i18n.t('pendingAlready')
				);
			}
		});

	error === false && dispatch(toggleLoading(true));
	error === false &&
		firebase
			.auth()
			.currentUser.getIdToken()
			.then((token) => {
				return fetch(
					`https://us-central1-keto-diet-guide.cloudfunctions.net/friendsapi/sendfriendrequest?email=${formattedEmail}&uid=${uid}&myemail=${email}`,
					{
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				);
			})
			.then((responsej) => responsej.json())
			.then((response) => {
				//Sends a friend request to the inputted user. Adds recipient to your Sent Pending Friends list and you to their Received Pending list.
				dispatch(toggleLoading(false));
				cb();
				if (response === true) {
					return Toast.show(i18n.t('friendRequestSent'), {
						duration: Toast.durations.LONG,
						position: Toast.positions.BOTTOM,
						shadow: true,
						animation: true
					});
				} else {
					return Toast.show(i18n.t('userNotFound'), {
						duration: Toast.durations.LONG,
						position: Toast.positions.BOTTOM,
						shadow: true,
						animation: true
					});
				}
			})
			.catch((error) => {
				dispatch(toggleLoading(false));
				cb();
				return Toast.show(i18n.t('userNotFound'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			});
};

//Removes the recipient from your send requests, and removes you from their pending requests
export const unsendFriendRequest = (friendsID) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref('userdata/');
	let updates = {};
	updates[`${uid}/friends/sent/${friendsID}`] = null;
	dispatch(toggleLoading(true));
	firebase
		.auth()
		.currentUser.getIdToken()
		.then((token) => {
			return fetch(
				`https://us-central1-keto-diet-guide.cloudfunctions.net/friendsapi/unsendfriendrequest?uid=${uid}&fuid=${friendsID}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
		})
		.then((responsej) => responsej.json())
		.then((response) => {
			//Sends a friend request to the inputted user. Adds recipient to your Sent Pending Friends list and you to their Received Pending list.
			if (response === true) {
				ref.update(updates).then(() => dispatch(toggleLoading(false)));
				return Toast.show(i18n.t('friendRequestCanceled'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			}
		})
		.catch((error) => {
			dispatch(toggleLoading(false));
			return Toast.show(i18n.t('error'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

//Accepts a Friend Request that was sent to you. Adds both you and the sender to each others Accepted Friends list. Removes them from your Received Pending and you from their Sent Pending
export const acceptFriendRequest = (friend) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid, email } = state.firebase.auth;
	const ref = firebase.database().ref('userdata/');
	let updates = {};
	updates[`${uid}/friends/pending/${friend.id}`] = null;
	updates[`${uid}/friends/accepted/${friend.id}`] = {
		email: friend.email,
		id: friend.id
	};
	dispatch(toggleLoading(true));
	firebase
		.auth()
		.currentUser.getIdToken()
		.then((token) => {
			return fetch(
				`https://us-central1-keto-diet-guide.cloudfunctions.net/friendsapi/acceptfriendrequest?fuid=${friend.id}&uid=${uid}&email=${email}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
		})
		.then((responsej) => responsej.json())
		.then((response) => {
			//Sends a friend request to the inputted user. Adds recipient to your Sent Pending Friends list and you to their Received Pending list.
			if (response === true) {
				ref.update(updates).then(() => dispatch(toggleLoading(false)));
				return Toast.show(i18n.t('friendRequestAccepted'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			}
		})
		.catch((error) => {
			dispatch(toggleLoading(false));
			return Toast.show(i18n.t('userNotFound'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

//Declines a friend request that was sent to you. Removes you from there sent request, and removes them from your pending requests.
export const declineFriendRequest = (friend) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref('userdata/');
	let updates = {};
	updates[`${uid}/friends/pending/${friend.id}`] = null;
	dispatch(toggleLoading(true));
	firebase
		.auth()
		.currentUser.getIdToken()
		.then((token) => {
			return fetch(
				`https://us-central1-keto-diet-guide.cloudfunctions.net/friendsapi/declinefriendrequest?uid=${uid}&fuid=${friend.id}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
		})
		.then((responsej) => responsej.json())
		.then((response) => {
			dispatch(toggleLoading(false));

			//Sends a friend request to the inputted user. Adds recipient to your Sent Pending Friends list and you to their Received Pending list.
			if (response === true) {
				ref.update(updates);
				return Toast.show(i18n.t('friendRequestDeclined'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			}
		})
		.catch((error) => {
			dispatch(toggleLoading(false));
			return Toast.show(i18n.t('userNotFound'), {
				duration: Toast.durations.LONG,
				position: Toast.positions.BOTTOM,
				shadow: true,
				animation: true
			});
		});
};

//Removes a friend from your Accepted Friends list, also removes you from their Accepted Friends list
export const unFriend = (friend) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref('userdata/');
	let updates = {};
	updates[`${uid}/friends/accepted/${friend.id}`] = null;
	dispatch(toggleLoading(true));
	firebase
		.auth()
		.currentUser.getIdToken()
		.then((token) => {
			return fetch(
				`https://us-central1-keto-diet-guide.cloudfunctions.net/friendsapi/unfriend?uid=${uid}&fuid=${friend.id}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			)
				.then((responsej) => responsej.json())
				.then((response) => {
					dispatch(toggleLoading(false));
					//Sends a friend request to the inputted user. Adds recipient to your Sent Pending Friends list and you to their Received Pending list.
					if (response === true) {
						ref.update(updates);
						return Toast.show(i18n.t('unfriended'), {
							duration: Toast.durations.LONG,
							position: Toast.positions.BOTTOM,
							shadow: true,
							animation: true
						});
					} else {
						return Toast.show(i18n.t('userNotFound'), {
							duration: Toast.durations.LONG,
							position: Toast.positions.BOTTOM,
							shadow: true,
							animation: true
						});
					}
				})
				.catch((error) => {
					dispatch(toggleLoading(false));
					return Toast.show(i18n.t('userNotFound'), {
						duration: Toast.durations.LONG,
						position: Toast.positions.BOTTOM,
						shadow: true,
						animation: true
					});
				});
		});
};

/*
////////////////Single Friends Screen Functions ////////////////////
*/

//Copies a Single Item from Friends Foods to User's Food
export const copyFriendItem = (item, cb) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	//const foodID = randomString(14);
	let updates = {};
	if (item.type === 'food') {
		updates[`customfoodsv3/${item.id}`] = { ...item, favorite: false };
	} else if (item.type === 'meal') {
		updates[`savedmealsv3/${item.id}`] = { ...item, favorite: false };
	}

	ref.update(updates).then(() => {
		Toast.show(`${item.name} ${i18n.t('copied')}`, {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
		return cb();
	});
};

//Copies All Items from the Current View Tab (Food : Meal)
export const copyAllTab = (items, tabType, callBack) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let counter = items.length;
	dispatch(toggleLoading(true));
	let refSave;
	if (tabType === 'food') {
		refSave = 'customfoodsv3';
	} else if (tabType === 'meal') {
		refSave = 'savedmealsv3';
	}
	const finalCall = () => {
		dispatch(toggleLoading(false));
		Toast.show(i18n.t('allFoodsCopied'), {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
		callBack();
	};
	items.forEach((element) => {
		//const uniqid = randomString(14);
		let updates = {};
		updates[`${refSave}/${element.id}`] = { ...element, favorite: false };

		ref.update(updates).then(() => {
			counter -= 1;
			if (counter === 0) {
				return finalCall();
			}
		});
	});
};
