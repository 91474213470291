//@flow
import React from 'react';
import { ScrollView, Image, Linking } from 'react-native';
import { Paragraph, Heading, Divider, BlockQuote, Text } from '../common';
import styles from './Styles/Styles';
import { i18n, Images } from 'Theme';

export const SideEffects = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={styles.container}>
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh0')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp0')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh1')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp1')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh2')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp2')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh3')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp3')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh4')}
			</Heading>
			<Divider />
			<BlockQuote
				ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
				{i18n.t('sideeffectsbq1')}
			</BlockQuote>
			<Divider />
			<Paragraph>{i18n.t('sideeffectsp4')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh5')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp5')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh6')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp6')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh7')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp7')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh8')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp8')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh9')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp9')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh10')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp10')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh11')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp11')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh12')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp12')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh13')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp13')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh14')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp14')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh15')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp15')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh16')}
			</Heading>
			<Divider />
			<Image
				source={Images.electro}
				resizeMode="contain"
				style={styles.imageStyle}
			/>
			<Divider />
			<Paragraph>{i18n.t('sideeffectsp16')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('sideeffectsh17')}
			</Heading>
			<Paragraph>{i18n.t('sideeffectsp17')}</Paragraph>
			<Divider />
			<BlockQuote
				ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}
				style={{ paddingTop: 0, alignItems: 'center' }}>
				<Text
					style={styles.linkText}
					onPress={() =>
						Linking.openURL(
							'https://www.health.harvard.edu/blog/ketogenic-diet-is-the-ultimate-low-carb-diet-good-for-you-2017072712089'
						)
					}>
					{'\n'}Source
				</Text>
				<Text
					style={styles.linkText}
					onPress={() =>
						Linking.openURL('https://www.dietdoctor.com/low-carb/science')
					}>
					{'\n'}Source 2
				</Text>
			</BlockQuote>
		</ScrollView>
	);
};
