//@flow
import React from 'react';
import { ScrollView, Image, Linking } from 'react-native';
import { Paragraph, Heading, Divider, Text, BlockQuote } from '../common';
import styles from './Styles/Styles';
import { i18n, Images } from 'Theme';

export const Fiber = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={styles.container}>
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh0')}
			</Heading>
			<Paragraph>{i18n.t('fiberp0')}</Paragraph>
			<Divider />
			<Image
				source={Images.netCarb}
				resizeMode="contain"
				style={styles.imageStyle}
			/>
			<Divider />
			<Paragraph>{i18n.t('fiberp1')}</Paragraph>
			<Divider />
			<Image style={styles.imageStyle} source={Images.vegFiber} />
			<Divider />
			<Paragraph>{i18n.t('fiberp2')}</Paragraph>
			<Divider />
			<Paragraph>{i18n.t('fiberp3')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh1')}
			</Heading>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh2')}
			</Heading>
			<Paragraph>{i18n.t('fiberp4')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh3')}
			</Heading>
			<Paragraph>{i18n.t('fiberp5')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh4')}
			</Heading>
			<Paragraph>{i18n.t('fiberp6')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh5')}
			</Heading>
			<Paragraph>{i18n.t('fiberp7')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh6')}
			</Heading>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh7')}
			</Heading>
			<Paragraph>{i18n.t('fiberp8')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh8')}
			</Heading>
			<Paragraph>{i18n.t('fiberp9')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh9')}
			</Heading>
			<Paragraph>{i18n.t('fiberp10')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh10')}
			</Heading>
			<Paragraph>{i18n.t('fiberp11')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh11')}
			</Heading>
			<Paragraph>{i18n.t('fiberp12')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh12')}
			</Heading>
			<Paragraph>{i18n.t('fiberp13')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh13')}
			</Heading>
			<Paragraph>{i18n.t('fiberp14')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh14')}
			</Heading>
			<Paragraph>{i18n.t('fiberp15')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh15')}
			</Heading>
			<Paragraph>{i18n.t('fiberp16')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh16')}
			</Heading>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh17')}
			</Heading>
			<Paragraph>{i18n.t('fiberp17')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh18')}
			</Heading>
			<Paragraph>{i18n.t('fiberp18')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh19')}
			</Heading>
			<Paragraph>{i18n.t('fiberp19')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh20')}
			</Heading>
			<Paragraph>{i18n.t('fiberp20')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh21')}
			</Heading>
			<Paragraph>{i18n.t('fiberp21')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh22')}
			</Heading>
			<Paragraph>{i18n.t('fiberp22')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh23')}
			</Heading>
			<Paragraph>{i18n.t('fiberp23')}</Paragraph>
			<Divider />
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh24')}
			</Heading>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh25')}
			</Heading>
			<Paragraph>{i18n.t('fiberp24')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh26')}
			</Heading>
			<Paragraph>{i18n.t('fiberp25')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh27')}
			</Heading>
			<Paragraph>{i18n.t('fiberp26')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh28')}
			</Heading>
			<Paragraph>{i18n.t('fiberp27')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh29')}
			</Heading>
			<Paragraph>{i18n.t('fiberp28')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('fiberh30')}
			</Heading>
			<Paragraph>{i18n.t('fiberp29')}</Paragraph>
			<Divider />
			<BlockQuote
				ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}
				style={{ paddingTop: 0, alignItems: 'center' }}>
				<Text
					style={styles.linkText}
					onPress={() =>
						Linking.openURL(
							'https://www.hsph.harvard.edu/nutritionsource/carbohydrates/fiber/'
						)
					}>
					{'\n'}Source
				</Text>
			</BlockQuote>
		</ScrollView>
	);
};
