import { REQUEST_KETO_REDDIT_POSTS, RECEIVE_KETO_REDDIT_POSTS } from './types';

export const requestPosts = () => {
	return {
		type: REQUEST_KETO_REDDIT_POSTS
	};
};

export const receivePosts = (json) => {
	return {
		type: RECEIVE_KETO_REDDIT_POSTS,
		payload: json.data.children.map((child) => child.data)
	};
};

export const fetchPosts = () => {
	return (dispatch) => {
		dispatch(requestPosts());
		return fetch('http://www.reddit.com/r/ketorecipes.json')
			.then((response) => response.json())
			.then((json) => dispatch(receivePosts(json)));
	};
};
