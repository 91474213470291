//@flow
import React from 'react';
import { ScrollView } from 'react-native';
import { Paragraph, Heading, Divider } from '../common';
import styles from './Styles/Styles';
import { i18n } from 'Theme';

export const Tips = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={styles.container}>
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('tipsh1')}
			</Heading>
			<Paragraph>{i18n.t('tipsp1')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('tipsh2')}
			</Heading>
			<Paragraph>{i18n.t('tipsp2')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('tipsh3')}
			</Heading>
			<Paragraph>{i18n.t('tipsp3')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('tipsh4')}
			</Heading>
			<Paragraph>{i18n.t('tipsp4')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('tipsh5')}
			</Heading>
			<Paragraph>{i18n.t('tipsp5')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('tipsh6')}
			</Heading>
			<Paragraph>{i18n.t('tipsp6')}</Paragraph>
			<Divider />
			<Heading style={{ backgroundColor: theme.base }}>
				{i18n.t('tipsh7')}
			</Heading>
			<Paragraph>{i18n.t('tipsp7')}</Paragraph>
			<Divider />
		</ScrollView>
	);
};
