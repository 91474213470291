import React from 'react';
import {
	StyleSheet,
	View,
	Image,
	TouchableOpacity,
	Dimensions
} from 'react-native';
import { CardSection, Card, Text } from 'Components/common';
import { i18n } from 'Theme';
import { HeaderMacros } from './';
import { useNavigation } from '@react-navigation/native';
const window = Dimensions.get('window');

export const RecipeThread = (props) => {
	const navigation = useNavigation();
	const { recipe } = props.item;
	const { units } = props;

	return (
		<TouchableOpacity
			style={styles.container}
			activeOpacity={0.5}
			onPress={() => navigation.navigate('RecipesEdamamSingle', { recipe })}>
			<Card style={{ backgroundColor: props.theme.grey }}>
				{recipe.image && (
					<CardSection style={styles.paddingZero}>
						<Image
							resizeMode="cover"
							style={styles.imageStyle}
							source={{
								uri: recipe.image
							}}
						/>
					</CardSection>
				)}
				<View
					style={[
						styles.headerContainer,
						{ backgroundColor: props.theme.grey }
					]}>
					<View style={styles.titleContainer}>
						<Text style={styles.recipeTitle}>{recipe.label}</Text>
					</View>
					<View style={styles.metaContainer}>
						<Text style={styles.metaText}>
							{recipe.yield} {i18n.t('servings')} | {recipe.ingredients.length}{' '}
							{i18n.t('ingredients')}
						</Text>
					</View>
				</View>
				<HeaderMacros recipe={recipe} units={units} theme={props.theme} />
			</Card>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	container: { marginVertical: 10 },
	imageStyle: {
		width: window.width - 30,
		height: (238 / 375) * window.width,
		alignSelf: 'center'
	},
	paddingZero: {
		padding: 0
	},
	headerContainer: {
		paddingHorizontal: 15,
		paddingTop: 10,
		paddingBottom: 12,
		flexDirection: 'column'
	},
	recipeTitle: {
		fontSize: 16,
		fontWeight: 'bold'
	},
	metaContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	metaText: {
		flex: 1,
		fontSize: 12
	}
});
