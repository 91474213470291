//@flow
import foodListStringsEN from 'Components/foodlist/data/foodListStringsEN';

export const enStrings = {
	//Sidebar
	appName: 'MyKeto',
	log: 'Log',
	login: 'Login',
	learn: 'Learn',
	foodlist: 'Food List',
	recipes: 'Recipes',
	mealplan: 'Meal Plan',
	otherdiet: 'Other Diets',
	moreapps: 'More Apps',
	macrocalculator: 'Macro Calculator',
	purchase: 'Purchase',
	tracker: 'Tracker',
	progress: 'Progress',
	review: 'Review',
	manage: 'Manage',
	settings: 'Settings',
	preferences: 'Preferences',
	customgoals: 'Custom Goals',
	othertracking: 'Other Tracking',
	social: 'Social',
	fitnessTracker: 'Fitness Trackers',
	shoppingList: 'Shopping List',
	feedback: 'Feedback / Support',

	//Navigation Stuff
	addFood: 'Add Food',
	addIngredient: 'Add Ingredient',
	mealsRecipes: 'Meals / Recipes',
	editLogEntry: 'Edit Log Entry',
	editMeal: 'Edit Meal',
	editFood: 'Edit Food',
	deleteFood: 'Delete Food',
	deleteMeal: 'Delete Meal',
	friends: 'Friends',
	copyMeal: 'Copy Meal',
	mealCopied: 'Meal Copied',
	copyFood: 'Copy Food',
	copyItems: 'Copy Items',
	copyPlus: 'Copy +',
	copyAll: 'Copy All',
	copied: 'Copied',
	pending: 'Pending',
	manageFoods: 'Manage Foods',
	manageMeals: 'Manage Meals',

	//Generic
	macros: 'Macros',
	ingredients: 'Ingredients',
	directions: 'Directions',
	nutrition: 'Nutrition',
	nutrients: 'Nutrients',

	//Actions
	save: 'Save',
	cancel: 'Cancel',
	goback: 'Go Back',
	close: 'Close',
	delete: 'Delete',
	confirmDelete: 'Are you sure you want to delete?',
	saveas: 'Save As',
	add: 'Add',
	confirm: 'Confirm',
	addFavorite: 'Add Favorite',
	removeFavorite: 'Remove Favorite',
	remove: 'Remove',
	removed: 'Removed',
	added: 'Added',
	clear: 'Clear',
	send: 'Send',
	required: 'Required',
	macrosAddedToMeal: 'Macros Added To Meal',
	ingredientUpdated: 'Ingredient Updated',
	addToMeal: 'Add To Meal',
	saveMeal: 'Save Meal',
	addedToMeal: 'Added To Meal',
	mealSaved: 'Meal Saved',
	mealsAdded: 'Meals Added',
	addTo: 'Add To',
	addedToLog: 'Added To Log',
	saveAsFood: 'Save As Food',
	updateEntry: 'Update Entry',
	updateIngredient: 'Update Ingredient',
	otherTrackingUpdated: 'Other Tracking Updated',
	nameEmpty: 'Name Empty',
	pleaseEnterName: 'Please Enter A Name',
	selectDate: 'Select Date',
	date: 'Date',
	foodNotFound: 'Food Not Found',
	deleted: 'Deleted',
	deleteConfirm: 'Are you sure you want to delete?',
	addedToFavorites: 'added to Favorites',
	favoritesCleared: 'Favorites Cleared',
	removedFromFavorites: 'Removed from Favorites',
	savedToFoods: 'Saved To Foods',
	updated: 'Updated',
	update: 'Update',
	multiAdd: 'Multi-Add',

	//Rate Modal
	noThanks: 'No Thanks',
	giveFeedback: 'Give Feedback',
	rateAppName: 'Rate MyKeto',
	never: 'Never',
	later: 'Later',
	rate: 'Rate',
	rateDesc:
		"If you enjoy using MyKeto, would you mind taking a second to rate it? It won't take more than a minute. Thank you for your support!",
	rateFeed:
		'Would you mind providing us feedback on what you would like to see in our app? Thank you!',

	//Learn
	basics: 'Basics',
	benefits: 'Benefits',
	sideeffects: 'Side Effects',
	myths: 'Myths',
	faq: 'FAQ',
	faq1: 'Frequently Asked Questions',
	getstarted: 'Get Started',
	fiber: 'Fiber',
	tips: 'Tips',
	products: 'Recommended Products',

	basicsh1: 'What is the Keto Diet?',
	basicsh2: 'How does it work?',
	basicsh3: 'Why Keto?',
	basicsp1:
		'The ketogenic or keto diet is very similar to a low-carbohydrate diet such as the Atkins. The main difference between a regular low carb diet plan and a ketogenic diet plan is the amount of carbohydrate and protein allowed on a daily basis.\n\nIn general, low-carb diets lower carbohydrate intake to below 100 grams; strict ketogenic diets are a subgroup of low carb diets that typically only allow up to 50g of carbohydrates per day.',
	basicsp2:
		'Furthermore, the ketogenic diet is a high-fat, adequate-protein, low-carbohydrate diet. You are swapping out the carbs in the diet with a higher portion of fat and a moderate protein intake.',
	basicsp3:
		'This high fat diet puts your body into a state called "Ketosis", where the body produces ketones in the liver to be used as energy.\n\nNormally carbohydrates are broken down into Glucose, which your body carries in the blood to give your cells energy. Oppositely, ketosis makes ketones from the high amounts of fat being consumed, and the presence of large carbs in the diet will actually stop ketosis.',
	basicsp4:
		'Compared to the western diet which consists largely of highly-refined carbohydrates, keto can offer a lot of health advantages. Most people start a ketogenic diet because they want to lose weight. However, scientific research suggests that it goes further than that, such as lowering your risk for heart disease, diabetes, cancer, stroke, and more.\n\nThere is a lot more to a keto diet than just removing carbohydrates, it is a lifestyle about overall health. It promotes long, intense bouts of energy, an increase in healthy, delicious food and an overall better outlook on your life. It is easily maintainable with an excess of options and often is one answer to improving well-being that many people struggle to grasp at first.\n\nKeto is difficult and will test your willpower, but it changes the way you see and think about yourself, food, and health in general.',
	basicsbq:
		'The nutrient intake on a ketogenic diet typically works out to about 70-75% of calories from fat, 20-25% from protein, and 5-10% from carbohydrate on a daily basis.',
	benefitsh0: 'Advantages',
	benefitsp0:
		'Ketogenic diets have a long list of benefits and can be seen as fast as one week, while developing gradually over a period of 3 weeks.',
	benefitsh1: '1. Weight Loss',
	benefitsp1:
		'When the body is burning fat as the main source of energy, it will essentially be taking it from your fat stores since you are in a fasting state. Studies show that people on low-carb diets lose more weight and faster, than people on low-fat diets. This is true even when the low-fat dieters are actively restricting calories.',
	benefitsbq1:
		'Severely obese subjects with a high prevalence of diabetes or the metabolic syndrome lost more weight during six months on a carbohydrate-restricted diet than on a calorie- and fat-restricted diet, with a relative improvement in insulin sensitivity and triglyceride levels, even after adjustment for the amount of weight lost.',
	benefitsbq2:
		'A low carb diet appears to be an effective method for short-term weight loss in overweight adolescents and does not harm the lipid profile.',
	benefitsbq3:
		'A one-year, multicenter, controlled trial involving 63 obese men and women who were randomly assigned to either a low-carbohydrate, high-protein, high-fat diet or a low-calorie, high-carbohydrate, low-fat (conventional) diet. The low-carbohydrate diet produced a greater weight loss (absolute difference, approximately 4 percent) than did the conventional diet for the first six months, but the differences were not significant at one year.',
	benefitsbq4:
		'A large percentage of the fat lost on low-carb diets tends to come from the harmful fat in the abdominal cavity that is known to cause serious metabolic problems.',
	benefitsbq5:
		'A recent study from Johns Hopkins University School of Medicine confirms that a higher fat, low carb diet is not detrimental to vascular health AND results in faster weight loss. The authors confirmed that the dieters in the low carb group dropped more weight over a shorter period of time than the higher carb group, and the low carb group had no harmful changes in vascular health.',
	benefitsh2: '2. Lower blood pressure',
	benefitsp2:
		'Low carb diets are very effective at reducing blood pressure. Talk with your doctor if you are on blood pressure medication before starting.',
	benefitsbq6:
		'In a sample of medical outpatients, a Low Carb Keto Diet led to similar improvements as Orlistat drug therapy combined with a low-fat diet for weight, serum lipid, and glycemic parameters and was more effective for lowering blood pressure.',
	benefitsh3: '3. Improved Cholesterol Profile',
	benefitsp3:
		'Shown to improve triglyceride levels and cholesterol levels most associated with arterial buildup.',
	benefitsbq7:
		'Ketogenic diets increase the concentrations of heart-healthy HDL cholesterol more than low-fat, high-carb diets.',
	benefitsbq8:
		'Lowering carbohydrate consumption in healthy individuals also leads to higher levels of HDL cholesterol.',
	benefitsbq9:
		'Low-carb, high-fat diets decrease LDL particle concentration (LDL-P) and increase the size of LDL cholesterol.',
	benefitsbq10:
		'Ketogenic diets decrease the amount of harmful VLDL cholesterol in the blood.',
	benefitsh4: '4. Stabilized Blood Sugar and Diabetes Treatment',
	benefitsp4:
		'The best way to lower blood sugar and insulin levels is to reduce carbohydrate consumption. This is also a very effective way to treat and possibly even reverse type II diabetes.',
	benefitsbq11:
		'The LCKD improved glycemic control in patients with type 2 diabetes such that diabetes medications were discontinued or reduced in most participants. Because the LCKD can be very effective at lowering blood glucose, patients on diabetes medication who use this diet should be under close medical supervision or capable of adjusting their medication.',
	benefitsbq12:
		'High saturated fat very low carbohydrate (VLCARB) results in similar fat loss than diets low in saturated fat, but are more effective in improving triacylglycerols, HDL-C, fasting and post prandial glucose and insulin concentrations. VLCARB may be useful in the short-term management of subjects with insulin resistance and hypertriacylglycerolemia.',
	benefitsbq13:
		'Dietary modification led to improvements in glycemic control and medication reduction/elimination in motivated volunteers with type 2 diabetes. The diet lower in carbohydrate led to greater improvements in glycemic control, and more frequent medication reduction/elimination than the low glycemic index diet. Lifestyle modification using low carbohydrate interventions is effective for improving and reversing type 2 diabetes.',
	benefitsh5: '5. Lack of hunger',
	benefitsp5:
		'Ketone bodies dampen the appetite, plus fat is naturally more satisfying and ends up leaving us in a satiated, full state for longer.',
	benefitsbq14:
		'Symptoms of negative affect and hunger improved to a greater degree in patients following an Keto Diet compared with those following an Low Fat Diet.',
	benefitsh6: '6. Anti-Cancer',
	benefitsp6:
		'Keto may help in fighting off certain types of cancer and various tumors.',
	benefitsbq15:
		'Although the mechanism by which ketogenic diets demonstrate anticancer effects when combined with standard radio-chemo-therapies has not been fully elucidated, preclinical results have demonstrated the safety and potential efficacy of using ketogenic diets in combination with radio-chemo-therapy to improve responses in murine cancer models. These preclinical studies have provided the impetus for extending the use of ketogenic diets into phase I clinical trials that are currently ongoing.',
	benefitsh7: '7. Better Skin',
	benefitsp7:
		'Researchers say foods that spike blood sugar can also increase hormones. The hormones can stimulate oil production, which in turn, can trigger acne. The Keto diet prevents blood sugar spiking by using Fat as the main source of energy.',
	benefitsbq16:
		'In a paper published in the Journal of the Academy of Nutrition and Dietetics, Burris and her colleagues review 27 studies on nutrition and acne. Some of the more convincing evidence comes from a few recent studies of teenagers and young men (aged 15-25 years) who had acne. After the participants followed a low-glycemic-load diet, researchers documented decreases in inflammatory acne lesions. The studies were small, but the findings were significant.',
	benefitsh8: '8. Improved Dental Health',
	benefitsp8:
		'Sugar (Carbohydrate) is the main contributor to dental disease and decay.',
	benefitsbq17:
		'Food residues, specifically carbohydrates, have caries promoting characteristics that allow fermentation and acid accumulation to occur in bacterial plaque. It enhances the four previously mentioned activities of biofilm: implantation, colonization, metabolic activity and thickness. When carbs are restricted in a diet, it limits an essential factor for the development of caries.',
	benefitsh9: '9. Mood stabilization',
	benefitsp9:
		'Ketone bodies have been shown to be beneficial in stabilizing neurotransmitters such as serotonin and dopamine which result in better mood control.',
	benefitsbq18:
		'The ketogenic diet, originally introduced in the 1920s, has been undergoing a recent resurgence as an adjunctive treatment for refractory epilepsy, particularly in children. In this difficult-to-treat population, the diet exhibits remarkable efficacy with two-thirds showing significant reduction in seizure frequency and one-third becoming nearly seizure-free. There are several reasons to suspect that the ketogenic diet may also have utility as a mood stabilizer in bipolar illness. These include the observation that several anticonvulsant interventions may improve outcome in mood disorders. Furthermore, beneficial changes in brain-energy profile are noted in subjects on the ketogenic diet. This is important since global cerebral hypometabolism is a characteristic of the brains of depressed or manic individuals. Finally, the extracellular changes that occur in ketosis would be expected to decrease intracellular sodium concentrations, a common property of all effective mood stabilizers. Trials of the ketogenic diet in relapse prevention of bipolar mood episodes are warranted.',
	benefitsh10: '10. Possible Increased Energy',
	benefitsp10:
		'Fats are shown to be the most effective molecule to burn as fuel.',
	benefitsbq19:
		'During metabolic stress, ketones serve as an alternative energy source to maintain normal brain cell metabolism. In fact, BHB (a major ketone) may be an even more efficient fuel than glucose, providing more energy per unit oxygen used. A ketogenic diet also increases the number of mitochondria, so called “energy factories” in brain cells. A recent study found enhanced expression of genes encoding for mitochondrial enzymes and energy metabolism in the hippocampus, a part of the brain important for learning and memory. Hippocampal cells often degenerate in age-related brain diseases, leading to cognitive dysfunction and memory loss. With increased energy reserve, neurons may be able to ward off disease stressors that would usually exhaust and kill the cell.',

	sideeffectsh0: 'Dangers',
	sideeffectsp0:
		'As a precaution, you should always check with your general practitioner before you start a ketogenic diet. You should especially be weary if you or anyone in your family has any pre-existing kidney, heart, or diabetic conditions.',
	sideeffectsh1: 'Osteoporosis and Kidney Stones',
	sideeffectsp1:
		"High-protein diets have also been shown to cause people to excrete a large amount of calcium in their urine. Over a prolonged period of time, this can increase a person's risk of osteoporosis and kidney stones. A diet that increases protein at the expense of a very restrictive intake of plant carbohydrates may be bad for bones, but not necessarily a high-protein intake alone.",
	sideeffectsh2: 'Kidney Failure',
	sideeffectsp2:
		'Consuming too much protein puts a strain on the kidneys, which can make a person susceptible to kidney disease.',
	sideeffectsh3: 'Thyroid, Heart, Blood Pressure',
	sideeffectsp3:
		"There is limited research-based-evidence that may suggest ketosis can cause a short-lived increase in the body's levels of adrenaline and cortisol for a small subgroup of people. In turn, these increased levels of hormones can cause thyroid issues, heart irregularities and elevated blood pressure for some.",
	sideeffectsh4: 'Side-Effects',
	sideeffectsp4:
		"As your body becomes induced into a ketogenic state, your body will naturally use what's left of your glucose. Thus, the body will be exhausted of all glucose storage in the muscles, which may cause a lack of energy, and a general lethargy. During the first couple weeks, people report:",
	sideeffectsbq1:
		'"Over the first few days to few weeks of low-carb adaptation, your body is laying off the carbohydrate worker enzymes and building new fat worker enzymes. Once the workforce in your body is changed out, you start functioning properly on your new low-carb, higher-fat diet. The carbs you used to burn for energy are now replaced to a great extent by ketones..." - Mike Eades M.D.',
	sideeffectsh5: 'Keto Flu',
	sideeffectsp5: 'Flu-like symptoms',
	sideeffectsh6: 'Headaches',
	sideeffectsp6:
		'Ketosis can cause headaches for some people and you might feel a little lightheaded.',
	sideeffectsh7: 'Fatigue and Dizziness',
	sideeffectsp7:
		"As you start dumping water, you'll lose minerals such as salt, potassium and magnesium. Having lower levels of these minerals will make you very, very tired, lightheaded or dizzy, give you muscle cramps, and headaches. This is one of the most common of the low carb diet side effects, and it can be avoided for the most part by making sure you get enough replacement minerals.",
	sideeffectsh8: 'Hypoglycemia Episodes',
	sideeffectsp8:
		'The body is used to releasing a certain amount of insulin to take care of the glucose which gets created from all the carbohydrates you eat. When you tank your carb intake, there initially may be occasional bouts of low blood sugar',
	sideeffectsh9: 'Mental fogginess',
	sideeffectsp9:
		'It is not uncommon to have a lack of mental clarity at the start. In most cases, brain fog will only last a few days.',
	sideeffectsh10: 'Frequent Urination',
	sideeffectsp10:
		"After beginning, you'll notice that you are going to the bathroom more often. The body is burning up the left over glucose (from storage form: glycogen) in your liver and muscles. Breaking down glycogen releases a lot of water and your kidneys will start dumping it. Additionally, as your circulating insulin levels drop, the kidneys start to excrete surplus sodium, which will also cause more frequent urination.",
	sideeffectsh11: 'Constipation',
	sideeffectsp11:
		'This is one of the more common side effects, and is usually a result of dehydration, salt loss and a lack of magnesium. Make sure to stay hydrated and drink lots of water throughout the day. Also remember to eat enough fiber from vegetables and you can even take a teaspoon or two of psyllium husk fiber daily.',
	sideeffectsh12: 'Carb Cravings',
	sideeffectsp12:
		'The body is still adapting to life without carbohydrates like sugar. Intense cravings for starchy foods like bread will be present for a week or so.',
	sideeffectsh13: 'Diarrhea',
	sideeffectsp13:
		'This usually happens to people who unwisely decide to also limit their fat intake on a low carb diet, which results in eating too much protein.',
	sideeffectsh14: 'Muscle Cramps',
	sideeffectsp14:
		'Mineral loss, specifically magnesium, is the most likely contributor to cramps.',
	sideeffectsh15: 'Gym & Exercise Levels',
	sideeffectsp15:
		"At the start, it's normal to lose some strength and endurance. Don't worry, as soon as your body becomes adapted to ketosis, you will be able to completely utilize the fats as your primary source of energy. Numerous studies show that there is no long term drop in performance for athletes.",
	sideeffectsh16: 'Electrolytes',
	sideeffectsp16:
		'Since ketosis has a diuretic effect on the body, most of the time the side-effects are the result of electrolytes being flushed out. Ensure you drink plenty of water and keep your salt/sodium intake up. You should be going salt crazy with all of your foods. This aids with water retention, and helps replenish electrolytes.',
	sideeffectsh17: 'Conclusion',
	sideeffectsp17:
		'These ketogenic side effects are really just minor issues, and occur only over a period of the first few weeks. They will get better and finally subside, and then you will experience the full benefits of the keto diet.',
	mythsh0: 'The Fattest Myth',
	mythsp0:
		"The majority of people who give up on the keto diet fail because they don't feel comfortable massively increasing their fat intake, especially saturated fat.",
	mythsh1: 'High Carbs + High Fat = Bad',
	mythsp1:
		'High carb diets ramp up blood sugar and insulin levels. High levels of sugar and insulin are correlated with high inflammation. Most American diets are high in both sugar & high in saturated fat. When studied, these two factors were combined together and not studied separately.\n\nSaturated fat received the blame for the inflammation that causes heart disease because it was studied in combination with a high carb diet. Ketogenic diets, which are high in saturated fat and very low in carbohydrates, will minimize inflammation.',
	mythsbq1:
		'A John Hopkins study confirmed that saturated fat is not harmful in the context of a low carb diet and the cause of heart disease is chronically high carbohydrate consumption, not a high saturated fat and cholesterol intake. In other words, fat makes us fat when its paired with high levels of carbohydrates.',
	mythsh2: 'What about protein?',
	mythsp2:
		'It is vital to ketosis that your protein levels do not exceed that of fat in calories, not weight. This is because protein in excessive levels will act very similarly to carbohydrates. They are eventually broken down into glucose, which then spikes your blood sugar and causes an insulin surge.',
	mythsh3: '"Ketosis is dangerous and harmful"',
	mythsp3:
		"Ketosis, which is a beneficial metabolic state, is often confused with ketoacidosis, which is something completely different and a serious medical condition. Ketoacidosis is caused by the body's failure to properly regulate the production of ketones. Its most commonly suffered by alcoholics and type 1 diabetics.",
	mythsh4: '"It\'s not maintainable"',
	mythsp4:
		"Sticking to the diet can be easier than you think. If you eat out a lot, especially fast food, there will be the temptation of convenience and you may have some difficulties. The same is true if you keep a lot of starchy and sugary food in your pantry. However, if you stay away from and don't expose yourself to the these foods, you'll hardly notice. The cravings will subside after the first week or two, if not sooner",
	mythsh5: '"You can\'t get all your vitamins"',
	mythsp5:
		"One will get far more of most vitamins and minerals on a proper ketogenic diet than on a typical diet. The main exception is vitamin C, which is only required in more than trace amounts if you're on a high-carb diet. Eating leafy greens will be more than enough Vitamin C though.",
	mythsh6: '"Keto will give you kidney damage"',
	mythsp6:
		"People with very advanced kidney disease have trouble handling large amounts of dietary protein. However there is no research anywhere that a high-protein diet causes kidney disease. There may be an increased risk of kidney stones due to increased levels of uric acid in the blood, but that's easily avoided by drinking plenty of fluids, and if you want to be extra careful, taking potassium citrate supplements.",
	mythsh7: '"I don\'t want to starve myself"',
	mythsp7:
		'Fat and Protein are digested much more slowly than Carbs, so you will notice that you go longer periods of time without being hungry.',
	mythsh8: '"Keto gives you bad breath"',
	mythsp8:
		"When you're in ketosis, it's certainly detectable in your breath. What you're smelling is ketones, fruity-smelling molecules converted from fat for use as fuel by the cells. If you're the one in ketosis, it's actually quite satisfying to know that you're actually exhaling calories! Just chew some sugar free gum if it bothers you.",
	getstartedh0: 'How To Get Started',
	getstartedp0:
		"Overall, eating a high amount of fat, moderate protein, and limited amount of carbohydrates will have a massive impact on your health – lowering your cholesterol, body weight, blood sugar, and raising your energy and mood levels. \n\nOnce you're in the mindset to start a more realistic, healthy approach at losing body fat – a low carb lifestyle doesn't seem as bad. Give it a few weeks to get over the initial hump, and those pesky side-effects will dwindle away. \n\nOne important point on a keto diet: be prepared to spend more time in the kitchen. A keto menu involves cooking and eating real foods. If you don't know how to cook already, this will be a good learning experience for you. \n\nYou should use the calculator section of this App to calculate your specific macros mentioned below",
	getstartedh1:
		'1. Restock your kitchen so that there are no temptations for you.',
	getstartedh2:
		'2. Reduce daily carbohydrate consumption to around 20g, however anything under 50g is acceptable.',
	getstartedh3: '3. Largely increase the proportion of fat in your diet.',
	getstartedh4:
		'4. Bring protein intake to 0.6 to 1g per pound of lean body mass. (1.3 to 2.2g per kg).',
	getstartedh5: '5. Largely increase the amount of water you consume.',
	getstartedh6:
		'6. Up the intake of electrolytes such as salt, potassium and magnesium.',
	fiberh0: 'Fiber - Get Some!',
	fiberh1: 'Keto Sources of Fiber Include:',
	fiberh2: 'Flaxseed',
	fiberh3: 'Chia Seeds',
	fiberh4: 'Vegetables that are close to all fiber',
	fiberh5: 'Fiber Supplements',
	fiberh6: 'More Fiber Than Usable Carbohydrate:',
	fiberh7: 'Wheat Bran',
	fiberh8: 'Unsweetened Coconut and Coconut Flour',
	fiberh9: 'High Fiber Cereals',
	fiberh10: 'Collard Greens',
	fiberh11: 'Avocado, Hass',
	fiberh12: 'Spinach and Chard',
	fiberh13: 'Broccoli',
	fiberh14: 'Cauliflower',
	fiberh15: 'Blackberries',
	fiberh16: 'About as Much Usable Carb as Fiber:',
	fiberh17: 'Asparagus',
	fiberh18: 'Celery',
	fiberh19: 'Eggplant',
	fiberh20: 'Lettuce, Romaine',
	fiberh21: 'Mushrooms',
	fiberh22: 'Radishes',
	fiberh23: 'Red Raspberries',
	fiberh24: 'High Fiber, but Not As Much Fiber as Usable Carb:',
	fiberh25: 'Rice Bran',
	fiberh26: 'Cabbage',
	fiberh27: 'Bell Peppers',
	fiberh28: 'Snow Peas (edible pod)',
	fiberh29: 'Zucchini Squash',
	fiberh30: 'Strawberries',
	fiberp0:
		'Fiber is a form of sugar called cellulose. We do not have the necessary enzymes to break down cellulose, so it does not activate glycolysis and will not effect insulin levels. High-fiber foods are safe to eat on keto, and should be consumed throughout the day. It is important to note that fiber DOES NOT count towards your carbohydrate intake.\n\nFor example, if your meal has 10g Carbs total and 5g of it is fiber, then the actual carb intake for the meal is 5g (10g -5g). Although fiber is a carbohydrate, it is not digested as a simple carbohydrate and is therefore not included in your daily carb count.',
	fiberp1:
		"It's important to stress that fiber doesn't NEGATE carbs - it just isn't counted; so mixing a handful of flax meal into a bowl of ice-cream won't work!",
	fiberp2:
		'Most people get their fiber from grains and eliminating this food group to adopt your ketogenic diet can result in a considerable decrease in your daily fiber intake.',
	fiberp3:
		'The 2010 Dietary Guidelines for Americans recommend that you get between 25g and 38g of fiber a day,&nbsp;but someone on a keto diet may need more.',
	fiberp4:
		'There is almost no usable carbohydrate in flax seeds. It is very high in both soluble and insoluble fiber (about one third of the fiber is soluble), and has a pile of nutrients to boot. Flax is one of the best sources of fiber to implement in your diet.',
	fiberp5: 'Have a fiber and carb profile similar to flax seeds.',
	fiberp6: 'Mustard Greens, Chicory, Endive',
	fiberp7: 'Such as psyllium husk powder',
	fiberp8: '½ cup raw, 3 grams usable carb, 6 grams fiber',
	fiberp9: '1 ounce, 2 grams usable carb, 5 grams fiber',
	fiberp10:
		'Check the labels carefully, but a few high fiber cereals are also low or fairly low in carbohydrate. Examples: All Bran with Extra Fiber; Fiber One',
	fiberp11: '1 cup chopped, cooked, 4 grams usable carb, 5 grams fiber',
	fiberp12: '1 medium avocado, 3 grams usable carb, 12 grams fiber',
	fiberp13:
		'Frozen 1 10 oz package, 10g total carb, 6g fiber. 6 cups of raw spinach or chard = about 1 cup cooked',
	fiberp14:
		'1/2 cup chopped, cooked, 1 gram usable carb, 3 grams fiber. 1 cup chopped, raw, 4 grams usable carb, 2 grams fiber',
	fiberp15:
		'1/2 cup pieces, cooked, 1 gram usable carb, 2 grams fiber. 1 cup raw, 2 grams usable carb, 2.5 grams fiber',
	fiberp16: '1 cup, raw, 6 grams usable carb, 8 grams fiber',
	fiberp17: '1/2 cup pieces, 2 grams usable carbs, 2 grams fiber',
	fiberp18: '1 cup chopped, 1.5 grams usable carb, 1.5 grams fiber',
	fiberp19:
		'1 cup raw, cubed, 2 grams usable fiber, 3 grams fiber. 1 cup cubed, cooked, 5 grams usable carb, 3 grams fiber',
	fiberp20: '1 cup shredded, .5 gram usable carb, 1 g fiber',
	fiberp21: '1 cup, sliced, raw, 1 gram usable carb, 1 gram fiber',
	fiberp22: '1 cup raw, sliced, 2 grams usable carb, 2 grams fiber',
	fiberp23: '1 cup, raw, 7 grams usable carb, 8 grams fiber',
	fiberp24: '1/4 cup 8 grams usable carb, 6 grams fiber',
	fiberp25:
		'1 cup raw, chopped, 3 grams usable carb, 2 grams fiber. 1/2 cup cooked, chopped, 2 grams usable carb 1 gram fiber',
	fiberp26: '1 cup chopped, raw, 4 grams usable carb, 3 grams fiber',
	fiberp27: '1 cup whole, raw, 3 grams usable carb, 2 grams fiber',
	fiberp28: '1 cup cooked, sliced, 4 grams usable carb, 3 grams fiber',
	fiberp29: '1/2 cup sliced, 5 grams usable carb, 2 grams fiber',
	tipsh1: 'Go on a carbohydrate sweep.',
	tipsp1:
		'Inspect kitchen cupboards and refrigerator, and remove all of the high carb foods. This will avoid temptations later on down the road.',
	tipsh2:
		'Get a health checkup and a blood panel test done before starting the diet.',
	tipsp2:
		'Plan to get another four to eight weeks after starting. This way you can track the effect of the change in your eating habits on your cholesterol, blood pressure, and other health markers.',
	tipsh3: 'Buy Ketostix.',
	tipsp3:
		'To check if you are in or out of ketosis. Ketostix are designed to measure how much ketones are in your urine. Recently, a blood ketones meter has become available to track ketosis at home. This is a much more accurate way to track your individual ketone levels.',
	tipsh4: 'Think about your meals, and how you will plan them.',
	tipsp4:
		"This will help in buying the right foods at the grocery store, and give a framework to follow when meal time arrives. If you know you're supposed to have salmon and broccoli for dinner tonight, it makes it a lot easier to avoid choosing the old high carb foods you used to eat.",
	tipsh5: 'Stay hydrated.',
	tipsp5:
		'As carb intake is lowered, the kidneys will start dumping the excess water the body has been holding due to a high carb intake. Make sure to drink enough water to replace what gets lost.',
	tipsh6: 'Think about any social situations that will be encountered.',
	tipsp6:
		'Devise ways to handle those times when temptations to eat the "old" way will be high. You don\'t want to be blind sided when someone at the office brings in a box of your favorite chocolates and puts them right under your nose. Likewise, a beer with your friends usually turns into a date with potato skins and nachos. Think salad and steak instead.',
	tipsh7: "Don't focus on your weight.",
	tipsp7:
		"Don't weigh yourself every day. Your weight can vary between 2-4 pounds each day because of changes in water intake and absorption. You won't be able to track any fat loss accurately on a daily basis, and the fluctuations will make you crazy if you focus on them. Instead, weigh yourself once a week, or keep track of your body measurements to track your progress.",

	//Recipes
	makes: 'Makes',
	servings: 'Servings',
	fat: 'Fat',
	carbs: 'Carbs',
	totalcarb: 'Total Carbs',
	netcarb: 'Net Carbs',
	protein: 'Protein',
	calories: 'Calories',
	zeroResults: '0 results',
	loadMore: 'Load More',
	likes: 'Likes',
	comments: 'Comments',
	dishType: 'Dish Type',
	mealType: 'Meal Time',
	cuisineType: 'Cuisine Type',
	american: 'American',
	asian: 'Asian',
	british: 'British',
	caribbean: 'Caribbean',
	centralEurope: 'Central Europe',
	chinese: 'Chinese',
	easternEurope: 'Eastern Europe',
	french: 'French',
	indian: 'Indian',
	italian: 'Italian',
	japanese: 'Japanese',
	kosher: 'Kosher',
	mediterranean: 'Mediterranean',
	mexican: 'Mexican',
	middleEastern: 'Middle Eastern',
	nordic: 'Nordic',
	southAmerican: 'South American',
	southEastAsian: 'South East Asian',

	//Food List & Fat Seret
	netcarbFinder: 'Net Carb Finder',
	search: 'Search',
	searchFilter: 'Search Filter',
	barcode: 'Barcode',
	offline: 'Offline',
	results: 'Results',
	barcodeNotRecognized: 'Barcode Not Recognized',
	barcodeNotRecognizedDetail:
		'Try searching the database or adding it as a custom food.',
	clearAllRecentlyScanned: 'Clear All Recently Scanned?',
	clearAllConfirm:
		'This will clear your recently scanned barcodes on all synced devices',
	requestCamera: 'Requesting for camera permission',
	noAccessCamera: 'No access to camera',
	scanBarcode: 'Scan Barcode',
	itemFound: 'Item Found',
	itemNotFoundFS: 'Item Not Found In Fat Secret',
	ItemNotFoundFSdesc:
		'Try searching the database or adding it as a custom food.',
	generic: 'Generic',
	basedDiet: '% based on a 2000 calorie diet',
	all: 'All',
	alcohol: 'Alcohol',
	beverages: 'Beverages',
	cheeses: 'Cheeses',
	dairy: 'Dairy',
	dressings: 'Dressings (Check Labels)',
	fatsoils: 'Fats and Oils',
	fish: 'Fish',
	flours: 'Flours or Meals',
	fowl: 'Fowl',
	fruits: 'Fruits',
	grainsstarch: 'Grains & Starch',
	meats: 'Meats',
	nutslegumes: 'Nuts and Legumes',
	seeds: 'Seeds',
	shellfish: 'Shellfish',
	sugarssweetener: 'Sugars & Sweetener',
	vegetables: 'Vegetables',
	...foodListStringsEN,

	//Macros Calculator
	calculatorDetails:
		'We need a few details to form your personalized keto plan',
	height: 'Height',
	weight: 'Weight',
	age: 'Age',
	gender: 'Gender',
	male: 'Male',
	female: 'Female',
	exerciseLevel: 'Exercise Level',
	littleToNone: 'Little to None',
	exerciseOne: '1-3 times / week',
	exerciseTwo: '3-5 times / week',
	exerciseThree: '6-7 times / week',
	exerciseFour: '2 times / day',
	goalWeight: 'Goal Weight',
	faster: 'Faster',
	slower: 'Slower',
	weightPlan: 'Your Weight Plan',
	maintain: 'Maintain',
	easier: 'Easier',
	mild: 'Mild',
	moderate: 'Moderate',
	difficult: 'Difficult',
	customize: 'Customize',
	dailyCalories: 'Daily Calories',
	week: 'Week',
	month: 'Month',
	lose: 'Lose',
	by: 'by',
	bodyFat: 'Body Fat',
	bodyFatDetails:
		'Your body fat % will be used to calculate your ideal protein amount based off your total muscle mass.',
	proteinDetail:
		'Too much protein can break ketosis, so you need just enough to maintain muscle and a bit more to build it.',
	dailyProtein: 'Daily Protein',
	idealRange: 'Your ideal range is',
	maintainLower: 'maintain',
	build: 'build',
	maintainMuscle: 'Maintain Muscle',
	buildMuscle: 'Build Muscle',
	netcarbDescription:
		'Eating over 50g of net carbs a day is enough to break ketosis. Most keto dieters eat around 20g-30g. The fewer carbs you eat the less chance you have of breaking ketosis.',

	//Settings and Language
	language: 'Language',
	languageUpdated: 'Language Updated',
	english: 'English',
	french: 'French',
	spanish: 'Spanish',
	portuguese: 'Portuguese',
	german: 'German',
	logout: 'Logout',
	loggedOut: 'Logged Out',
	unitsUpdated: 'Units Updated',
	featureRequest: 'Feature Request',
	deleteAccount: 'Delete Account',
	verifyDeleteMessage:
		'This will delete your account and all data, are you sure?',
	accountDeleted: 'Account Deleted',
	net: 'Net',
	total: 'Total',
	preferredCarb: 'Preferred Carb',
	preferredCarbDesc:
		'Total carbs include fiber and sugar alcohols, while Net carbs only include countable carbs that affect ketosis. Most keto dieters only track Net carbs.',
	updateMacroGoals: 'Update Macro Goals',
	defaultMacroGoalsUpdated: 'Default Macro Goals Updated',
	customGoalsApplied: 'Custom Goals Applied To Dates',
	tracking: 'Tracking',
	recalculateMacroGoals: 'Re-Calculate Macro Goals',
	manuallyChangeMacroGoals: 'Manually Change Macro Goals',
	changePreferences: 'Change Preferences',
	restoreAdFree: 'Restore Ad Free Purchase',
	darkMode: 'Dark Mode',

	//Preferences
	otherTracking: 'Other Tracking',
	other: 'Other',
	notes: 'Notes',

	//Purchase
	invalidCode: 'Invalid Code',
	purchaseRestored: 'Purchase Restored',
	userNotFound: 'User Not Found',
	error: 'Error',
	errorProducts:
		'There was an error trying to load products. Make sure you have a valid internet connection.',
	trackerEnabledAdsRemoved: 'Purchase Restored, Ads Removed',
	purchaseTracking: 'Purchase Tracking',
	gettingProductDetails: 'Getting Product Details ...',
	noPurchaseFound: 'No Purchase Found',
	noPurchaseFoundDesc:
		'No purchase was found on this account. Please email us if you think that is incorrect.',
	premium: 'Premium',
	premiumEnabled: 'Premium Enabled',

	//login
	account: 'Account',
	signIn: 'Sign In',
	logIn: 'Log In',
	signedIn: 'Signed In',
	accountCreated: 'Account Created',
	signUp: 'Sign Up',
	email: 'Email',
	password: 'Password',
	forgotPassword: 'Forgot Password?',
	resetEmailSent: 'Reset Email Sent',
	newUser: 'New User?',
	emailError: 'Enter a valid email',
	passwordError: 'Enter a password',
	emailSubscribe: 'Get updates and special offers from MyKeto',
	enterYourEmail: 'Enter Your Email',
	medicalDisclaimer: 'Medical Disclaimer',
	disclaim:
		'Medical Disclaimer: MyKeto is offered for educational and entertainment purposes only, and in no way intends to diagnose, cure, or treat any medical or other condition. Always seek the advice of your physician or other qualified health provider prior to changing your diet or exercise plan and ask your physician any questions you may have regarding a medical condition. Although we do our best to verify the accuracy of information contained herein, we cannot guarantee its accuracy.',
	skipAccount: 'Skip Account',
	registerAccount: 'Register Account',
	convertAccount: 'Convert Account to Login',
	createAccountLater:
		'You can create an account later with your data by going to the Settings menu',
	whyLogin: 'Why Login?',
	usingAccount:
		"Using an account will back up and sync your data across all your other devices. It only takes a second to register and we don't share or store any personal details.",

	//Editing Item Modal
	totalcarbError: "Total can't be less than Fiber",
	auto: 'Auto',
	manual: 'Manual',
	name: 'Name',
	editingItem: 'Editing Item',

	//Food Tabs
	foods: 'Foods',
	meals: 'Meals',
	meal: 'Meal',
	favorites: 'Favorites',
	recent: 'Recents',
	recentlyused: 'Recently Used',
	saveToFoods: 'Save To Foods',
	searched: 'Searched',
	myfoods: 'My Foods',
	mymeals: 'My Meals',
	quickmacro: 'Quick Macros',
	create: 'Create',
	created: 'Created',
	createFood: 'Create Food',
	createMeal: 'Create Meal',
	ingredientNotFound: 'Ingredient not found, try to add it again',
	totalServings: 'Total Servings',
	totalServingsDesc: 'Please enter a number for total servings',
	clearAllFavorites: 'Clear All Favorites?',
	clearAllFavoritesDesc:
		'This will clear all of your favorites on all synced devices',
	clearAllRecentlyUsed: 'Clear All Recently Used Items?',
	clearAllRecentlyUsedConfirm:
		'This will clear your recently logged items on all synced devices',
	removedFromRecentlyUsed: 'Removed From Recently Used',
	itemsAdded: 'Items Added',
	mealName: 'Meal Name',
	servingName: 'Serving Name',
	wholeRecipe: 'Whole Recipe',

	//Onboard
	clickStart: 'You are all set, click the button below to get started',
	privacyPolicy: 'Privacy Policy',
	termsConditions: 'Terms and Conditions',
	policyAgree: 'By clicking below, you are agreeing to our',
	unitsOnboard:
		'Choose your preferred units for Body Measurements (Weight, Chest Size, etc) and Water',
	clockOnboard:
		'Choose how you want your start and end timer display formatted',
	scheduleOnboard:
		'Choose if you want to receive notifications every time you change windows and if you want a reminder before the period finishes.',
	manualOnboardSettings:
		'Choose your default fast goal (you can quickly change this on the homepage) and if you want to choose your goal in total hours or if you want to select a date and time for your goal.',
	manualOnboardNotifications:
		'Choose if you want to receive notifications such as a specific reminder to fast every day at a certain time, and reminders when the fast has ended or is about to end soon.',
	weightLoss: 'Weight Loss',
	diseasePrevention: 'Disease Prevention',
	lowerBloodPressure: 'Lower Blood Pressure',
	improvedCholesterolProfile: 'Improved Cholesterol Profile',
	antiAging: 'Anti-Aging & Longevity',
	insulinManagement: 'Insulin and Diabetes Management',
	antiCancer: 'Anti-Cancer',
	heartHealth: 'Heart Health',
	improvedSkin: 'Improved Skin',
	dentalHealth: 'Dental Health',
	whyKeto: 'Why are you interested in the ketogenic diet?',
	betterTailor: 'We will better tailor MyKeto to help you with your goals',
	goals: 'Goals',
	goalsExplanation:
		'We will use this information to help you predict your progress',
	finished: 'Finished',
	startTracking: 'Start Tracking',

	//Body Measurements
	weightUnits: 'Weight and Body Measurements',
	units: 'Units',
	metric: 'Metric',
	imperial: 'Imperial',
	ftcm: 'ft, lbs',
	bodyMeasurements: 'Measurements',
	Weight: 'Weight',
	BMI: 'BMI',
	BodyFat: 'Body Fat',
	LeanBodyMass: 'Lean Body Mass',
	WaistSize: 'Waist Size',
	HipSize: 'Hip Size',
	ChestSize: 'Chest Size',
	NeckSize: 'Neck Size',
	ArmSize: 'Arm Size',
	ForearmSize: 'Forearm Size',
	CalfSize: 'Calf Size',
	ThighSize: 'Thigh Size',
	enterNumber: 'Please enter a number above zero.',

	//Vitals
	vitals: 'Vitals',
	water: 'Water',
	bloodGlucose: 'Blood Glucose',
	BloodGlucose: 'Blood Glucose',
	'Blood Glucose': 'Blood Glucose',
	Exercise: 'Exercise',
	BloodPressure: 'Blood Pressure',
	'Blood Pressure': 'Blood Pressure',
	HeartRate: 'Heart Rate',
	'Heart Rate': 'Heart Rate',
	Ketones: 'Ketones',
	ketones: 'Ketones',
	Potassium: 'Potassium',
	Sodium: 'Sodium',
	Magnesium: 'Magnesium',
	Systolic: 'Systolic',
	Diastolic: 'Diastolic',

	//Log
	breakfast: 'Breakfast',
	lunch: 'Lunch',
	dinner: 'Dinner',
	snacks: 'Snacks',
	quickAdd: 'Quick Add',
	quickAddMacros: 'Quick Add Macros',
	quickAddToMeal: 'Quick Add To Meal',
	copyTo: 'Copy To',
	saveAsMeal: 'Save As Meal',
	addEntry: 'Add Entry',
	caloriesFromMacros: 'Calories From Macros',
	eaten: 'Eaten',
	remaining: 'Remaining',
	macroGoalsEaten: 'Macro Goals Eaten',
	per: 'Per',

	//Other Tracking
	syncExercise: 'Sync Exercise',
	estimateCalories: 'Estimate Calories',
	selectTime: 'Select Time',
	duration: 'Duration',
	caloriesBurned: 'Calories Burned',
	addExerciseCal: 'Add Exercise Cal',
	Notes: 'Notes',
	exercise: 'Exercise',
	Water: 'Water',
	Mood: 'Mood',
	mood: 'Mood',

	//Progress
	custom: 'Custom',
	oneWeek: '1 Week',
	oneMonth: '1 Month',
	threeMonths: '3 Months',
	sixMonths: '6 Months',
	oneYear: '1 Year',
	allTime: 'All Time',
	to: 'To',
	addTwoEntries: 'Add 2 or more entries',
	addMoreEntriesToSeeLine:
		'Add more entries to see line-chart of macros over time',
	netChange: 'Net Change',
	noEntriesFound: 'No Entries Found',
	addFoodEntries: 'Add food entries to your log to see data',
	viewAllData: 'View All Data',

	//export
	exportWeight: 'Export Weight',
	exportBodyMeasurements: 'Export Body Measurements',
	exportVitals: 'Export Vitals',
	exportOthers: 'Export Others',
	permissionTitle: 'File Permission',
	permissionMessage:
		'We need permission to save the export .csv file to your device storage.',
	pickOne: 'Pick at least one option',
	emailSent: 'Email Successfully Sent',
	emailNotSent: 'Email Not Sent',
	export: 'Export',
	type: 'Type',
	dietLog: 'Diet Log',
	data: 'Data',
	allData: 'All Data',
	noData: 'No Data',
	noDataDesc: 'There is no data to export',

	//Fitness Trackers
	fitbitDesc:
		'Send your MyKeto weight, and macro data into Fitbit. Send your Fitbit exercise activity into MyKeto',
	fitbitConnected: 'Fitbit Syncing Connected',
	fitbitRemove: 'Do you want to remove Fitbit access from this device?',
	fitbitDisconnected: 'Fitbit disconnected',
	healthkit1:
		'To update your permissions for Apple Healthkit, open the health kit app on your phone, tap on sources, and select MyKeto from the list',
	healthkit2:
		'Send your MyKeto weight, water, and macros data into Apple HealthKit',

	//Social
	friendRequest: 'Friend request',
	tryAddFriend:
		"Try adding a friend by email with the (+) button. Once accepted, you can copy each other's saved foods and meals.",
	alreadyCopied: 'Already Copied',
	sent: 'Sent',
	received: 'Received',
	alreadyInvited: 'Already Invited',
	alreadyPendingInvite: 'This user already has a pending invite',
	alreadyFriended: 'Already Friended',
	alreadyYourFriend: 'This user is already your friend',
	pendingInvite: 'Pending Invite',
	pendingAlready: 'This user already sent you an invite',
	friendRequestSent: 'Friend Request Sent',
	invalidEmail: 'Invalid Email',
	cantFriendYourself: "You can't friend yourself",
	friendRequestCanceled: 'Friend Request Canceled',
	friendRequestAccepted: 'Friend Request Accepted',
	friendRequestDeclined: 'Friend Request Declined',
	unfriended: 'Unfriended',
	allFoodsCopied: 'All Foods Copied',
	friendNotAnon:
		'Please convert your account in the settings page to enable the social features',

	//Exercise Calculator
	ex1: 'Aerobic Dance (Casual)',
	ex2: 'Aerobic Dance (Moderate)',
	ex3: 'Aerobic Dance (Intense)',
	ex4: 'Basketball (Full court)',
	ex5: 'Basketball (Half court)',
	ex6: 'Calisthenics',
	ex7: 'Cycling (5.5 mph, 9 kmph)',
	ex8: 'Cycling (10 mph, 16 kmph)',
	ex9: 'Cycling (13 mph, 21 kmph)',
	ex10: 'Dancing (Casual)',
	ex11: 'Dancing (Moderate)',
	ex12: 'Dancing (Intense)',
	ex13: 'Field Sports',
	ex14: 'Frisbie',
	ex15: 'Golf (walking)',
	ex16: 'Golf (with Cart)',
	ex17: 'Gymnastics',
	ex18: 'Housework',
	ex19: 'Jump Rope',
	ex20: 'Martial Arts',
	ex21: 'Racquetball',
	ex22: 'Rollerblading',
	ex23: 'Rowing Machine (Intense)',
	ex24: 'Rowing Machine (Moderate)',
	ex25: 'Running (4 mph, 6.5 kmph)',
	ex26: 'Running (5 mph, 8 kmph)',
	ex27: 'Running (6 mph, 9.7 kmph)',
	ex28: 'Running (7 mph, 11.3 kmph)',
	ex29: 'Running (8 mph, 13 kmph)',
	ex30: 'Running (10 mph, 16 kmph)',
	ex31: 'Running (12 mph, 19.3 kmph)',
	ex32: 'Softball',
	ex33: 'Stair Step (6", 15.2 cm)',
	ex34: 'Stair Step (8", 20.3 cm)',
	ex35: 'Stair Step (10", 25.4 cm)',
	ex36: 'Stair Step (12", 30.5 cm)',
	ex37: 'Swimming (Casual)',
	ex38: 'Swimming (intense)',
	ex39: 'Tennis',
	ex40: 'Volleyball',
	ex41: 'Walking (2 mph, 3.2 kmph)',
	ex42: 'Walking (3 mph, 4.8 kmph)',
	ex43: 'Walking (4 mph, 6.5 kmph)',
	ex44: 'Weight Training (Intense)',
	ex45: 'Weight Training (Normal)',
	ex46: 'Yardwork',
	ex47: 'Yoga (Hatha)',
	ex48: 'Yoga (Ashtanga / Power)',
	ex49: 'Yoga (Hot / Bikram)',
	ex50: 'Yoga (Vinyasa / Flow)',
	exerciseCalculatorDescrition:
		'Enter your weight, exercise duration, and the activity to estimate your energy expenditure',

	//Shopping List
	addItem: 'Add Item',
	deleteItem: 'Delete Item'

	//Nutrients:
};
