//@flow
const Colors = {
	base: '#e6e6e6',
	baseBG: '#FFF',
	baseAlt: '#FAFAFA',
	themeAccent: '#326bcc',
	themeAccentDesat: '#5875a5',
	navBar: '#326bcc',
	navButton: '#FFF',
	navText: '#FFF',
	tabBase: '#e6e6e6',
	tabActive: '#326bcc',
	tabInactive: '#333',
	popMenuBg: '#FFF',
	grey: '#f5f5f5',
	border: '#dddddd',
	backdropGray: '#e6e6e6',
	placeholderGray: '#5d5d5d',
	darkFont: '#333',
	lightFont: '#333',
	green: '#00b894',
	teal: '#68c3b9',
	red: '#e17055',
	orange: '#FFC107',
	blue: '#64a4d3',
	yellow: '#fdcb6e',
	white: '#FFF',
	pickerBg: [50, 107, 204, 1],
	pickerBase: [255, 255, 255, 1],
	graphFat: '#0984e3',
	graphCarb: '#e17055',
	graphProtein: '#00b894',
	graphFatSelected: '#68caff',
	graphCarbSelected: '#ff8b6a',
	graphProteinSelected: '#ffd562',
	submit: '#2ecc71',
	highlightedText: '#dddddd',
	loaderBG: 'rgba(255,255,255,0.18)',
	nutrientBG: '#f5f5f5'
};

const Dark = {
	base: '#272727',
	baseBG: '#131313',
	baseAlt: '#212121',
	themeAccent: '#5875a5',
	themeAccentDesat: '#777d87',
	navBar: '#272727',
	navButton: '#f9f9f9',
	navText: '#f9f9f9',
	tabBase: '#272727',
	tabActive: '#5875a5',
	tabInactive: '#777d87',
	popMenuBg: '#272727',
	grey: '#272727',
	border: '#212121',
	backdropGray: '#131313',
	placeholderGray: '#5d5d5d',
	darkFont: '#f9f9f9',
	lightFont: '#f9f9f9',
	green: '#378172',
	teal: '#83a8a4',
	red: '#b78a7f',
	orange: '#b59c51',
	blue: '#859fb2',
	yellow: '#d2be99',
	white: '#FFF',
	pickerBg: [96, 119, 158, 1],
	pickerBase: [0, 0, 0, 1],
	graphFat: '#4a7ca2',
	graphCarb: '#b78a7f',
	graphProtein: '#378172',
	graphFatSelected: '#95bcd2',
	graphCarbSelected: '#d2a497',
	graphProteinSelected: '#d0bf91',
	submit: '#5d9d78',
	highlightedText: '#5d5d5d',
	loaderBG: 'rgba(0,0,0,0.8)',
	nutrientBG: '#131313'
};

export { Colors, Dark };
