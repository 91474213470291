import { i18n } from 'Theme';

const DATA = [
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food0'),
		netcarbs: 0.7,
		servingsize: '1oz (28g)'
	},
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food1'),
		netcarbs: 0.4,
		servingsize: '1oz (28g)'
	},
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food2'),
		netcarbs: 3.8,
		servingsize: '4oz (113g)'
	},
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food3'),
		netcarbs: 2.0,
		servingsize: '2tbsp (30g)'
	},
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food4'),
		netcarbs: 1.6,
		servingsize: '1 wedge (38g)'
	},
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food5'),
		netcarbs: 0.6,
		servingsize: '1oz (28g)'
	},
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food6'),
		netcarbs: 0.6,
		servingsize: '1oz (28g)'
	},
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food7'),
		netcarbs: 0.6,
		servingsize: '1oz (28g)'
	},
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food8'),
		netcarbs: 3.8,
		servingsize: '1/2 cup (124g)'
	},
	{
		type: i18n.t('cheeses'),
		title: i18n.t('food9'),
		netcarbs: 1.5,
		servingsize: '1oz (28g)'
	},
	{
		type: i18n.t('dairy'),
		title: i18n.t('food10'),
		netcarbs: 1.9,
		servingsize: '1oz (28g)'
	},
	{
		type: i18n.t('dairy'),
		title: i18n.t('food11'),
		netcarbs: 1,
		servingsize: '1/3 cup (79ml)'
	},
	{
		type: i18n.t('dairy'),
		title: i18n.t('food12'),
		netcarbs: 0.5,
		servingsize: '1 large (63g)'
	},
	{
		type: i18n.t('dairy'),
		title: i18n.t('food13'),
		netcarbs: 0.8,
		servingsize: '1 fl oz (30g)'
	},
	{
		type: i18n.t('dairy'),
		title: i18n.t('food14'),
		netcarbs: 7,
		servingsize: '1/2 cup (66g)'
	},
	{
		type: i18n.t('dairy'),
		title: i18n.t('food15'),
		netcarbs: 12,
		servingsize: '1 cup (244 mL) 2%'
	},
	{
		type: i18n.t('dairy'),
		title: i18n.t('food16'),
		netcarbs: 3,
		servingsize: '1 cup (243g)'
	},
	{
		type: i18n.t('dairy'),
		title: i18n.t('food17'),
		netcarbs: 0.8,
		servingsize: '1 fl oz (30g)'
	},
	{
		type: i18n.t('dairy'),
		title: i18n.t('food18'),
		netcarbs: 9,
		servingsize: '1 cup (227g)'
	},
	{
		type: i18n.t('dressings'),
		title: i18n.t('food19'),
		netcarbs: 1.2,
		servingsize: '2tbsp (30g)'
	},
	{
		type: i18n.t('dressings'),
		title: i18n.t('food20'),
		netcarbs: 1.0,
		servingsize: '2tbsp (30g)'
	},
	{
		type: i18n.t('dressings'),
		title: i18n.t('food21'),
		netcarbs: 1.4,
		servingsize: '2tbsp (30g)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food22'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food23'),
		netcarbs: 0,
		servingsize: '1 tbsp (14g)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food24'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food25'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food26'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food27'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food28'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food29'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food30'),
		netcarbs: 0,
		servingsize: '1 tbsp (15g)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food31'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food32'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food33'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food34'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fatsoils'),
		title: i18n.t('food35'),
		netcarbs: 0,
		servingsize: '1 tbsp (15ml)'
	},
	{
		type: i18n.t('fish'),
		title: i18n.t('food36'),
		netcarbs: 0,
		servingsize: '8oz uncooked'
	},
	{
		type: i18n.t('fish'),
		title: i18n.t('food37'),
		netcarbs: 0,
		servingsize: '8oz uncooked'
	},
	{
		type: i18n.t('fish'),
		title: i18n.t('food38'),
		netcarbs: 0,
		servingsize: '8oz uncooked'
	},
	{
		type: i18n.t('fish'),
		title: i18n.t('food39'),
		netcarbs: 0,
		servingsize: '8oz uncooked'
	},
	{
		type: i18n.t('fish'),
		title: i18n.t('food40'),
		netcarbs: 0,
		servingsize: '8oz uncooked'
	},
	{
		type: i18n.t('fish'),
		title: i18n.t('food41'),
		netcarbs: 0,
		servingsize: '8oz uncooked'
	},
	{
		type: i18n.t('fish'),
		title: i18n.t('food42'),
		netcarbs: 0,
		servingsize: '8oz uncooked'
	},
	{
		type: i18n.t('fish'),
		title: i18n.t('food43'),
		netcarbs: 0,
		servingsize: '1 can'
	},
	{
		type: i18n.t('flours'),
		title: i18n.t('food44'),
		netcarbs: 3,
		servingsize: '1/4 cup (28g)'
	},
	{
		type: i18n.t('flours'),
		title: i18n.t('food45'),
		netcarbs: 1,
		servingsize: '1 tbsp (5g)'
	},
	{
		type: i18n.t('flours'),
		title: i18n.t('food46'),
		netcarbs: 0,
		servingsize: '2 tbsp (13g)'
	},
	{
		type: i18n.t('flours'),
		title: i18n.t('food47'),
		netcarbs: 0,
		servingsize: '2 scoops'
	},
	{
		type: i18n.t('flours'),
		title: i18n.t('food48'),
		netcarbs: 0.5,
		servingsize: '1 tbsp (6g)'
	},
	{
		type: i18n.t('flours'),
		title: i18n.t('food49'),
		netcarbs: 23,
		servingsize: '1/4 cup (31g)'
	},
	{
		type: i18n.t('fowl'),
		title: i18n.t('food50'),
		netcarbs: 0,
		servingsize: '1 leg (101g)'
	},
	{
		type: i18n.t('fowl'),
		title: i18n.t('food51'),
		netcarbs: 0,
		servingsize: '1 wing (29g)'
	},
	{
		type: i18n.t('fowl'),
		title: i18n.t('food52'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('fowl'),
		title: i18n.t('food53'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('fowl'),
		title: i18n.t('food54'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('fowl'),
		title: i18n.t('food55'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('fowl'),
		title: i18n.t('food56'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('fowl'),
		title: i18n.t('food57'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food58'),
		netcarbs: 38,
		servingsize: '1 cup (157g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food59'),
		desc:
			'Most are high in wheat and sugar and are the worst high carb foods to eat because of the added preservatives and fillers.',
		netcarbs: 26,
		servingsize: '6 pizza rolls (85g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food60'),
		netcarbs: 15,
		servingsize: '1 slice (30g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food61'),
		netcarbs: 18,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food62'),
		netcarbs: 29,
		servingsize: '1 piece (50g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food63'),
		netcarbs: 12,
		servingsize: '1 cup (237g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food64'),
		netcarbs: 47,
		servingsize: '1 cup (81g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food65'),
		netcarbs: 24,
		servingsize: '3 Oreos (34g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food66'),
		netcarbs: 17,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food67'),
		netcarbs: 31,
		servingsize: '1 piece (60g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food68'),
		netcarbs: 35,
		servingsize: '50g'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food69'),
		netcarbs: 22,
		servingsize: '10 crackers (30g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food70'),
		netcarbs: 30,
		servingsize: '1 cup (242g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food71'),
		netcarbs: 21,
		servingsize: '1/2 cup (72g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food72'),
		netcarbs: 23,
		servingsize: '1 cup, cooked (234g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food73'),
		netcarbs: 23,
		servingsize: '1 muffin (57g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food74'),
		netcarbs: 22,
		servingsize: '1 waffle 6" diameter (77g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food75'),
		netcarbs: 25,
		servingsize: '100g'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food76'),
		netcarbs: 30,
		servingsize: '1 piece (50g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food77'),
		netcarbs: 30,
		servingsize: '1 cup (242g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food78'),
		netcarbs: 18,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food79'),
		netcarbs: 14,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food80'),
		netcarbs: 46,
		servingsize: '10 twists (60g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food81'),
		netcarbs: 42,
		servingsize: '1 cup, cooked (195g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food82'),
		netcarbs: 38,
		servingsize: '1 cup (157g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food83'),
		netcarbs: 30,
		servingsize: '50g'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food84'),
		netcarbs: 28,
		servingsize: '1 piece (186g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food85'),
		netcarbs: 20,
		servingsize: '3oz (85g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food86'),
		netcarbs: 33,
		servingsize: '50g'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food87'),
		netcarbs: 20,
		servingsize: '1 tortilla (40g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food88'),
		netcarbs: 34,
		servingsize: '1 cup (157g)'
	},
	{
		type: i18n.t('grainsstarch'),
		title: i18n.t('food89'),
		netcarbs: 25,
		servingsize: '1 waffle 7" diameter (75g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food90'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food91'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food92'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food93'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food94'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food95'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food96'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food97'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food98'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food99'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('meats'),
		title: i18n.t('food100'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food101'),
		netcarbs: 15,
		servingsize: '1/2 cup (100g)'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food102'),
		netcarbs: 8.3,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food103'),
		netcarbs: 12,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food104'),
		netcarbs: 2.3,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food105'),
		netcarbs: 12,
		servingsize: '1/2 cup (100g)'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food106'),
		netcarbs: 1.5,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food107'),
		netcarbs: 3.8,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food108'),
		netcarbs: 4.5,
		servingsize: '2 tbsp (32g)'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food109'),
		netcarbs: 1.2,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food110'),
		netcarbs: 4.8,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('nutslegumes'),
		title: i18n.t('food111'),
		netcarbs: 2,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('seeds'),
		title: i18n.t('food112'),
		netcarbs: 0,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('seeds'),
		title: i18n.t('food113'),
		netcarbs: 1.4,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('seeds'),
		title: i18n.t('food114'),
		netcarbs: 2.7,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('seeds'),
		title: i18n.t('food115'),
		netcarbs: 9.7,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('seeds'),
		title: i18n.t('food116'),
		netcarbs: 3.3,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('seeds'),
		title: i18n.t('food117'),
		netcarbs: 3.6,
		servingsize: '1oz 28g'
	},
	{
		type: i18n.t('shellfish'),
		title: i18n.t('food118'),
		netcarbs: 0,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('shellfish'),
		title: i18n.t('food119'),
		netcarbs: 0.7,
		servingsize: '1 lobster (150g)'
	},
	{
		type: i18n.t('shellfish'),
		title: i18n.t('food120'),
		netcarbs: 3.1,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('shellfish'),
		title: i18n.t('food121'),
		netcarbs: 4.2,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('shellfish'),
		title: i18n.t('food122'),
		netcarbs: 0.8,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('shellfish'),
		title: i18n.t('food123'),
		netcarbs: 2.6,
		servingsize: '3 oz (85g)'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food124'),
		netcarbs: 0.5,
		servingsize: '1 cube (4g)'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food125'),
		netcarbs: 0,
		servingsize: '8 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food126'),
		netcarbs: 0,
		servingsize: '8 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food127'),
		netcarbs: 0,
		servingsize: '12 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food128'),
		netcarbs: 0,
		servingsize: '12 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food129'),
		netcarbs: 0,
		servingsize: '12 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food130'),
		netcarbs: 0,
		servingsize: '8 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food131'),
		netcarbs: 0.5,
		servingsize: '8 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food132'),
		netcarbs: 0,
		servingsize: '8 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food133'),
		netcarbs: 40,
		servingsize: '12 oz can'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food134'),
		netcarbs: 0,
		servingsize: '1 can'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food135'),
		netcarbs: 0,
		servingsize: '1 can'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food136'),
		netcarbs: 0,
		servingsize: '8 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food137'),
		netcarbs: 0,
		servingsize: '8 fl oz'
	},
	{
		type: i18n.t('beverages'),
		title: i18n.t('food138'),
		netcarbs: 0,
		servingsize: '8 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food139'),
		netcarbs: 14,
		servingsize: '12 oz can'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food140'),
		netcarbs: 0,
		servingsize: '1.5 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food141'),
		netcarbs: 0,
		servingsize: '1.5 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food142'),
		netcarbs: 0,
		servingsize: '1.5 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food143'),
		netcarbs: 4.5,
		servingsize: '12 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food144'),
		netcarbs: 0,
		servingsize: '1.5 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food145'),
		netcarbs: 0,
		servingsize: '1.5 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food146'),
		netcarbs: 0,
		servingsize: '1.5 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food147'),
		netcarbs: 0,
		servingsize: '1.5 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food148'),
		netcarbs: 4,
		servingsize: '5 fl oz'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food149'),
		netcarbs: 14,
		servingsize: '3.5 oz glass'
	},
	{
		type: i18n.t('alcohol'),
		title: i18n.t('food150'),
		netcarbs: 0,
		servingsize: '1.5 fl oz'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food151'),
		netcarbs: 27,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food152'),
		netcarbs: 28,
		servingsize: '1 average bar (44g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food153'),
		netcarbs: 28,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food154'),
		netcarbs: 24,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food155'),
		netcarbs: 28,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food156'),
		netcarbs: 0,
		servingsize: '1 tsp (4g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food157'),
		netcarbs: 28,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food158'),
		netcarbs: 28,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food159'),
		netcarbs: 24,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food160'),
		netcarbs: 28,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food161'),
		netcarbs: 28,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food162'),
		netcarbs: 21,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food163'),
		netcarbs: 28,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food164'),
		netcarbs: 28,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food165'),
		netcarbs: 0,
		servingsize: '1 tsp (4g)'
	},
	{
		type: i18n.t('sugarssweetener'),
		title: i18n.t('food166'),
		netcarbs: 28,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food167'),
		netcarbs: 21,
		servingsize: '1 medium 3" (182g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food168'),
		netcarbs: 9,
		servingsize: '3 apricot (100g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food169'),
		netcarbs: 2,
		servingsize: '1/2 avocado (100g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food170'),
		netcarbs: 24,
		servingsize: '1 medium 7" long (118g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food171'),
		netcarbs: 6,
		servingsize: '1 cup (144g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food172'),
		netcarbs: 17,
		servingsize: '1 cup (148g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food173'),
		netcarbs: 10,
		servingsize: '1 NLEA serving (134g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food174'),
		netcarbs: 17,
		servingsize: '1 cup without pits (155g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food175'),
		netcarbs: 7.4,
		servingsize: '1 cup whole (100g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food176'),
		netcarbs: 15,
		servingsize: '3 dates (21g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food177'),
		netcarbs: 8,
		servingsize: '100g'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food178'),
		netcarbs: 24,
		servingsize: '100g'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food179'),
		netcarbs: 28,
		servingsize: '100g'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food180'),
		netcarbs: 15,
		servingsize: '1 cup (92g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food181'),
		netcarbs: 14,
		servingsize: '1 NLEA serving (154g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food182'),
		netcarbs: 11,
		servingsize: '1 NLEA serving (134g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food183'),
		netcarbs: 33,
		servingsize: '1 cup (249g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food184'),
		netcarbs: 7.9,
		servingsize: '1 fruit (69g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food185'),
		netcarbs: 3.4,
		servingsize: '1 fruit (58g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food186'),
		netcarbs: 5.1,
		servingsize: '1 fruit (67g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food187'),
		netcarbs: 23,
		servingsize: '1 cup, pieces (165g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food188'),
		netcarbs: 11.7,
		servingsize: '1 medium 2-2/3" dia (150g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food189'),
		netcarbs: 7.7,
		servingsize: '1 small 2-3/8" dia (96g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food190'),
		netcarbs: 14,
		servingsize: '1 fruit, small (157g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food191'),
		netcarbs: 4.6,
		servingsize: '2 fruit, without refuse (36g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food192'),
		netcarbs: 11.7,
		servingsize: '1 medium 2-2/3" dia (150g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food193'),
		netcarbs: 21,
		servingsize: '1 medium (178g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food194'),
		netcarbs: 20,
		servingsize: '1 cup, chunks (165g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food195'),
		netcarbs: 12.5,
		servingsize: '1/2 cup arils (87g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food196'),
		netcarbs: 7,
		servingsize: '1 fruit (66g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food197'),
		netcarbs: 10.6,
		servingsize: '2 prunes, pitted (20g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food198'),
		netcarbs: 32,
		servingsize: '1 small box (1.5 oz, 43g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food199'),
		netcarbs: 7,
		servingsize: '1 cup (123g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food200'),
		netcarbs: 8,
		servingsize: '1 NLEA serving (147g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food201'),
		netcarbs: 13,
		servingsize: '1 NLEA serving (109g)'
	},
	{
		type: i18n.t('fruits'),
		title: i18n.t('food202'),
		netcarbs: 19,
		servingsize: '1 NLEA serving (280g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food203'),
		netcarbs: 0.1,
		servingsize: '1 cup (33g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food204'),
		netcarbs: 1,
		servingsize: '1 NLEA Serving (85g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food205'),
		netcarbs: 6,
		servingsize: '1 medium (128g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food206'),
		netcarbs: 2.5,
		servingsize: '1 cup (134g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food207'),
		netcarbs: 0.6,
		servingsize: '1 cup (120g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food208'),
		netcarbs: 4.3,
		servingsize: '1 cup (104g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food209'),
		netcarbs: 0.2,
		servingsize: '1 cup (38g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food210'),
		netcarbs: 0.8,
		servingsize: '1 cup, shredded (70g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food211'),
		netcarbs: 6,
		servingsize: '1 NLEA serving (148g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food212'),
		netcarbs: 4.5,
		servingsize: '1 cup (88g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food213'),
		netcarbs: 3,
		servingsize: '1 cup, chopped (89g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food214'),
		netcarbs: 3.2,
		servingsize: '1 cup, chopped (107g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food215'),
		netcarbs: 1.5,
		servingsize: '1 NLEA serving (110g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food216'),
		netcarbs: 11,
		servingsize: '1 cup (156g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food217'),
		netcarbs: 0.7,
		servingsize: '1 cup (36g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food218'),
		netcarbs: 0,
		servingsize: '1 tbsp chopped (3g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food219'),
		netcarbs: 0.6,
		servingsize: '1 cup, chopped (36g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food220'),
		netcarbs: 34,
		servingsize: '50g'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food221'),
		netcarbs: 1.6,
		servingsize: '1/2 cup slices (52g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food222'),
		netcarbs: 3.1,
		servingsize: '1 cup, chopped (55g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food223'),
		netcarbs: 0.7,
		servingsize: '1 medium (3-3.75 in long, 65g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food224'),
		netcarbs: 2.8,
		servingsize: '3 cloves (9g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food225'),
		netcarbs: 3.5,
		servingsize: '1 cup, chopped (67g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food226'),
		netcarbs: 11,
		servingsize: '1 cup (89g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food227'),
		netcarbs: 2,
		servingsize: '3.5 oz (100g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food228'),
		netcarbs: 26,
		servingsize: '1 cup, boiled (188g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food229'),
		netcarbs: 1.6,
		servingsize: '1 cup, pieces (70g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food230'),
		netcarbs: 4,
		servingsize: '1 cup (100g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food231'),
		netcarbs: 0.5,
		servingsize: '3.5oz (100g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food232'),
		netcarbs: 8.1,
		servingsize: '1 medium (110g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food233'),
		netcarbs: 14,
		servingsize: '1 cup (145g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food234'),
		netcarbs: 32,
		servingsize: '1 medium (213g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food235'),
		netcarbs: 2,
		servingsize: '1 cup slices (116g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food236'),
		netcarbs: 1.9,
		servingsize: '1 cup (142g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food237'),
		netcarbs: 4.4,
		servingsize: '1 cup, chopped (100g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food238'),
		netcarbs: 4.7,
		servingsize: '1 oz (28g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food239'),
		netcarbs: 0.4,
		servingsize: '1 cup (30g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food240'),
		netcarbs: 22,
		servingsize: '1 5" long (130g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food241'),
		netcarbs: 0.7,
		servingsize: '1 cup (36g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food242'),
		netcarbs: 5.8,
		servingsize: '1 medium (122g)'
	},
	{
		type: i18n.t('vegetables'),
		title: i18n.t('food243'),
		netcarbs: 13,
		servingsize: '1/2 cup slices (62g)'
	}
];

export default DATA;
