// @flow

const CONFIG = {
	SENDGRIDURL: 'https://api.sendgrid.com/v3/mail/send',
	KEY: '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'
};

export const sendGridEmail = (email, language) => {
	const isSuccess = sendEmail(email, language);
	return isSuccess;
};

export const sendEmail = (email, language) => {
	return fetch(CONFIG.SENDGRIDURL, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + CONFIG.KEY
		},
		body: JSON.stringify({
			from: {
				email: 'support@myketotracker.com'
			},
			personalizations: [
				{
					to: [
						{
							email: email
						}
					],
					dynamic_template_data: {
						[`${language}`]: true
					}
				}
			],
			template_id: 'd-b68a9e3b1e234829b603c9df1d262e14'
		})
	})
		.then(response => {
			return true;
		})
		.catch(error => {
			return false;
		});
};
