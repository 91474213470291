//@flow
import Toast from 'react-native-root-toast';
import { Platform } from 'react-native';
import { i18n } from 'Theme';
import { WebCompatAlert } from 'Components/common';
import { CHANGE_OTHER_REVIEW_NAME } from 'Redux/actions/types';
import {
	ahk_updateWater,
	ahk_deleteWater
} from 'Components/tracker/fitnesstrackers/utilities/AppleHealthKit';
import moment from 'moment';
import { randomString } from 'Utilities';
//.............................................Other Tracking Section.......................................//

//Saves or Updates Vital Key/Value for a specific Date. Used for both Saving original and Editing Previous Entries
//Date in YYYY-MM-DD format
export const saveOtherTracking = (
	firebaseOtherTrackingName: string,
	value: Object,
	id: string,
	originalDateIfUpdating?: string
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { ahkWasAuth } = state.fitnessTracker.appleHealthKit;
	//const { auth: fitbitAuth } = state.fitnessTracker.fitbit;
	//const { waterUnits } = state.firebase.profile.settings.units;
	const ref = firebase.database().ref(`userdata/${uid}/othertracking/`);
	let updates = {};
	if (originalDateIfUpdating) {
		updates[
			`${moment(originalDateIfUpdating).format(
				'YYYY-MM-DD'
			)}/${firebaseOtherTrackingName}/${id}`
		] = {};
	}
	const fbnodeDate = moment(value.time).format('YYYY-MM-DD'); //convert iso string from vital entry object to get needed YYYY-MM-DD value for fb node
	updates[`${fbnodeDate}/${firebaseOtherTrackingName}/${id}`] = value;

	if (firebaseOtherTrackingName === 'Water') {
		if (Platform.OS === 'ios' && ahkWasAuth) {
			ahk_updateWater(value);
		}
		/*if (fitbitAuth.isAuth) {
			dispatch(
				fitbit_saveWater(
					fitbitAuth.access_token,
					fbnodeDate,
					value.value,
					waterUnits === 'ml'
				)
			);
		}*/
	}

	ref.update(updates).then(() => {
		Toast.show(i18n.t('updated'), {
			duration: Toast.durations.LONG,
			position: Toast.positions.BOTTOM,
			shadow: true,
			animation: true
		});
	});
};

//Delete OtherTracking Key/Value for a specific Date.
//Date in YYYY-MM-DD format
export const deleteOtherTracking = (
	item: Object,
	itemName: string,
	callback: Function
) => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const { ahkWasAuth } = state.fitnessTracker.appleHealthKit;
	//const { auth: fitbitAuth } = state.fitnessTracker.fitbit;
	const ref = firebase.database().ref(`userdata/${uid}/othertracking/`);
	const formattedDate = moment(item.time).format('YYYY-MM-DD');
	let updates = {};
	updates[`${formattedDate}/${itemName}/${item.id}`] = {};

	WebCompatAlert(
		i18n.t('delete'),
		i18n.t('confirmDelete'),
		i18n.t('delete'),
		() => {
			if (itemName === 'Water') {
				if (Platform.OS === 'ios' && ahkWasAuth) {
					ahk_deleteWater(item);
				}

				/*if (fitbitAuth.isAuth) {
					dispatch(
						fitbit_saveWater(fitbitAuth.access_token, date, item.value, metric)
					);
				}*/
			}

			return ref.update(updates).then(() => {
				callback && callback();
				Toast.show(i18n.t('updated'), {
					duration: Toast.durations.LONG,
					position: Toast.positions.BOTTOM,
					shadow: true,
					animation: true
				});
			});
		}
	);
};

/*-------------------------------Review Vitals Tab----------------------------------------------*/

//Changes Vital REview Tab filter while keeping translations
export const changeOtherName = (newName: string, index: number) => {
	const payLd = otherNameFind(newName);

	return {
		type: CHANGE_OTHER_REVIEW_NAME,
		value: payLd,
		label: newName,
		index: index
	};
};

//Converts String to English Version for Review Tab Filter in order to save to Firebase Node
export const otherNameFind = (newName: string) => {
	switch (newName) {
		case i18n.t('Water'):
			return 'Water';
		case i18n.t('Exercise'):
			return 'Exercise';
		case i18n.t('Notes'):
			return 'Notes';
		case i18n.t('Mood'):
			return 'Mood';
		default:
			return 'Water';
	}
};

/*export const createDemoOtherData = () => (dispatch, getState, getFirebase) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};

	//Adding Demo Data
	var startDate = new Date('2020-011-04'); //YYYY-MM-DD
	var endDate = new Date('2021-01-04'); //YYYY-MM-DD
	var getDateArray = (start, end) => {
		var arr = {};
		var dt = new Date(start);
		while (dt <= end) {
			const day = moment(dt).format('YYYY-MM-DD');
			const time = '06:30';
			const time2 = '13:30';
			const time3 = '20:00';
			const randomOne = randomString(14);
			const randomTwo = randomString(14);
			const randomThree = randomString(14);
			const randomFour = randomString(14);
			const randomFive = randomString(14);
			const randomSix = randomString(14);
			const randomSeven = randomString(14);
			const randomEight = randomString(14);
			const randomNine = randomString(14);
			arr[moment(dt).format('YYYY-MM-DD')] = {
				Exercise: {
					[randomOne]: {
						id: randomOne,
						time: moment(day + ' ' + time, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (500 - 100) + 100
					}
				},
				Water: {
					[randomFour]: {
						id: randomFour,
						time: moment(day + ' ' + time, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (16 - 0)
					},
					[randomFive]: {
						id: randomFive,
						time: moment(day + ' ' + time2, 'YYYY-MM-DD HH:mm').toISOString(),
						value: Math.random() * (24 - 17) + 5
					}
				},
				Notes: {
					[randomSeven]: {
						id: randomSeven,
						time: moment(day + ' ' + time, 'YYYY-MM-DD HH:mm').toISOString(),
						value: 'Lorem ipsum dump a cum on my tumn weuhfeijfnskndf'
					}
				}
			};
			dt.setDate(dt.getDate() + 1);
		}
		return arr;
	};
	var dateArr = getDateArray(startDate, endDate);
	updates['othertracking'] = dateArr;
	ref.update(updates).then(() => console.log('DONOE'));
	console.log(JSON.stringify({ dateArr }));
};*/
