//@flow
import React, { useEffect, useState } from 'react';
import { View, Platform, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { Nav, PageProtein, PageCarb } from './';
import styles from './styles';
import { Text, Gram, Button } from 'Components/common';
import {
	calcWeightChangePerWeek,
	calcWeightGoalDate,
	convertWeightToLbs
} from 'Components/calculator/utilities/MacroCalculatorHelper';
import {
	calculateResults,
	calculateCustomResults,
	onDeficitChange
} from 'Components/calculator/actions/MacroCalculatorActions';
import { updateManualGoals } from 'Components/tracker/settings/actions/FirebaseActionsSettings';
import { i18n } from 'Theme';
import Slider from 'react-native-slider';

const Modal = Platform.select({
	ios: () => require('react-native-modal'),
	web: () => require('modal-enhanced-react-native-web'),
	android: () => require('react-native-modal')
})();

type Props = {
	page: number,
	next: Function,
	prev: Function,
	finalSubmit: boolean,
	dotNumber?: number,
	theme: Object
};

export const PageMacroGoals = (props: Props) => {
	const { next, prev, page, finalSubmit, dotNumber, theme } = props;
	const [proteinModal, toggleProteinModal] = useState(false);
	const [carbModal, toggleCarbModal] = useState(false);
	const {
		calories,
		fat,
		carb,
		protein,
		baseCalories,
		deficit,
		macroRatio
	} = useSelector((state) => state.calculator);

	const { weight, goalWeight } = useSelector(
		(state) => state.firebase.profile.profilePage
	);
	const { bodymeasurementsUnits } = useSelector(
		(state) => state.firebase.profile.settings.units
	);

	const dispatch = useDispatch();
	const weightUnit = bodymeasurementsUnits === 'metric' ? 'kg' : 'lbs';

	useEffect(() => {
		dispatch(calculateResults(baseCalories, macroRatio));
	}, []);

	const returnWeightLoss = (goal, current) => {
		let finalWeightChange = +current - +goal;
		if (bodymeasurementsUnits === 'imperial') {
			finalWeightChange = convertWeightToLbs(finalWeightChange);
		}
		let finalString = '';

		if (deficit === 0) return finalString;
		if (finalWeightChange > 0) {
			finalString = `${i18n.t('lose')} ${Math.abs(finalWeightChange).toFixed(
				1
			)} ${weightUnit} ${i18n.t('by')} ${calcWeightGoalDate(
				calories,
				deficit,
				bodymeasurementsUnits === 'metric',
				finalWeightChange
			)}    |    `;
		}
		return finalString;
	};

	const percents = [
		((+fat * 9) / calories) * 100,
		((+protein * 4) / calories) * 100,
		((+carb * 4) / calories) * 100
	];

	const closeCarbModal = () => {
		dispatch(calculateCustomResults(calories, protein, carb));
		toggleCarbModal(false);
	};

	const getDifficulty = (deficit) => {
		if (Math.abs(deficit) === 0) {
			return i18n.t('maintain');
		} else if (Math.abs(deficit) <= 5) {
			return i18n.t('easier');
		} else if (Math.abs(deficit) > 5 && Math.abs(deficit) < 10) {
			return i18n.t('mild');
		} else if (Math.abs(deficit) >= 10 && Math.abs(deficit) < 20) {
			return i18n.t('moderate');
		} else if (Math.abs(deficit) >= 20) {
			return i18n.t('difficult');
		}

		return ' ';
	};

	const getDifficultStyle = (deficit) => {
		if (Math.abs(deficit) <= 5) {
			return { color: theme.green };
		} else if (Math.abs(deficit) > 5 && Math.abs(deficit) < 10) {
			return { color: theme.green };
		} else if (Math.abs(deficit) >= 10 && Math.abs(deficit) < 20) {
			return { color: theme.green };
		} else if (Math.abs(deficit) >= 20) {
			return { color: theme.red };
		}

		return {};
	};

	return (
		<View style={styles.pageContainer}>
			<Nav
				onForward={() => next()}
				onBack={prev}
				page={page}
				finalSubmit={finalSubmit}
				dotNumber={dotNumber}
				theme={theme}
			/>
			<Text style={styles.pageTitle}>{i18n.t('weightPlan')}</Text>
			<Text style={[styles.weightLossEstimateText, getDifficultStyle(deficit)]}>
				{getDifficulty(deficit)}
			</Text>
			<Slider
				style={styles.slider}
				value={deficit}
				onValueChange={(value) =>
					dispatch(onDeficitChange(value, baseCalories, macroRatio))
				}
				minimumValue={-30}
				maximumValue={0}
				step={1}
				minimumTrackTintColor={theme.blue}
				thumbTintColor={theme.blue}
			/>
			<View style={styles.sliderCtn}>
				<Text style={styles.sliderLabel}>{i18n.t('faster')}</Text>
				<Text style={styles.sliderLabel}>{i18n.t('slower')}</Text>
			</View>
			<View style={styles.weightLossEstimateCtn}>
				<Text style={styles.weightLossEstimateText}>{`${returnWeightLoss(
					goalWeight,
					weight
				)} ${calcWeightChangePerWeek(
					calories,
					deficit,
					bodymeasurementsUnits === 'metric'
				)} ${weightUnit} / ${i18n.t('week')}`}</Text>
			</View>
			{/*<Text style={styles.note}>
				This is your weight loss from diet alone. Exercising will speed up
				weight loss on this plan.
			</Text>*/}
			<View style={styles.macroCtn}>
				<View style={styles.macroList}>
					<View
						style={[styles.macroListRow, { borderBottomColor: theme.border }]}>
						<Text style={styles.placeholderStyle}>
							{i18n.t('dailyCalories')}
						</Text>
						<Text style={styles.textInputContainer}>{calories}</Text>
						<Gram customText={'  kCal'} />
					</View>
					<View
						style={[styles.macroListRow, { borderBottomColor: theme.border }]}>
						<View style={styles.pageResultsPercentHolder}>
							<Text
								style={[styles.placeholderStyle, { color: theme.graphFat }]}>
								{i18n.t('fat')}
							</Text>
							<Gram customText={`(${percents[0].toFixed(0)}%)`} />
						</View>
						<Text style={styles.textInputContainer}>{fat}</Text>
						<Gram />
					</View>
					<View
						style={[styles.macroListRow, { borderBottomColor: theme.border }]}>
						<View style={styles.pageResultsPercentHolder}>
							<Text
								style={[
									styles.placeholderStyle,
									{ color: theme.graphProtein }
								]}>
								{i18n.t('protein')}
							</Text>
							<Gram customText={`(${percents[1].toFixed(0)}%)`} />
						</View>
						<TouchableOpacity
							style={styles.customizeTextCtn}
							onPress={() => toggleProteinModal(true)}>
							<Text style={styles.customizeText}>{i18n.t('customize')}</Text>
						</TouchableOpacity>
						<Text style={styles.textInputContainer}>{protein}</Text>
						<Gram />
					</View>
					<View style={[styles.macroListRow, styles.noBorder]}>
						<View style={styles.pageResultsPercentHolder}>
							<Text
								style={[styles.placeholderStyle, { color: theme.graphCarb }]}>
								{i18n.t('carbs')}
							</Text>
							<Gram customText={`(${percents[2].toFixed(0)}%)`} />
						</View>
						<TouchableOpacity
							style={styles.customizeTextCtn}
							onPress={() => toggleCarbModal(true)}>
							<Text style={styles.customizeText}>{i18n.t('customize')}</Text>
						</TouchableOpacity>
						<Text style={styles.textInputContainer}>{carb}</Text>
						<Gram />
					</View>
				</View>
			</View>
			{finalSubmit && (
				<Button
					onPress={() =>
						dispatch(
							updateManualGoals({
								calories: calories,
								carbs: carb,
								fats: fat,
								proteins: protein
							})
						)
					}
					style={[styles.signUpBtn, { backgroundColor: theme.themeAccent }]}>
					Update Macro Goals
				</Button>
			)}
			<Modal.ReactNativeModal
				style={[styles.modal, { backgroundColor: theme.popMenuBg }]}
				isVisible={proteinModal}
				hasBackdrop={true}
				backdropOpacity={0.5}
				animationInTiming={600}
				animationOutTiming={600}
				animationIn={'slideInDown'}
				animationOut={'slideOutUp'}>
				<PageProtein onBack={() => toggleProteinModal(false)} theme={theme} />
			</Modal.ReactNativeModal>
			<Modal.ReactNativeModal
				style={[styles.modal, { backgroundColor: theme.popMenuBg }]}
				isVisible={carbModal}
				onBackdropPress={closeCarbModal}
				onSwipeComplete={closeCarbModal}
				swipeDirection={['down']}
				hasBackdrop={true}
				backdropOpacity={0.5}
				animationInTiming={600}
				animationOutTiming={600}
				animationIn={'slideInDown'}
				animationOut={'slideOutUp'}>
				<PageCarb onBack={closeCarbModal} theme={theme} />
			</Modal.ReactNativeModal>
		</View>
	);
};
