//@flow
import { Platform } from 'react-native';

const shareUrl = Platform.select({
	ios:
		'https://apps.apple.com/us/app/myketo-low-carb-tracker/id1459449742?ls=1',
	android:
		'https://play.google.com/store/apps/details?id=com.prestigeworldwide.keto&hl=en'
});

const rateURL = Platform.select({
	ios: 'itms://itunes.apple.com/us/app/apple-store/1459449742?mt=8',
	android: 'market://details?id=com.prestigeworldwide.myketotracker'
});

const admobBannerID = Platform.select({
	ios: 'ca-app-pub-5202729129404099/6318559762',
	android: 'ca-app-pub-5202729129404099/3383566165'
});

const admobInterID = Platform.select({
	ios: 'ca-app-pub-5202729129404099/7795292960',
	android: 'ca-app-pub-5202729129404099/9870578960'
});

const testingID = {
	banner: 'ca-app-pub-3940256099942544/6300978111',
	inter: 'ca-app-pub-3940256099942544/1033173712'
};

const decimalOption = 2;

const Global = {
	shareUrl: shareUrl,
	rateURL: rateURL,
	admobBannerID: global.__DEV__ ? testingID.banner : admobBannerID,
	admobInterID: global.__DEV__ ? testingID.inter : admobInterID,
	decimalOption: decimalOption
};

export default Global;
