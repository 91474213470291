//@flow
import React from 'react';
import { ScrollView, Linking, View, Image } from 'react-native';
import { Paragraph, Heading, BlockQuote, Divider, Text } from '../common';
import styles from './Styles/Styles';
import { i18n, Images } from 'Theme';

export const Benefits = (props) => {
	const { theme } = props;
	return (
		<ScrollView style={styles.container}>
			<View>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh0')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp0')}</Paragraph>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh1')}
				</Heading>
				<Divider />
				<Image
					resizeMode="contain"
					style={styles.imageStyle}
					source={Images.weightLoss}
				/>
				<Divider />
				<Paragraph>{i18n.t('benefitsp1')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq1')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://www.nejm.org/doi/full/10.1056/NEJMoa022637'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq2')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://www.jpeds.com/article/S0022-3476(02)40206-5/abstract?cc=y='
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq3')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://www.nejm.org/doi/full/10.1056/NEJMoa022207'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq4')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://nutritionandmetabolism.biomedcentral.com/articles/10.1186/1743-7075-1-13'
							)
						}>
						{'\n'}Source
					</Text>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://onlinelibrary.wiley.com/doi/10.1038/oby.2006.277/abstract'
							)
						}>
						{'\n'}Source 2
					</Text>
				</BlockQuote>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq5')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://www.hopkinsmedicine.org/news/media/releases/low_carb_higher_fat_diets_add_no_arterial_health_risks_to_obese_people_seeking_to_lose_weight'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh2')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp2')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq6')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('http://www.ncbi.nlm.nih.gov/pubmed/20101008')
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh3')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp3')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq7')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('http://www.ncbi.nlm.nih.gov/pubmed/23651522')
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq8')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://ajcn.nutrition.org/content/85/1/225.abstract'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq9')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('http://jn.nutrition.org/content/136/2/384.long')
						}>
						{'\n'}Source
					</Text>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('http://www.ncbi.nlm.nih.gov/pubmed/16297472')
						}>
						{'\n'}Source 2
					</Text>
				</BlockQuote>
				<Divider />
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq10')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('http://jn.nutrition.org/content/136/2/384.long')
						}>
						{'\n'}Source
					</Text>
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('http://www.ncbi.nlm.nih.gov/pubmed/16297472')
						}>
						{'\n'}Source 2
					</Text>
				</BlockQuote>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh4')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp4')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq11')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://nutritionandmetabolism.biomedcentral.com/articles/10.1186/1743-7075-2-34'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq12')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('http://www.ncbi.nlm.nih.gov/pubmed/16403234')
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />

				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq13')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://www.ncbi.nlm.nih.gov/pmc/articles/PMC2633336/'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh5')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp5')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq14')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('http://www.ncbi.nlm.nih.gov/pubmed/17228046')
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh6')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp6')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq15')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://www.ncbi.nlm.nih.gov/pmc/articles/PMC4215472/'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh7')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp7')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq16')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://www.andjrnl.org/article/S2212-2672(12)01923-5/abstract'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh8')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp8')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq17')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://www.dentalproductsreport.com/dental/article/how-low-carb-diets-impact-oral-health'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh9')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp9')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq18')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL('http://www.ncbi.nlm.nih.gov/pubmed/11918434')
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
				<Divider />
				<Heading style={{ backgroundColor: theme.base }}>
					{i18n.t('benefitsh10')}
				</Heading>
				<Paragraph>{i18n.t('benefitsp10')}</Paragraph>
				<Divider />
				<BlockQuote ctnStyle={{ backgroundColor: theme.base, borderColor: theme.border }}>
					{i18n.t('benefitsbq19')}
					<Text
						style={styles.linkText}
						onPress={() =>
							Linking.openURL(
								'http://blogs.scientificamerican.com/mind-guest-blog/the-fat-fueled-brain-unnatural-or-advantageous/'
							)
						}>
						{'\n'}Source
					</Text>
				</BlockQuote>
				<Divider />
			</View>
		</ScrollView>
	);
};
