//@flow
import {
	returnWeightKg,
	returnWeightNumber,
	returnWaterNumber,
	returnWaterML
} from 'Utilities';

//Returns Unit label in correct units as a string
export const returnCorrectUnitsString = (units: string, unitString: string) => {
	switch (units) {
		case 'length':
			return unitString === 'metric' ? 'cm' : 'in';
		case 'weight':
			return unitString === 'metric' ? 'kg' : 'lbs';
		case 'water':
			return unitString;
		//return unitBoolean ? 'ml' : 'oz';
		case 'percent':
			return '%';
		case 'mg':
			return 'mg';
		case 'glucose':
			return unitString;
		//return unitBoolean ? 'mg/dL' : 'mmol/L';
		case 'ketones':
			return unitString;
		//return unitBoolean ? 'mg/dL' : 'mmol/L';
		case 'exercise':
			return unitString;
		//return unitBoolean ? 'kJ' : 'kCal';
		case 'bpm':
			return 'bpm';
		case 'mmHg':
			return 'mmHg';
		case 'none':
			return ' ';
		case 'notes':
			return ' ';
		default:
			return ' ';
	}
};

//Returns the correct number value based off user unit preference
export const returnCorrectUnitsValue = (
	units: string,
	unitString: string,
	value: string
) => {
	//console.log(units, unitString, value)
	switch (units) {
		case 'length':
			return returnLengthNumber(value, unitString === 'metric');
		case 'weight':
			return returnWeightNumber(value, unitString === 'metric');
		case 'water':
			return returnWaterNumber(value, unitString === 'ml');
		case 'percent':
			return +value;
		case 'mg':
			return +value;
		case 'glucose':
			return returnBloodLevelNumber(value, unitString === 'mmol/L');
		case 'ketones':
			return returnBloodLevelNumber(value, unitString === 'mmol/L');
		case 'exercise':
			return returnCaloriesNumber(value, unitString === 'kJ');
		case 'bpm':
			return +value;
		case 'mmHg':
			return +value;
		case 'height':
			return returnHeightNumber(value, unitString === 'metric');
		case 'none':
			return +value;
		case 'notes':
			return value;
		default:
			return +value;
	}
};

//All firebase nodes are saved in Metric, this converts it to metric number before saving (if it already isn't in metric from user preferences)
export const convertToMetricForFirebase = (
	units: string,
	unitString: string,
	value: string
) => {
	switch (units) {
		case 'length':
			return returnLengthCm(value, unitString === 'metric');
		case 'weight':
			return returnWeightKg(value, unitString === 'metric');
		case 'water':
			return returnWaterML(value, unitString === 'ml');
		case 'percent':
			return +value;
		case 'mg':
			return +value;
		case 'glucose':
			return returnMMOL(value, unitString === 'mmol/L');
		case 'ketones':
			return returnMMOL(value, unitString === 'mmol/L');
		case 'exercise':
			return returnCaloriesKcal(value, unitString === 'kJ');
		case 'bpm':
			return +value;
		case 'mmHg':
			return +value;
		case 'height':
			return returnHeightCm(value, unitString === 'metric');
		case 'none':
			return +value;
		case 'notes':
			return value;
		default:
			return +value;
	}
};

/*--------Length ----- */

//Returns length converted into CM to be saved into the Firebase Node
const returnLengthCm = (length: string, isCM: boolean) => {
	let lengthCM = '';
	if (isCM === true) {
		lengthCM = +length;
	} else {
		//inches convert to cm
		lengthCM = +length * 2.54;
	}
	return lengthCM;
};

//Returns length from Firebase Node (saved in CM), in either CM or IN depending on user pref
const returnLengthNumber = (length: string, isCM: boolean) => {
	let lengthCM = '';
	if (isCM === true) {
		lengthCM = +length;
	} else {
		//inches convert to cm
		lengthCM = +length / 2.54;
	}
	return lengthCM;
};

/*--------Blood Levels  ----- */

//Returns glucose blood levels in correct units, according to user preference, as a number without labels
const returnBloodLevelNumber = (measurement: string, isMmol: boolean) => {
	let final = '';
	if (isMmol === false) {
		final = +measurement * 18.02;
	} else {
		final = +measurement;
	}
	return final;
};

//Returns glucose in mmol/L number, in order to be saved (always saved as mmol/L)
const returnMMOL = (glucose: string, isMmol: boolean) => {
	let glucoseMMOL = '';
	if (isMmol === false) {
		glucoseMMOL = +glucose / 18.02;
	} else {
		glucoseMMOL = +glucose;
	}
	return glucoseMMOL;
};

/*--------Calories  ----- */

//Returns calories from Firebase Node (saved in kCal), in either kCal or kJ depending on user pref
export const returnCaloriesNumber = (calories: string, isKJ: boolean) => {
	let calNum = '';
	if (isKJ === true) {
		//gets kCal value from firebase and converts to user's kJ
		calNum = +calories * 4.184;
	} else {
		//already kCal, do nothing
		calNum = +calories;
	}
	return calNum;
};

//Returns calories converted into kCal to be saved into the Firebase Node
const returnCaloriesKcal = (calories: string, isKJ: boolean) => {
	let calorieskCAL = '';
	if (isKJ === true) {
		//conerts kJ unit to kCal unit for saving
		calorieskCAL = +calories / 4.184;
	} else {
		//already in kCal, do nothing
		calorieskCAL = +calories;
	}
	return calorieskCAL;
};

//Helper Function for Adding all values for "mg" and "exercise" types
export const totalAllArrValues = (arr: Array<Object>) => {
	let total = 0;
	for (var i = 0; i < arr.length; i++) {
		total += +arr[i].value;
	}
	return total;
};

/*-----Height -------*/
export const returnHeightCm = (Height, isMetric: boolean) => {
	let HeightCm;
	if (isMetric) {
		HeightCm = Height;
	} else {
		HeightCm = Height[0] * 30.48 + Height[1] * 2.54;
	}

	return HeightCm;
};

export const returnHeightNumber = (height: string, isMetric: boolean) => {
	let heightFinal = '';
	if (isMetric === true) {
		heightFinal = +height.toFixed(0);
	} else {
		var realFeet = (+height * 0.3937) / 12;
		var feet = Math.floor(realFeet);
		var inches = Math.round((realFeet - feet) * 12);
		heightFinal = [feet, inches];
	}
	return heightFinal;
};
