const DATA = {
	food0: 'Fromage bleu',
	food1: 'Fromage cheddar',
	food2: 'Cottage cheese',
	food3: 'Fromage Frais',
	food4: 'Feta',
	food5: 'Fromage Gouda',
	food6: 'Fromage mozzarella',
	food7: 'Fromage provolone',
	food8: 'Fromage Ricotta',
	food9: 'Fromage suisse',
	food10: 'Creme de noix de coco',
	food11: 'Lait de coco (non sucré)',
	food12: 'Oeuf',
	food13: 'Crème épaisse',
	food14: 'Glace (non sucrée)',
	food15: 'Lait',
	food16: 'Lait de soja (non sucré)',
	food17: 'Crème fouettée (non sucrée)',
	food18: 'Yaourt / Kéfir, nature',
	food19: 'Fromage bleu',
	food20: 'César crémeux',
	food21: 'Ranch',
	food22: "Huile d'amande",
	food23: 'Beurre',
	food24: 'Le beurre de cacao',
	food25: 'Huile de noix de coco',
	food26: 'Huile de poisson (foie de morue)',
	food27: 'Huile de graines de lin',
	food28: "L'huile de pépins de raisin",
	food29: 'Huile de graines de chanvre',
	food30: 'Mayonnaise',
	food31: "Huile d'olive",
	food32: "L'huile de carthame",
	food33: 'Huile de sésame',
	food34: 'Huile de tournesol',
	food35: 'Huile végétale',
	food36: 'Patauger',
	food37: 'Hareng',
	food38: 'Saumon',
	food39: 'Sardines',
	food40: 'Unique',
	food41: 'Truite',
	food42: 'Thon',
	food43: 'Thon (en conserve)',
	food44: "Poudre d'amande",
	food45: 'Poudre de cacao (non sucrée)',
	food46: 'Farine de graines de lin',
	food47: 'Poudre de protéine',
	food48: 'Cosse de psyllium',
	food49: 'Farine de blé',
	food50: 'Cuisses de poulet',
	food51: 'Ailes de poulet',
	food52: 'Canard',
	food53: 'OIE',
	food54: 'Caille',
	food55: 'Poitrine de dinde',
	food56: 'Turquie, terre',
	food57: 'Bacon de dinde',
	food58: 'Orge',
	food59: 'Aliments transformés en boîte',
	food60: 'Pain',
	food61: 'Chapelure',
	food62: 'Gâteaux',
	food63: 'Soupes et ragoûts en conserve',
	food64: 'Céréales froides',
	food65: 'Biscuits',
	food66: 'Chips de maïs',
	food67: 'Pain au maïs',
	food68: 'Semoule de maïs',
	food69: 'Crackers',
	food70: 'Grits',
	food71: 'Hash browns',
	food72: 'Céréales chaudes',
	food73: 'Muffins, nature',
	food74: 'Crêpes',
	food75: 'Pâtes',
	food76: 'Tartes',
	food77: 'Polenta',
	food78: 'Pop corn',
	food79: 'Chips de pommes de terre',
	food80: 'Bretzels',
	food81: 'Riz',
	food82: 'Seigle',
	food83: 'Orthographié',
	food84: 'Emballages Tamale',
	food85: 'Tater tots',
	food86: 'Teff',
	food87: 'Tortillas',
	food88: 'Tricale',
	food89: 'Gaufres',
	food90: 'Côtes de boeuf',
	food91: 'Boeuf, saucisse',
	food92: 'Steak de bœuf',
	food93: "Côtelettes d'agneau",
	food94: 'Pepperoni, porc / boeuf',
	food95: 'Porc, bacon',
	food96: 'Côtes de porc',
	food97: 'Porc, jambon',
	food98: 'Saucisse de porc',
	food99: 'Veau',
	food100: 'Chevreuil, steak',
	food101: 'Des haricots',
	food102: 'Noix de cajou',
	food103: 'Noix de coco (viande, non sucrée)',
	food104: 'Noisettes',
	food105: 'Lentilles',
	food106: 'Macadamias',
	food107: 'Cacahuètes',
	food108: "Beurre d'arachide",
	food109: 'Noix de pécan',
	food110: 'Pistaches',
	food111: 'Noix',
	food112: 'Graines de lin',
	food113: 'Graines de chanvre',
	food114: 'Graines de citrouille',
	food115: 'Graines de carthame',
	food116: 'Graines de sésame',
	food117: 'Graines de tournesol',
	food118: 'La chair de crabe',
	food119: 'Homard',
	food120: 'Moules',
	food121: 'Huîtres',
	food122: 'Crevette',
	food123: 'Calamar',
	food124: 'Bouillon clair ou bouillon',
	food125: 'Café',
	food126: 'Lumière de cristal',
	food127: 'Sodas diététiques (Coke Zero, Coca light)',
	food128: 'Eau tonique diététique',
	food129: 'Eau de Seltz aromatisée (non sucrée)',
	food130: 'Tisane (non sucrée)',
	food131: 'Jus de citron et de lime (limité)',
	food132: "L'eau de Seltz",
	food133: 'Un soda',
	food134: 'Eau gazifiée',
	food135: 'Boissons énergisantes sans sucre (Red Bull, Monster, etc.)',
	food136: 'Eau gazeuse (Perrier)',
	food137: 'Thé (non sucré)',
	food138: 'Eau',
	food139: 'Bière',
	food140: 'Cognac',
	food141: 'Cognac (Hennessy, etc.)',
	food142: 'Gin (Tanqueray, Beefeater, etc.)',
	food143: 'Light Beers (ie Coors, Bud Light)',
	food144: 'Rhum (Captain Morgan, etc.)',
	food145: 'Scotch',
	food146: 'Tequila',
	food147: 'Vodka (Absolut, Grey Goose, etc.)',
	food148: 'Vin (sec)',
	food149: 'Vin (dessert)',
	food150: "Whisky (Jack Daniel's, etc.)",
	food151: 'Cassonade',
	food152: 'Bonbons',
	food153: 'Sucre de canne',
	food154: 'Sirop de maïs',
	food155: 'Dextrose',
	food156: 'Érythritol',
	food157: 'Fructose',
	food158: 'Glucose',
	food159: 'Mon chéri',
	food160: 'Lactose',
	food161: 'Maltose',
	food162: "Sirop d'érable",
	food163: 'Sucre en poudre',
	food164: 'Sorgho',
	food165: 'Stevia',
	food166: 'Saccharose',
	food167: 'Pommes',
	food168: 'Abricot',
	food169: 'Avocat',
	food170: 'Banane',
	food171: 'Mûres',
	food172: 'Myrtilles',
	food173: 'Cantaloup',
	food174: 'Cerises',
	food175: 'Canneberges',
	food176: 'Rendez-vous',
	food177: 'Fruit du dragon',
	food178: 'Durians',
	food179: 'Figues',
	food180: 'Les raisins',
	food181: 'Pamplemousse',
	food182: 'Miellat',
	food183: 'Jus',
	food184: 'Kiwi',
	food185: 'Citrons',
	food186: 'Citrons verts',
	food187: 'Mangue',
	food188: 'Nectarines',
	food189: 'Des oranges',
	food190: 'Papaye',
	food191: 'Fruit de la passion',
	food192: 'Pêche',
	food193: 'Poires',
	food194: 'Ananas',
	food195: 'Grenade',
	food196: 'Prunes',
	food197: 'Pruneaux',
	food198: 'Raisins secs',
	food199: 'Framboises',
	food200: 'Fraises',
	food201: 'Mandarines',
	food202: 'Pastèque',
	food203: 'Germes de luzerne',
	food204: 'Tout légume vert à feuilles',
	food205: 'Artichauts',
	food206: 'Asperges',
	food207: 'Pousses de bambou',
	food208: 'Germes de soja',
	food209: 'Betteraves vertes',
	food210: 'Bok Choy',
	food211: 'Brocoli',
	food212: 'Choux de Bruxelles',
	food213: 'Chou',
	food214: 'Choufleur',
	food215: 'Céleri',
	food216: 'Céleri-rave',
	food217: 'Bettes',
	food218: 'Ciboulette',
	food219: 'Collard Greens',
	food220: 'Blé',
	food221: 'Concombres',
	food222: 'Les feuilles de pissenlit',
	food223: "Cornichons à l'aneth",
	food224: 'Ail',
	food225: 'Chou frisé',
	food226: 'Poireaux',
	food227:
		'Laitues et salade verte - Roquette, Bok Choy, Laitue Boston, Chicorée, Endive, Scarole, Fenouil, Mache, Radicchio, Romaine, Sorrel',
	food228: 'Haricots de Lima',
	food229: 'Champignons',
	food230: 'Gombo',
	food231: 'Olives',
	food232: 'Oignons',
	food233: 'Pois',
	food234: 'Patates',
	food235: 'Des radis',
	food236: 'Choucroute',
	food237: 'Oignons verts',
	food238: 'Échalotes',
	food239: 'Épinard',
	food240: 'Patates douces',
	food241: 'Bette à carde',
	food242: 'Navets',
	food243: "Châtaignes d'Eau"
};

export default DATA;
