//@flow
// leave off @2x/@3x
const images = {
	logo: require('../../assets/images/icon.png'),
	iconCutOut: require('../../assets/images/iconCutOut.png'),
	edamam: require('../../assets/images/edamam.png'),
	logo: require('../../assets/images/logo.png'),
	logoText: require('../../assets/images/logo_text.png'),
	ketoChart: require('../../assets/images/Learn/ketochart.png'),
	butter: require('../../assets/images/Learn/butter.jpg'),
	dietGrid: require('../../assets/images/Learn/dietgrid.png'),
	electro: require('../../assets/images/Learn/electro.jpg'),
	headache: require('../../assets/images/Learn/headache.png'),
	netCarb: require('../../assets/images/Learn/netcarb.jpg'),
	psyllium: require('../../assets/images/Learn/psyllium_husk_powder.png'),
	satFat: require('../../assets/images/Learn/satfat.png'),
	vegFiber: require('../../assets/images/Learn/veg_fiber.jpg'),
	weightLoss: require('../../assets/images/Learn/weightloss.png'),

	seventeen: require('../../assets/images/OtherDiets/17day.png'),
	myfodmaps: require('../../assets/images/OtherDiets/myfodmaps.jpg'),
	alkaline: require('../../assets/images/OtherDiets/alkaline.png'),
	dash: require('../../assets/images/OtherDiets/dash.png'),
	fertility: require('../../assets/images/OtherDiets/fertility.png'),
	glutenFree: require('../../assets/images/OtherDiets/glutenfree.png'),
	hcg: require('../../assets/images/OtherDiets/hcg.png'),
	hmr: require('../../assets/images/OtherDiets/hmr.png'),
	macrobiotic: require('../../assets/images/OtherDiets/macrobiotic.png'),
	mediterranean: require('../../assets/images/OtherDiets/mediterranean.png'),
	paleo: require('../../assets/images/OtherDiets/paleo.png'),
	rawFood: require('../../assets/images/OtherDiets/rawfood.png'),
	keto: require('../../assets/images/OtherDiets/keto.png'),
	glycemic: require('../../assets/images/OtherDiets/glycemic.png'),
	peanut: require('../../assets/images/OtherDiets/peanut.png'),
	pescatarian: require('../../assets/images/OtherDiets/pescatarian.png'),
	kidney: require('../../assets/images/OtherDiets/kidney.png'),
	kosher: require('../../assets/images/OtherDiets/kosher.png'),
	immune: require('../../assets/images/OtherDiets/immune.png'),
	cocktail: require('../../assets/images/OtherDiets/cocktail.png'),
	redmeat: require('../../assets/images/OtherDiets/redmeat.png'),
	dairy: require('../../assets/images/OtherDiets/dairy.png'),
	caribbean: require('../../assets/images/OtherDiets/caribbean.png'),
	chinese: require('../../assets/images/OtherDiets/chinese.png'),
	easterneurope: require('../../assets/images/OtherDiets/easterneurope.png'),
	french: require('../../assets/images/OtherDiets/french.png'),
	indian: require('../../assets/images/OtherDiets/indian.png'),
	bodyFatEstimate: require('../../assets/images/Calculator/bodyfatestimate.jpg'),
	fatsecret: require('../../assets/images/fatsecret.png'),
	appleHealthKit: require('../../assets/images/fitnesstrackers/appleHealthKit.png'),
	gFit: require('../../assets/images/fitnesstrackers/gFit.png'),
	fitBit: require('../../assets/images/fitnesstrackers/fitBit.png'),
	samsungHealth: require('../../assets/images/fitnesstrackers/samsungHealth.png'),
	login: require('../../assets/images/login.jpg'),
	gplay: require('../../assets/images/gplay.png'),
	ios: require('../../assets/images/ios.png'),
	purchase1a: require('../../assets/images/Purchase/1a.jpg'),
	purchase1b: require('../../assets/images/Purchase/1b.jpg'),
	purchase2a: require('../../assets/images/Purchase/2a.jpg'),
	purchase2b: require('../../assets/images/Purchase/2b.jpg'),
	purchase3a: require('../../assets/images/Purchase/3a.jpg'),
	purchase3b: require('../../assets/images/Purchase/3b.jpg'),
	purchase3c: require('../../assets/images/Purchase/3c.jpg'),
	purchase4: require('../../assets/images/Purchase/4.jpg')
};

export default images;
