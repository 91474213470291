//@flow
import {
	SET_PROGRESS_FILTER,
	SET_PROGRESS_FROM_DATE,
	SET_PROGRESS_TO_DATE,
	SET_PROGRESS_TIMEFRAME,
	SET_PROGRESS_BM_TIMEFRAME,
	SET_PROGRESS_BM_FILTER,
	SET_PROGRESS_BM_FROM_DATE,
	SET_PROGRESS_BM_TO_DATE,
	SET_PIE_CHART_DATA
} from './types';
import { getDates } from 'Utilities/ProgressFormat';
import moment from 'moment';

const dateTo = moment().format('YYYY-MM-DD');
const dateFromWeek = moment().subtract(7, 'd').format('YYYY-MM-DD');
const dateFromMonth = moment().subtract(1, 'months').format('YYYY-MM-DD');
const dateFromThreeMonth = moment().subtract(3, 'months').format('YYYY-MM-DD');
const dateFromSixMonth = moment().subtract(6, 'months').format('YYYY-MM-DD');
const dateFromOneYear = moment().subtract(1, 'years').format('YYYY-MM-DD');

const weekly = getDates(dateFromWeek, dateTo);
const monthly = getDates(dateFromMonth, dateTo);
const threeMonth = getDates(dateFromThreeMonth, dateTo);
const sixMonth = getDates(dateFromSixMonth, dateTo);
const oneYear = getDates(dateFromOneYear, dateTo);

export const setProgressFilter = (filterOption) => {
	return {
		type: SET_PROGRESS_FILTER,
		payload: filterOption
	};
};

export const setProgressFromDate = (fromDate) => {
	return {
		type: SET_PROGRESS_FROM_DATE,
		payload: fromDate
	};
};

export const setProgressToDate = (toDate) => {
	return {
		type: SET_PROGRESS_TO_DATE,
		payload: toDate
	};
};

export const setProgressTimeframePeriod = (timeframeDateStringArr) => {
	return {
		type: SET_PROGRESS_TIMEFRAME,
		payload: timeframeDateStringArr
	};
};

export const loadDietProgress = (progressFilter, dateF, dateT) => {
	switch (progressFilter) {
		case 0:
			return (dispatch) => {
				dispatch(setProgressTimeframePeriod(weekly));
			};
		case 1:
			return (dispatch) => {
				dispatch(setProgressTimeframePeriod(monthly));
			};
		case 2:
			return (dispatch) => {
				dispatch(setProgressTimeframePeriod(threeMonth));
			};
		case 3:
			return (dispatch) => {
				dispatch(setProgressTimeframePeriod(sixMonth));
			};
		case 4:
			return (dispatch) => {
				dispatch(setProgressTimeframePeriod(oneYear));
			};
		case 5:
			return (dispatch) => {
				dispatch(setProgressTimeframePeriod('all'));
			};
		case 6:
			return (dispatch) => {
				dispatch(setProgressTimeframePeriod(getDates(dateF, dateT)));
			};
		default:
			return (dispatch) => {
				dispatch(setProgressTimeframePeriod(weekly));
			};
	}
};

export const setBMProgressFilter = (filterOption) => {
	return {
		type: SET_PROGRESS_BM_FILTER,
		payload: filterOption
	};
};

export const setBMProgressFromDate = (fromDate) => {
	return {
		type: SET_PROGRESS_BM_FROM_DATE,
		payload: fromDate
	};
};

export const setBMProgressToDate = (toDate) => {
	return {
		type: SET_PROGRESS_BM_TO_DATE,
		payload: toDate
	};
};

export const setProgressBMTimeframePeriod = (timeframeDateStringArr) => {
	return {
		type: SET_PROGRESS_BM_TIMEFRAME,
		payload: timeframeDateStringArr
	};
};

export const loadBodyMeasurementProgress = (progressFilter, dateF, dateT) => {
	switch (progressFilter) {
		case 0:
			return (dispatch) => {
				dispatch(setProgressBMTimeframePeriod(weekly));
			};
		case 1:
			return (dispatch) => {
				dispatch(setProgressBMTimeframePeriod(monthly));
			};
		case 2:
			return (dispatch) => {
				dispatch(setProgressBMTimeframePeriod(threeMonth));
			};
		case 3:
			return (dispatch) => {
				dispatch(setProgressBMTimeframePeriod(sixMonth));
			};
		case 4:
			return (dispatch) => {
				dispatch(setProgressBMTimeframePeriod(oneYear));
			};
		case 5:
			return (dispatch) => {
				dispatch(setProgressBMTimeframePeriod('all'));
			};
		case 6:
			return (dispatch) => {
				dispatch(setProgressBMTimeframePeriod(getDates(dateF, dateT)));
			};
		default:
	}
};

/*export const convertAllWeights = (weightArrTotal) => (
	dispatch,
	getState,
	getFirebase
) => {
	const firebase = getFirebase();
	const state = getState();
	const { uid } = state.firebase.auth;
	const ref = firebase.database().ref(`userdata/${uid}/`);
	let updates = {};
	weightArrTotal.forEach((element) => {
		const postData = +element.y * 0.453592;
		updates[`bodymeasurements/${element.x}/Weight`] = postData;
	});
	console.log(updates)
	ref.update(updates).then(() => console.log('updates done'));
};*/
