import { registerRootComponent } from 'expo';
import App from './App';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';

// For Firebase WEB JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyBCyur1HFf7SQ0Ke0rtsb1QxayeLL3vzZY',
	authDomain: 'keto-diet-guide.firebaseapp.com',
	databaseURL: 'https://keto-diet-guide.firebaseio.com',
	projectId: 'keto-diet-guide',
	storageBucket: 'keto-diet-guide.appspot.com',
	messagingSenderId: '576543677526',
	appId: '1:576543677526:web:186b3f330ce04875ab2ff7',
	measurementId: 'G-TGT80CYBM6'
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
